import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { BASE_API } from "../../../Services/apis";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";


const PaymentPage = ({ selectedVehicle }) => {
    const location = useLocation();
    const navigate = useNavigate()
    const { vehicleType } = useParams();
    const { token, user } = useSelector((state) => state.auth);
    const [selectedPayment, setSelectedPayment] = useState("part");
    const [bookingDetails, setBookingDetails] = useState({
        vehicleId: "",
        pickupDate: "",
        dropDate: "",
        pickupTime: "",
        dropTime: "",
        pickupLocation: "",
        dropLocation: "",
        rateType: "",
        totalAmount: "",
        contactNumber: '',
        additionalRequests: 'child birth',
    });


    const searchParams = new URLSearchParams(location.search);
    useEffect(() => {
        const from = searchParams.get("pickupLocation") || "";
        const to = searchParams.get("dropLocation") || "";
        const pickupDate = searchParams.get("pickupDate") || "";
        const dropoffDate = searchParams.get("dropoffDate") || "";
        const dropoffTime = searchParams.get("dropoffTime") || "";
        const selectedrateType = searchParams.get("rateType");

        setBookingDetails({
            vehicleId: selectedVehicle._id,
            totalAmount: selectedrateType == 'ratePerDay' ? selectedVehicle?.ratePerDay : selectedVehicle?.ratePer24Hrs,
            pickupDate: pickupDate,
            dropDate: dropoffDate, 
            pickupTime: "10:00",  
            dropTime: dropoffTime || "18:00",
            pickupLocation: from,
            dropLocation: to,
            rateType: "RatePerDay", 
            contactNumber: user?.mobile,
            additionalRequests: "London", 
        });
    }, [location.search, selectedVehicle, user]);


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pickupLocation = searchParams.get("pickupLocation");
        const dropLocation = searchParams.get("dropLocation");
        const pickUpDate = searchParams.get("pickupDate");
        const pickUpTime = searchParams.get("pickupTime");
        const dropOffDate = searchParams.get("dropoffDate");
        const dropOffTime = searchParams.get("dropOffTime");
        const rateTypes = searchParams.get("rateType")

        setBookingDetails({
            vehicleId: selectedVehicle._id,
            rateType: rateTypes, 
            totalAmount: rateTypes == 'ratePerDay' ? selectedVehicle?.ratePerDay : selectedVehicle?.ratePer24Hrs,
            pickupAddress: pickupLocation || "Default Pickup Address",
            pickupDate: pickUpDate,
            pickupTime: pickUpTime || "09:30",
            dropAddress: dropLocation || "Default Drop Address",
            dropDate: dropOffDate || pickUpDate, 
            dropTime: dropOffTime || "16:30", 
            inBetweenCities: [
                { address: "Indore" },
                { address: "Guawalier" },
                { address: "Satna" }
            ],
            alternateMobile: user?.mobile 
        });
    }, [location.search]);


    const RentalPayment = async () => {
        const RenatalParms = {
            vehicleId: bookingDetails.vehicleId,
            pickupDate: bookingDetails.pickupDate,
            dropDate: bookingDetails.dropDate,
            pickupTime: bookingDetails.pickupTime,
            dropTime: bookingDetails.dropTime,
            pickupLocation: searchParams.get("pickupLocation"),
            dropLocation: searchParams.get("dropLocation"),
            rateType: searchParams.get("rateType") === "RatePerDay" ? "RatePerDay" : "RatePer24Hrs",
            totalAmount: bookingDetails.totalAmount,
            contactNumber: user.mobile,
            additionalRequests: bookingDetails.additionalRequests
        }
        try {
            const response = await axios.post(
                `${BASE_API}/booking/create/rental-booking`, RenatalParms,
                {
                    headers: {
                        Authorization: `Bearer ${token}`, 
                    },
                } 
            );


            if (response.status === 201 || response.status === 200) {
                toast.success('Successfully booked vehicle');
                localStorage.setItem('Booking', JSON.stringify(response?.data?.booking));
                navigate('/rental-booking-confirmation-details', { state: { booking: response?.data?.booking } });
            } else {
                toast.error('Error during booking');
            }
        } catch (error) {
            console.error("Error during booking:", error.message);
            toast.error(`Error during booking: ${error.response?.data?.message || error.message}`);
        }
    }

    const TaxiServicePackage = async () => {
        const rawInBetweenCities = searchParams.get("inBetweenCities");

        let inBetweenCities = [];
        if (rawInBetweenCities) {
            
                inBetweenCities = JSON.parse(rawInBetweenCities);
            
        }
        try {
            const response = await axios.post(
                `${BASE_API}/booking/create/taxi-booking`,
                {
                    vehicleId: bookingDetails.vehicleId,
                    pickupAddress: bookingDetails.pickupAddress,
                    pickupDate: bookingDetails.pickupDate,
                    pickupTime: bookingDetails.pickupTime,
                    dropAddress: bookingDetails.dropAddress,
                    dropDate: bookingDetails.dropDate,
                    dropTime: bookingDetails.dropTime,
                    alternateMobile: searchParams.get("alternateMobile"),
                    inBetweenCities: Array.isArray(inBetweenCities)
                        ? inBetweenCities.map((city) => ({ address: city.address }))
                        : [],
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 201 || response.status === 200) {
                toast.success("Successfully booked vehicle");
                localStorage.setItem("Booking", JSON.stringify(response?.data?.booking));
                navigate('/taxi-package-booking-successful', { state: { booking: response?.data?.booking } });
            } else {
                toast.error("Error during booking");
            }
        } catch (error) {
            console.error("Error during booking:", error.message);
            toast.error(`Error during booking: ${error.response?.data?.message || error.message}`);
        }
    };

    const handleBookingVehicle = async () => {
        if (vehicleType !== "TaxiPackages") {
            RentalPayment();
        }
        else {
            TaxiServicePackage();
        }

    };
    return (
        <div className="max-w-md hidden  mx-auto text-[0.8rem]  bg-white shadow-md rounded-md py-4 md:flex flex-col gap-2">
            {/* Pay Now Button */}
            <button
                onClick={handleBookingVehicle}
                style={{ background: "linear-gradient(90.95deg, #53B2FE 0%, #065AF3 100%)" }}
                className="w-full bg-blue-500 text-white py-3 font-semibold text-lg mb-4">
                PAY ₹{bookingDetails?.totalAmount || selectedVehicle?.ratePer12Hrs} NOW
            </button>

            <div className="space-y-4 p-4">
                <label className="flex items-center">
                    <input
                        type="radio"
                        name="payment"
                        value="part"
                        checked={selectedPayment === "part"}
                        onChange={() => setSelectedPayment("part")}
                        className="mr-2"
                    />
                    <span className="flex justify-between w-full">
                        <div className="flex flex-col">
                            <span className="text-black font-bold"> payment now ₹{bookingDetails?.totalAmount || selectedVehicle?.ratePer12Hrs}</span>


                        </div>
                    </span>
                </label>

                <label className="flex items-center">
                    <input
                        type="radio"
                        name="payment"
                        value="full"
                        checked={selectedPayment === "full"}
                        onChange={() => setSelectedPayment("full")}
                        className="mr-2"
                    />
                    <span className="flex justify-between w-full">
                        <div className="flex flex-col">
                            <span className="text-black font-bold">Pay After</span>
                        </div>
                    </span>
                </label>
            </div>

            <div className="border-t border-gray-300 mt-4 w-[90%] mx-auto flex items-center justify-between ">
                <div className="flex  text-lg font-semibold flex-col text-start">
                    <span className="font-bold">Total Amount</span>
                    <p className="text-sm text-gray-500">inc. of tolls and taxes</p>
                </div>
                <div className="">
                    <span className="flex items-center justify-end">
                        <span className="text-xl font-bold">₹{bookingDetails?.totalAmount || selectedVehicle?.ratePer12Hrs}</span>
                    </span>
                    <span className="text-blue-500 font-semibold text-end">Fare .Breakup</span>
                </div>
            </div>
        </div>
    );
};

export default PaymentPage;

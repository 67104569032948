import DashboardIcon from '@mui/icons-material/Dashboard';
import BookingIcon from '@mui/icons-material/Work';
import RentalIcon from '@mui/icons-material/Apartment';
import InvoiceIcon from '@mui/icons-material/Receipt';

export const menuItems = [
    { name: 'Dashboard', path: '', icon: <DashboardIcon /> },
    { name: 'Booking', path: 'Booking/Rental', icon: <BookingIcon /> },
    { name: 'Manage Vehciles', path: 'Create/vehicleType', icon: <RentalIcon /> },
    { name: 'Onboard Vehciles', path: 'onboard/Rental', icon: <RentalIcon /> },
    { name: 'Generate Invoice', path: 'created-invoice', icon: <InvoiceIcon /> },
    { name: 'Invoice List', path: 'invoice', icon: <InvoiceIcon /> },
    { name: 'Gallery', path: 'upload-to-gallery', icon: <InvoiceIcon /> },
    { name: 'Vendor Request', path: 'vendor-request', icon: <BookingIcon /> },
    { name: 'Post Blog', path: 'new-blog', icon: <BookingIcon /> },
    { name: 'Blog List', path: 'blog-list', icon: <BookingIcon /> },
    { name: 'Featured Vehicle', path: 'feature-vehicle-inventory', icon: <BookingIcon /> },

];

export const managermenuItems = [
    // { name: 'Dashboard', path: '', icon: <DashboardIcon /> },
    { name: 'Manage Booking', path: '/manager/manage/', icon: <BookingIcon /> },
 

];
export const AdminBookingPath = [
    {
        "id": 1,
        "title": "Rental Booking",
        "link": "Rental",
    },
    {
        "id": 2,
        "title": "Tour and Travel Booking",
        "link": "TourAndTravel",
    },
    {
        "id": 3,
        "title": "Taxi Type Booking",
        "link": "Taxi",
    },

]

export const AdminInvoice = [
    {
        "id": 1,
        "title": "Invoice Voice",
        "link": "invoice",
    },
    {
        "id": 2,
        "title": "Create Invoice",
        "link": "created-invoice",
    },
    

]

export const OnboardVehcilePaths = [
    {
        "id": 1,
        "title": "Onboard Rental Vehicle ",
        "link": "Rental",
    },
    {
        "id": 2,
        "title": "Onboard Taxi Vehicle ",
        "link": "Taxi",
    },
    {
        "id": 3,
        "title": "Onboard TourAndTravel Vehicle ",
        "link": "TourAndTravel",
    },]

export const ManageVehiclePaths = [
    {
        "id": 1,
        "title": "Add Vehicle Type",
        "link": "vehicleType",
    },
    {
        "id": 2,
        "title": "Add Rate Per Km",
        "link": "rateperkm",
    },

    {
        "id": 3,
        "title": "Add VehicleModel",
        "link": "vehiclemodel",
    },
    {
        "id": 4,
        "title": "Add Fuel",
        "link": "fuel",
    },
    {
        "id": 5,
        "title": "Add Service",
        "link": "serviceType",
    },
]
import React from 'react';
import {
  Grid,
  Button,
  Typography,
  TextField,
  Divider,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
} from '@mui/material';

const InvoiceForm = ({ invoiceData, handleInputChange, handleItemChange, addNewItem }) => {

  return (
    <Box p={3}>
      <Typography variant="h4" fontWeight="bold" align="center" gutterBottom>
        Invoice Form
      </Typography>
      <Divider sx={{ my: 2 }} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" align="left">Date:</Typography>
          <TextField
            type="date"
            size="small"
            fullWidth
            name="date"
            value={invoiceData.date}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" align="left">Customer ID:</Typography>
          <TextField
            size="small"
            fullWidth
            name="customerId"
            value={invoiceData.customerId}
            onChange={handleInputChange}
            placeholder="Enter Customer ID"
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* Customer Details */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" align="left">Bill To:</Typography>
          <TextField
            size="small"
            fullWidth
            name="billTo"
            value={invoiceData.billTo}
            onChange={handleInputChange}
            placeholder="Enter Billing Address"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" align="left">Ship To:</Typography>
          <TextField
            size="small"
            fullWidth
            name="shipTo"
            value={invoiceData.shipTo}
            onChange={handleInputChange}
            placeholder="Enter Shipping Address"
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* Description and Amount */}
      <TableContainer component={Paper} variant="outlined">
        <Table>
          <TableBody>
            <TableRow>
              <TableCell width="70%">
                <Typography align="left">Description</Typography>
              </TableCell>
              <TableCell width="30%">
                <Typography align="left">Amount</Typography>
              </TableCell>
            </TableRow>
            {invoiceData.items.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    value={item.description}
                    onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                    placeholder="Enter Description"
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    fullWidth
                    type="number"
                    value={item.amount}
                    onChange={(e) => handleItemChange(index, 'amount', e.target.value)}
                    placeholder="Enter Amount"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Button onClick={addNewItem} style={{ margin: '10px 0' }}>
          Add New Item
        </Button>
      </TableContainer>

      <Divider sx={{ my: 2 }} />

      {/* Additional Details */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" align="left">Discount Amount:</Typography>
          <TextField
            size="small"
            fullWidth
            type="number"
            name="discountAmount"
            value={invoiceData.discountAmount}
            onChange={handleInputChange}
            placeholder="Enter Discount Amount"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" align="left">Discount %:</Typography>
          <TextField
            size="small"
            fullWidth
            type="number"
            name="discountPercent"
            value={invoiceData.discountPercent}
            onChange={handleInputChange}
            placeholder="Enter Discount Percentage"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" align="left">Tax Rate %:</Typography>
          <TextField
            size="small"
            fullWidth
            type="number"
            name="taxRate"
            value={invoiceData.taxRate}
            onChange={handleInputChange}
            placeholder="Enter Tax Rate"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" align="left">Other Charges:</Typography>
          <TextField
            size="small"
            fullWidth
            type="number"
            name="otherCharges"
            value={invoiceData.otherCharges}
            onChange={handleInputChange}
            placeholder="Enter Other Charges"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" align="left">Toll Tax:</Typography>
          <TextField
            size="small"
            fullWidth
            type="number"
            name="toll"
            value={invoiceData.toll}
            onChange={handleInputChange}
            placeholder="Enter Tall Tax"
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* Date Range */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" align="left">From Date:</Typography>
          <TextField
            type="date"
            size="small"
            fullWidth
            name="fromDate"
            value={invoiceData.fromDate}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="subtitle1" align="left">To Date:</Typography>
          <TextField
            type="date"
            size="small"
            fullWidth
            name="toDate"
            value={invoiceData.toDate}
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceForm;

import api from "./apiUtility";

export const rentalBookingDetails = async (bookingId) => {
  try {
    const response = await api.get(`/get-booking-details/${bookingId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const adminApproveRejectRental = async (bookingId,approvalStatus,rejectionReason = "") => {
  try {
    const payload = {
      approvalStatus,
    };
    if (rejectionReason) {
      payload.rejectionReason = rejectionReason;
    }
    const response = await api.patch(`/admin/approve-reject/rental-booking/${bookingId}`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const managerApproveRejectRental = async (bookingId,approvalStatus,rejectionReason = "") => {
  try {
    const payload = {
      approvalStatus,
    };
    if (rejectionReason) {
      payload.rejectionReason = rejectionReason;
    }
    const response = await api.patch(`/manager/approve-reject/rental-booking/${bookingId}`,
      payload
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const adminLoginAPi = async (payload) => {
  try {
    const response = await api.post('/admin/login',payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const taxiBookingDetails = async (bookingId) => {
  try {
    const response = await api.get(`/get-taxi-booking-details/${bookingId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateStatusTaxiBooking= async (bookingId,status) => {
  try {
    const response = await api.patch(`/admin/update-status/taxi-booking/${bookingId}`,{status});
    return response;
  } catch (error) {
    throw error;
  }
};

export const tourPackageBookingDetails = async (bookingId) => {
  try {
    const response = await api.get(`/tour-package-booking-details/${bookingId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateStatusTourPacakgeBooking= async (bookingId,bookingStatus) => {
  try {
    const response = await api.patch(`/admin/update-status/tour-package-booking/${bookingId}`,{bookingStatus});
    return response;
  } catch (error) {
    throw error;
  }
};


export const tourandPacakges= async () => {
  try {
    const response = await api.get(`/search-tour-and-packages`);
    return response;
  } catch (error) {
    throw error;
  }
};


export const getAllGalleryImages= async () => {
  try {
    const response = await api.get(`/gallery/retrive-gallery`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const uploadGalleryImages = async (payload) => {
  try {
    const response = await api.post(`/gallery/add-to-gallery`,payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const vendorRequest = async () => {
  try {
    const response = await api.get(`/admin/retrive-vendor-request`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createNewBlog = async (payload) => {
  try {
    const response = await api.post(`/blog/create/new-blog`,payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deletBlog= async (blogId) => {
  try {
    const response = await api.delete(`/blog/delete-blog/${blogId}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editBlog= async (blogId,payload) => {
  try {
    const response = await api.patch(`/blog/edit-blog/${blogId}`,payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const blogDetails= async (blogId) => {
  try {
    const response = await api.get(`/blog/get-blog-details/${blogId}`);
    return response;
  } catch (error) {
    throw error;
  }
};
// featured
export const addNewFeaturedVehicle = async (id) => {
  try {
    const payload={
      vehicleId:id
    }

    const response = await api.post(`/admin/create-new-featured-vehicle-inventory`,payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editFeaturedVehicle = async (id,vehicleId) => {
  try {
    const payload={
      vehicleId:vehicleId
    }
    const response = await api.patch(`/admin/edit-featured-vehicle-inventory/${id}`,payload);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllFeaturedVehicle = async () => {
  try {
    const response = await api.get(`/all-featured-veehicle-inventory`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteFeaturedVehicle = async (id) => {
  try {
    const response = await api.delete(`/admin/delete-featured-vehicle-inventory/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};


export const getResponse = async (url) => {
  try {
    const response = await api.get(`${url}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const markNotification= async (id) => {
  try {
    const response = await api.patch(`/notification/mark-notification-read/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

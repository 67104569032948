import React from "react";
import { Helmet } from "react-helmet";

const SEOHelmet = ({
    title, 
 
}) => {
    return (
        <Helmet>
            <title>{title} </title>
            <meta
                name="description"
                content="TSJ (The Standard Journey) offers premium car rental services with a wide range of vehicles, affordable pricing, and exceptional customer service. Explore our fleet for a seamless and comfortable journey tailored to your travel needs."
            />
            <meta name="keywords" content='Self Drive car/cars, car/veachle on Rent,Car rental services, Car leasing services, Self DRIVEN car, Driver less car' />
            <meta property="og:title" content={title} />
            <meta property="og:description"   content="Discover the best car rental experience with TSJ (The Standard Journey). Choose from our fleet of modern and well-maintained vehicles for a smooth and enjoyable travel experience."  />
            <meta property="og:url" content="https://orca-app-iyk4e.ondigitalocean.app" />

        </Helmet>
    );
};

export default SEOHelmet;
import React, { useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import Signup from "../Assests/Auth/Signup.png";
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'; // Import axios for making API requests
import { setSignupData } from '../slices/authSlices';
import { BASE_API } from '../Services/apis';
import Logo from "../Assests/Home/image 14.png"
import { Helmet } from 'react-helmet';
import SEOHelmet from '../Components/common/SeoHelmet';

const SignupOtpForm = () => {
    const navigate = useNavigate();
    const OTP_LENGTH = 6;
    const inputRefs = useRef([]);
    const state = useSelector((state) => state); // Log the whole state
    console.log("state data", state)
    const signupData = state.signup?.signupData; // Using optional chaining

    useEffect(() => {
        console.log(signupData);
    }, [signupData]);



    const handleResendOtp = async () => {

        try {
            const orderId = signupData?.orderId;

            const response = await axios.post(`${BASE_API}/user/resend-otp`, {
                orderId: orderId
            });

            // dispatch(setSignupData({ ...signupData, orderId })); // Dispatching with all values 

        } catch (error) {
            toast.error(error?.response?.data?.message);
            console.error(error.message);
        } finally {
        }
    };


    // Formik setup
    const formik = useFormik({
        initialValues: {
            otp: new Array(OTP_LENGTH).fill(""), // Initialize OTP array with empty strings
        },
        validationSchema: Yup.object({
            otp: Yup.array()
                .of(Yup.string().required("Required").matches(/^\d$/, "Only digits are allowed"))
                .length(OTP_LENGTH, `Must be exactly ${OTP_LENGTH} digits`), // Change min to length
        }),
        onSubmit: async (values) => {
            const otpValue = values.otp.join(""); // Join OTP digits into a single string
            formik.resetForm();

            try {
                const response = await axios.post('https://orca-app-iyk4e.ondigitalocean.app/api/v1/user/verify-signup-otp', {
                    mobile: signupData.mobile, // Sending mobile from signupData
                    orderId: signupData.orderId, // Sending orderId from signupData
                    otp: otpValue, // Sending the entered OTP
                });
                if (response.status === 201 || 200) {
                    // dispatch(setSignupData({  response.data }));
                    toast.success("successfully Signup ")
                    navigate("/login")
                }
                else {

                    toast.error("Error while Signup ")

                }
                // Handle success response here (e.g., navigate to another page, show success message)
            } catch (error) {
                console.error("Error submitting OTP: ", error);
                // Handle error response here (e.g., show error message to user)
            }
        },
    });

    // Handle OTP input changes
    const handleChange = (e, index) => {
        const { value } = e.target;
        if (/^[0-9]{1}$/.test(value) || value === "") {
            const newOtp = [...formik.values.otp];
            newOtp[index] = value;
            formik.setFieldValue("otp", newOtp);

            if (value && index < OTP_LENGTH - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    // Handle backspace to navigate between inputs
    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && formik.values.otp[index] === "") {
            if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    // Handle paste event
    const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData('text').slice(0, OTP_LENGTH).split("");
        const newOtp = [...formik.values.otp];

        pastedData.forEach((char, index) => {
            if (/^[0-9]{1}$/.test(char)) {
                newOtp[index] = char;
            }
        });

        formik.setFieldValue("otp", newOtp);

        if (pastedData.length === OTP_LENGTH) {
            inputRefs.current[OTP_LENGTH - 1].focus();
        }
    };

    return (
        <>
            <SEOHelmet title="SignUp Verify Otp | TSJ Car Rentals" />
            <div className="hidden md:flex justify-center  items-center h-screen">
                <div className="bg-white shadow-lg grid grid-cols-12 rounded-lg p-4 w-[100vw] h-[100vh] text-[0.9rem]">
                    <div className="px-8 col-span-5 text-start w-[65%] mx-auto p-6 font-sf-pro-rounded flex flex-col space-y-5 place-content-center">
                        <Link to="/">
                            <div className="flex justify-center mb-6">
                                <img src={Logo} alt="Logo" className="w-[50px] h-[50px]" />
                            </div>
                        </Link>
                        <h2 className="text-xl font-bold mb-6 text-center">OTP Validation</h2>

                        <form className='space-y-4' onSubmit={formik.handleSubmit}>
                            <div className="flex justify-between mb-4">
                                {formik.values.otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        onPaste={handlePaste}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        className={`w-12 h-12 text-center text-xl border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${formik.errors.otp && formik.errors.otp[index] ? "border-red-500" : ""}`}
                                    />
                                ))}
                            </div>

                            {formik.errors.otp && <p className="text-red-500 text-sm mb-4">Please enter a valid OTP.</p>}

                            <button
                                type="submit"
                                className="w-full h-12 cursor-pointer bg-[#162D3A] text-white rounded-md hover:bg-gray-800"
                            >
                                Verify OTP
                            </button>

                            <div className='flex justify-between items-start'>
                                <button
                                    type="button"
                                    onClick={handleResendOtp}
                                    className="text-[#959CB6] cursor-pointer   text-[0.8rem]"
                                >
                                    Resend OTP
                                </button>
                                <p className=" text-[#000] text-[16px] leading-[16px] tracking-[0.01em]">
                                    Go to <a href="/Signup" className="text-blue-600 font-bold italic">Signup</a>
                                </p>
                            </div>
                        </form>
                    </div>
                    <div className="col-span-7 h-full">
                        <img
                            src={Signup}
                            alt="Signup Illustration"
                            className="w-full h-full object-cover rounded-lg max-h-[97vh]"
                        />
                    </div>
                </div>


            </div>
            <section
                style={{
                    backgroundImage: `url(${Signup})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                className="w-full h-screen md:hidden block"
            >
                <div className=" grid grid-cols-12 p-4 w-full h-full text-[0.9rem] place-content-center">
                    {/* Logo Image Centered for Mobile */}
                    <div className="flex justify-center mb-10 col-span-12">
                        <Link to="/">
                            <img src={Logo} alt="Logo" className="w-[50px] h-[50px]" />
                        </Link>
                    </div>
                    <div
                        className="px-8 col-span-5 text-start rounded-lg w-[90vw] md:w-[65%] mx-auto p-6 font-sf-pro-rounded flex flex-col space-y-5 place-content-center h-fit"
                        style={{ backdropFilter: "blur(10px)" }}
                    >
                        <h2 className="text-xl text-white  font-bold mb-6 text-center">OTP Validation</h2>
                        <form className="space-y-4" onSubmit={formik.handleSubmit}>
                            <div className="flex justify-between mb-4">
                                {formik.values.otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyDown={(e) => handleKeyDown(e, index)}
                                        onPaste={handlePaste}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        className={`w-12 h-12 text-center text-xl border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${formik.errors.otp && formik.errors.otp[index] ? 'border-red-500' : ''
                                            }`}
                                    />
                                ))}
                            </div>
                            {formik.errors.otp && <p className="text-red-500 text-sm mb-4">Please enter a valid OTP.</p>}
                            <button
                                type="submit"
                                className="w-full h-12 cursor-pointer bg-[#162D3A] text-white rounded-md hover:bg-gray-800"
                            >
                                Verify OTP
                            </button>
                            <div className='flex justify-between items-start'>
                                <button
                                    type="button"
                                    onClick={handleResendOtp}
                                    className="text-blue-600 cursor-pointer   text-[0.8rem]"
                                >
                                    Resend OTP
                                </button>
                                <p className=" text-[#fff] text-[16px] leading-[16px] tracking-[0.01em]">
                                    Go to <a href="/Signup" className="text-blue-600 font-bold italic">Signup</a>
                                </p>
                            </div>

                        </form>
                    </div>

                </div>

            </section>

        </>

    );
};

export default SignupOtpForm;

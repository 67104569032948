import React from 'react'
import RentalBookingView from '../Layout/tables/AdminBookings/RentalBookingDetails'
import {
    adminApproveRejectRental,
} from "../Services/adminAPI";
const AdminRentalBookingApprove = () => {
    return (
        <RentalBookingView
        approveRejectRental={adminApproveRejectRental}
        />
    )
}

export default AdminRentalBookingApprove
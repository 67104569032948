import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Box,
    MenuItem,
    Chip,
} from '@mui/material';
import {
    getVehicleTypesList,
    getRatePerKmList,
    getVehicleModelList,
    getFuelList,
    editTaxiVehicle,
} from '../../Services/AddVehicleDetails';
import toast from 'react-hot-toast';

const EditTaxiVehicleModal = ({ open, onClose, selectedTaxi, fetchVehicles }) => {
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [ratePerKm, setRatePerKm] = useState([]);
    const [vehicleModels, setVehicleModels] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [amenityInput, setAmenityInput] = useState('');
    const [locationInput, setLocationInput] = useState('');

    const [formData, setFormData] = useState({
        vehicleId: "",
        vehicleType: '',
        vehicleModel: '',
        vehicleNumber: '',
        fuelType: '',
        transmission: '',
        ratePerDay: '',
        ratePer12Hrs: '',
        limitPer12Hrs: '',
        extraHoursRate: '',
        extraKmRate: '',
        amenities: [],
        description: '',
        cancellationRule: '',
        seats: '',
        img: '',
        contactNumber: '',
        availableLocation: [],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    vehicleTypesResponse,
                    ratePerKmResponse,
                    vehicleModelsResponse,
                    fuelTypesResponse,
                ] = await Promise.all([
                    getVehicleTypesList(),
                    getRatePerKmList(),
                    getVehicleModelList(),
                    getFuelList(),
                ]);

                setVehicleTypes(vehicleTypesResponse?.data?.data || []);
                setRatePerKm(ratePerKmResponse?.data?.data || []);
                setVehicleModels(vehicleModelsResponse?.data || []);
                setFuelTypes(fuelTypesResponse?.data?.data || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (open) {
            fetchData();
        }
    }, [open]);

    useEffect(() => {
        if (selectedTaxi) {
            setFormData({
                vehicleId: selectedTaxi._id || '',
                vehicleType: selectedTaxi.vehicleType || '',
                vehicleModel: selectedTaxi.vehicleModel || '',
                vehicleNumber: selectedTaxi.vehicleNumber || '',
                fuelType: selectedTaxi.fuelType || '',
                transmission: selectedTaxi.transmission || '',
                ratePerDay: selectedTaxi.ratePerDay || '',
                ratePer12Hrs: selectedTaxi.ratePer12Hrs || '',
                limitPer12Hrs: selectedTaxi.limitPer12Hrs || '',
                extraHoursRate: selectedTaxi.extraHoursRate || '',
                extraKmRate: selectedTaxi.extraKmRate || '',
                amenities: selectedTaxi.amenities || [],
                description: selectedTaxi.description || '',
                cancellationRule: selectedTaxi.cancellationRule || '',
                seats: selectedTaxi.seats || '',
                img: selectedTaxi.img || '',
                contactNumber: selectedTaxi.contactNumber || '',
                availableLocation: selectedTaxi.availableLocation || [],
            });
        }
    }, [selectedTaxi]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleAmenityAdd = () => {
        if (amenityInput.trim() && !formData.amenities.includes(amenityInput)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                amenities: [...prevFormData.amenities, amenityInput],
            }));
            setAmenityInput('');
        }
    };


    const handleAmenityRemove = (amenity) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            amenities: prevFormData.amenities.filter((item) => item !== amenity)
        }));
    };

    const handleLocationAdd = () => {
        if (locationInput.trim() && !formData.availableLocation.includes(locationInput)) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                availableLocation: [...prevFormData.availableLocation, locationInput],
            }));
            setLocationInput('');
        }
    };

    const handleLocationRemove = (location) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            availableLocation: prevFormData.availableLocation.filter((item) => item !== location)
        }));
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await editTaxiVehicle(formData);
            if (response.status === 200) {
                await fetchVehicles();
                toast.success('Successfully updated Vehicle');
                onClose();
            }

        } catch (error) {
            console.error('API Error:', error);
            toast.error('Failed to update Vehicle');
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Edit Taxi Vehicle</DialogTitle>
            <DialogContent>
                <Box component="form" onSubmit={handleSubmit} sx={{ display: 'grid', gap: 2 }}>
                    <TextField
                        label="Vehicle Type"
                        name="vehicleType"
                        select
                        SelectProps={{ native: true }}
                        value={formData.vehicleType}
                        onChange={handleChange}
                    >
                        <option value=""></option>
                        {vehicleTypes?.map((type) => (
                            <option key={type._id} value={type.vehicleType}>
                                {type.vehicleType}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        label="Vehicle Model"
                        name="vehicleModel"
                        select
                        SelectProps={{ native: true }}
                        value={formData.vehicleModel}
                        onChange={handleChange}
                    >
                        <option value=""></option>
                        {vehicleModels?.map((model) => (
                            <option key={model._id} value={model.vehicleModel}>
                                {model.vehicleModel}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        label="Vehicle Number"
                        name="vehicleNumber"
                        value={formData.vehicleNumber}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Fuel Type"
                        name="fuelType"
                        select
                        SelectProps={{ native: true }}
                        value={formData.fuelType}
                        onChange={handleChange}
                    >
                        <option value=""></option>
                        {fuelTypes?.map((fuel) => (
                            <option key={fuel._id} value={fuel.fuelType}>
                                {fuel.fuelType}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                        label="Transmission"
                        name="transmission"
                        select
                        value={formData.transmission}
                        onChange={handleChange}
                    >
                        <MenuItem value="Manual Transmission">Manual Transmission</MenuItem>
                        <MenuItem value="Automatic Transmission">Automatic Transmission</MenuItem>
                    </TextField>
                    <TextField
                        label="Rate Per Day"
                        name="ratePerDay"
                        type="number"
                        value={formData.ratePerDay}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Rate Per 12Hrs"
                        name="ratePer12Hrs"
                        type="number"
                        value={formData.ratePer12Hrs}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Limit Per 12Hrs"
                        name="limitPer12Hrs"
                        type="number"
                        value={formData.limitPer12Hrs}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Extra Hours Rate"
                        name="extraHoursRate"
                        type="number"
                        value={formData.extraHoursRate}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Extra Km Rate"
                        name="extraKmRate"
                        type="number"
                        value={formData.extraKmRate}
                        onChange={handleChange}
                    />
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                                label="Available Location"
                                value={locationInput}
                                onChange={(e) => setLocationInput(e.target.value)}
                                fullWidth
                            />
                            <Button variant="contained" onClick={handleLocationAdd}>Add</Button>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            {formData.availableLocation.map((location) => (
                                <Chip
                                    key={location}
                                    label={location}
                                    onDelete={() => handleLocationRemove(location)}
                                />
                            ))}
                        </Box>
                    </Box>
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <TextField
                                label="Add Amenity"
                                value={amenityInput}
                                onChange={(e) => setAmenityInput(e.target.value)}
                                fullWidth
                            />
                            <Button variant="contained" onClick={handleAmenityAdd}>
                                Add
                            </Button>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                            {formData.amenities.map((amenity) => (
                                <Chip
                                    key={amenity}
                                    label={amenity}
                                    onDelete={() => handleAmenityRemove(amenity)}
                                />
                            ))}
                        </Box>
                    </Box>
                    <TextField
                        label="Special Notes"
                        name="description"
                        multiline
                        value={formData.description}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Cancellation Rule"
                        name="cancellationRule"
                        value={formData.cancellationRule}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Seats"
                        name="seats"
                        type="number"
                        value={formData.seats}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Image URL"
                        name="img"
                        value={formData.img}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Contact Number"
                        name="contactNumber"
                        value={formData.contactNumber}
                        onChange={handleChange}
                    />
                    <DialogActions>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button type="submit">Update Vehicle</Button>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default EditTaxiVehicleModal;

import React, { useEffect } from "react";
import TripNavbar from "../Components/common/TripNavbar";
import BlueBar from "../Components/Core/TripDetails/BlueBar";
import MainSection from "../Components/Core/TripDetails/MianSection";

import SEOHelmet from "../Components/common/SeoHelmet";

const BookingReview = () => {


    const selectedVehicle = JSON.parse(localStorage.getItem('selectedVehicle'));

    return (
        <div>
            <SEOHelmet title="Booking Payments | TSJ Car Rentals"/>
           
            <TripNavbar />
            <BlueBar selectedVehicle={selectedVehicle} />
            <MainSection selectedVehicle={selectedVehicle} />
        </div>
    )
}

export default BookingReview;
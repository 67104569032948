import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getTaxiList } from '../../../Services/GetBooking';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Typography,
    Box
} from '@mui/material';
import { Helmet } from 'react-helmet';
import SEOHelmet from '../../../Components/common/SeoHelmet';
const AllTaxiBookingList = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [bookings, setBookings] = useState([]);
    const token = JSON.parse(localStorage.getItem("token"));
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await getTaxiList();
                console.log("All Rental bookings", response?.data?.bookings);
                setBookings(response?.data?.bookings);
            } catch (error) {
                console.error(error);
                setError("Error fetching bookings");
            } finally {
                setLoading(false);
            }
        };

        fetchBookings();
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page
    };


    const handleRowClick = (bookingId) => {
        navigate(`/admin/manage/Booking/taxi-booking-detail/${bookingId}`);
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 4, maxHeight: "70vh", overflowY: "scroll" }}>
            <SEOHelmet title="All Taxis Booking   | TSJ Car Rentals" />
            <Box p={2} sx={{ textAlign: "start" }}>
                <Typography variant="h5" sx={{ fontWeight: "600", float: "left" }}>All Taxi Bookings</Typography>
            </Box>
            <TableContainer component={Paper} sx={{ overflowX: "scroll", maxHeight: "auto" }}>
                <Table stickyHeader aria-label="booking table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Booking Name</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Vehicle Type</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Pickup Date</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Drop Date</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Pickup Location</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Drop Location</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Fare</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Booking Status</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {bookings?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((booking) => (
                            <TableRow
                                hover
                                key={booking._id}
                                onClick={() => handleRowClick(booking._id)}
                                sx={{ cursor: 'pointer' }}
                            >
                                <TableCell sx={{ color: "gray" }}>{booking.name}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{booking.vehicle?.vehicleType || 'N/A'}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{new Date(booking.pickupDate).toLocaleDateString()}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{new Date(booking.dropDate).toLocaleDateString()}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{booking.pickupAddress}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{booking.dropAddress}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{`₹${booking.fare}`}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{booking.status}</TableCell>
                                <TableCell sx={{ color: "gray" }}>View
                                    <VisibilityIcon />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>




            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={bookings?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default AllTaxiBookingList

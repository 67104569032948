import React, { useState, useEffect } from 'react';
import {
  AppBar, Toolbar, Typography, SvgIcon, IconButton, Badge, Popover, List, ListItem, ListItemText,
  ListItemButton, Divider,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { getResponse, markNotification } from '../../../../Services/adminAPI';
import io from 'socket.io-client';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const Header = () => {
  const navigate = useNavigate()
  const [notificationModel, setNotificationModel] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const location = useLocation();
  const loginType = location.pathname.split("/")[1];
  const user = JSON.parse(window.localStorage.getItem("user"));
  const { _id: adminId, role } = user?.data;

  const fetchUnreadCount = async () => {
    try {
      const response = await getResponse(`/notification/get-unread-notification-count`);
      setUnreadCount(response.data.totalUnreadCount);
    } catch (error) {
      console.error('Error fetching unread notification count:', error);
    }
  };

  useEffect(() => {
    // const socket = io('http://localhost:8000/', {
    //   query: { userId: adminId, role: role },
    // });

    const socket = io('https://www.thestandardjourney.com/', {
      query: { userId:adminId, role:role },
    });

    socket.on('newBooking', (notification) => {
      setNotifications((prev) => [...prev, notification]);
      fetchUnreadCount()
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {

    fetchUnreadCount();
  }, []);

  const fetchNotification = async () => {
    try {
      const response = await getResponse(`/notification/my-notifications`);
      if (response.status === 200) {
        setNotifications(response.data.notifications);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  const handleNotificationClick = (event) => {
    fetchNotification();
    setAnchorEl(event.currentTarget);
    setNotificationModel(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNotificationModel(false);
  };

  const markNotificationRead = async (notificationId, pageUrl) => {
    try {
      const response = await markNotification(notificationId);
      if (response.status === 200) {
        fetchNotification();
        window.open(pageUrl, '_blank');
      }
      // navigate(`${pageUrl}`)
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  return (
    <>
      <AppBar position="relative" sx={{ maxWidth: "90vw", float: "right", display: "flex" }}>
        <Toolbar>
          <Typography variant="h6">
            {loginType.charAt(0).toUpperCase() + loginType.slice(1).toLowerCase()} Dashboard
          </Typography>
          <IconButton onClick={handleNotificationClick}>
            <Badge badgeContent={unreadCount} color="error">
              <NotificationsOutlinedIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>

      <Popover
        open={notificationModel}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={{ width: 300, maxHeight: 400, overflowY: 'auto' }}>
          { notifications && notifications?.length > 0 ? (
            notifications.map((notification) => (
              <React.Fragment key={notification._id}>
                <ListItemButton
                  onClick={() => markNotificationRead(notification._id, notification?.pageUrl)}
                >
                  <ListItemText
                    primary={notification.title || 'New Notification'}
                    secondary={notification.message || 'No details available'}
                  />
                  {notification.isRead ? (
                    <CheckCircleIcon color="primary" sx={{ marginLeft: 'auto' }} />
                  ) : (
                    <CheckIcon sx={{ marginLeft: 'auto' }} />
                  )}
                </ListItemButton>
                <Divider />
              </React.Fragment>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No notifications available" />
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  );
};

export default Header;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addNewVehicleTypes, getVehicleTypesList, deleteVehicleTypes } from '../../Services/AddVehicleDetails';
import { Button, Box, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import SEOHelmet from '../../Components/common/SeoHelmet';

const AddNewVehicleType = () => {
    const [vehicleTypes, setVehicleTypes] = useState([]);

    // Fetch existing vehicle types on mount
    useEffect(() => {
        const fetchVehicleTypes = async () => {
            try {
                const response = await getVehicleTypesList();
                setVehicleTypes(response?.data?.data || []);
            } catch (error) {
                console.error('Error fetching vehicle types:', error);
            }
        };
        fetchVehicleTypes();
    }, []);

    // Formik setup
    const formik = useFormik({
        initialValues: {
            vehicleType: ''
        },
        validationSchema: Yup.object({
            vehicleType: Yup.string()
                .required('Vehicle type is required')
                .min(2, 'Vehicle type must be at least 2 characters'),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                await addNewVehicleTypes(values);
                setVehicleTypes([...vehicleTypes, { vehicleType: values.vehicleType, _id: Date.now() }]); // Update list after adding
                resetForm(); // Clear form
            } catch (error) {
                console.error('Error adding vehicle type:', error);
            }
        }
    });

    // Handle delete operation
    const handleDelete = async (id) => {
        try {
            await deleteVehicleTypes(id); // Call delete API
            setVehicleTypes(vehicleTypes.filter((vehicle) => vehicle._id !== id)); // Remove deleted vehicle from list
        } catch (error) {
            console.error('Error deleting vehicle type:', error);
        }
    };

    return (
        <div style={{ maxHeight: "72vh" }}>
             <SEOHelmet title="Create Rental Vehicle | TSJ Car Rentals "/>
            <Typography variant="h5" gutterBottom>
                Add New Vehicle Type
            </Typography>

            {/* Form to Add New Vehicle Type */}
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '3fr 1fr', // 75% for input, 25% for button
                    alignItems: 'center',
                    gap: 2,
                    marginTop: 2,
                }}
            >
                <TextField
                    id="vehicleType"
                    name="vehicleType"
                    label="Vehicle Type"
                    value={formik.values.vehicleType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.vehicleType && Boolean(formik.errors.vehicleType)}
                    helperText={formik.touched.vehicleType && formik.errors.vehicleType}
                    margin="normal"
                />
                <Button color="primary" variant="contained" type="submit">
                    Add Vehicle Type
                </Button>
            </Box>

            {/* Table to display Vehicle Types */}
            <TableContainer
                component={Paper}
                sx={{
                    height: '400px', // Adjust this value to the desired height
                    overflowY: 'scroll', // Enables vertical scrolling
                }}
            >
                <Table stickyHeader aria-label="vehicle table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1976D4' }}>
                            <TableCell sx={{ color: 'white' }}>Vehicle Type</TableCell>
                            <TableCell sx={{ color: 'white' }}>ID</TableCell>
                            <TableCell sx={{ color: 'white' }}>Version</TableCell>
                            <TableCell sx={{ color: 'white' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vehicleTypes.map((type) => (
                            <TableRow key={type._id}>
                                <TableCell>{type.vehicleType}</TableCell>
                                <TableCell>{type._id}</TableCell>
                                <TableCell>{type.__v}</TableCell>
                                <TableCell>
                                    <Button
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => handleDelete(type._id)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default AddNewVehicleType;

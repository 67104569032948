import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/common/Navbar";
import backgroud from "../Assests/Cars/background.png"
import instentRent from "../Assests/services/instantRent.png";
import longTrip from "../Assests/services/longTrip.png";
import Driver from "../Assests/services/personalDriver.png";
import Footer from "../Components/common/Footer";
import { CiMail } from "react-icons/ci";

import { motion } from "framer-motion";

import SEOHelmet from "../Components/common/SeoHelmet";
const Services = () => {

    const servicesData = [
        {
            subtitle: "Car Rental",
            subPath: "Rental",
            description:
                "We provide direct rental services when you need wherever you are. Our officers are quick to respond in carrying out this task . . .",
            buttonText: "",
            bgClass: instentRent,
        }, {
            subtitle: "Taxi Services",
            subPath: "Taxi Packages",
            description:
                "We have professional agents to accompany your trip useful for your protection from disturbances that you do not like . . .",
            buttonText: "Read More",
            bgClass: Driver,
        }, {
            subtitle: "Tour & Travel ",
            subPath: "Tour and Travel",
            description:
                "Long trips whenever and wherever you want can comfortably use our car collection that supports long and long trips . . .",
            buttonText: "Read More",
            bgClass: longTrip,
        }

    ]
    return (
        <div className="w-[100vw]">
            <SEOHelmet title="Services | TSJ Car Rentals" />
            <Navbar />
            <div className="md:h-[35rem] h-[20rem]">
                <img src={"../assets/tour-banner2.jpg"} className="w-[100%] h-full" alt="banner" />
            </div>
            <section className="">
                <h1 className="text-[2.5rem] -z-10 font-semibold text-[#0083FC] my-5">Services</h1>
                <div className="relative z-10 grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto w-[80vw]">
                    {servicesData.map((service, index) => (
                        <Link to={service.subPath} key={index}>
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.2 }}
                                whileHover={{ scale: 1.03 }}
                                className="p-3 relative bg-white flex flex-col justify-between rounded-lg shadow border-2 border-gray-200 transition-transform duration-300 hover:shadow-lg"
                            >
                                <img src={`${service.bgClass}`} className="h-[200px] w-full rounded-md" />
                                <div className="mt-4">
                                    <h3 className="text-[#4A4A4A] text-2xl font-semibold mb-4">{service.subtitle}</h3>
                                    <p className="mb-6">{service.description}</p>
                                    <Link to={`/Trip/${service.subPath.replace(/\s+/g, '')}`} className="w-full">
                                        <button className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 w-full transition duration-300">
                                            {"Go For"} {service.subtitle}
                                        </button>
                                    </Link>
                                </div>
                            </motion.div>
                        </Link>
                    ))}
                </div>
            </section>
            <section className="bg-blue-800 w-full lg:h-[13rem] md:h-[12rem] xsm:h-[15rem] grid place-content-center my-10">
                <div className="md:w-[35rem] lg:w-[35rem] xl:w-[35rem] w-full px-4">
                    <h2 className="text-white text-[2.2rem]">Contact Us</h2>
                    <p className="text-white text-[15px]">
                        We can help you provide the latest news whenever and wherever you are via email
                    </p>
                    <div className="flex mt-4 md:justify-between lg:justify-between lg:flex-row md:flex-row xsm:flex-col xsm:items-center xsm:justify-center gap-4">
                        <div className="bg-white w-full md:w-[77%] lg:w-[77%] h-[3rem] flex items-center justify-center rounded-md overflow-hidden">
                            <div className="w-[10%] flex items-center justify-center">
                                <CiMail className="text-[#D9D9D9] w-8 h-8" />
                            </div>
                            <input
                                type="text"
                                placeholder="example@gmail.com"
                                className="w-[90%] h-full px-4 focus:outline-none text-gray-700"
                            />
                        </div>

                        <button
                            style={{
                                boxShadow: "0px 0px 25px 0px #B40D0D1A",
                            }}
                            className="text-white px-6 py-2 rounded-md hover:bg-red-600 transition duration-300"
                        >
                            Mail
                        </button>
                    </div>
                </div>
            </section>

            <section className="w-[100vw]">
                <Footer />
            </section>
        </div>
    )
}

export default Services
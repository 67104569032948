import "./CabFilter.css";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { setVehicles } from "../../../slices/VehiclesSlice";
import { useDispatch } from "react-redux";
import { BASE_API } from "../../../Services/apis";

const CabFilter = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedCabTypes, setSelectedCabTypes] = useState([]);
    const [selectedFuelTypes, setSelectedFuelTypes] = useState([]);
    const [selectedCabModels, setSelectedCabModels] = useState([]);
    const [fuelTypes, setFuelTypes] = useState([]);
    const [cabTypes, setCabTypes] = useState([]);
    const [cabModels, setCabModels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filteredResults, setFilteredResults] = useState([]);

    const RatePerType = [
        { id: 1, title: "ratePerHours" },
        { id: 2, title: "ratePerDay" },
    ];
    const [rateType, setRateType] = useState("ratePerHours"); // Set default to ratePerHours

    const [vehicleTypes, setVehicleTypes] = useState("");
    const [fromLocation, setFromLocation] = useState("");
    const [toLocation, setToLocation] = useState("");
    const [returnDate, setReturnDate] = useState("");
    const [selectedTime, setSelectedTime] = useState({ Time: "12:00" });
    const [dropoffDate, setDropoffDate] = useState("");
    const [dropoffTime, setDropoffTime] = useState("18:00");
    const [selectedDate, setSelectedDate] = useState({
        date: "",
        month: "",
        year: "",
        day: "",
    });

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        const pickUpDate = params.get("pickupDate");
        // const pickUpTime = params.get("pickupTime");
        console.log("pickUpDate", pickUpDate)
        const pickUpTime = decodeURIComponent(params.get("pickupTime"));
        console.log("pickUpTime", pickUpTime)

        if (pickUpDate) {
            const [year, month, day] = pickUpDate.split("-").map(Number);

            // Set selected date
            setSelectedDate({
                date: pickUpDate,
                month,
                year,
                day,
            });

            // Calculate dropoff date (10 days later)
            const dateObject = new Date(year, month - 1, day);
            dateObject.setDate(dateObject.getDate() + 10);

            // Format the dropoff date as YYYY-MM-DD
            const formattedDropoffDate = dateObject.toISOString().split("T")[0];
            console.log("dropoffDate", params.get("dropoffDate"))

            setDropoffDate(params.get("dropoffDate"));
            setDropoffTime(params.get("dropoffTime"));
        }

        if (pickUpTime) {
            setSelectedTime({ Time: pickUpTime });
        }
    }, [location.search]);



    useEffect(() => {
        const currentParams = new URLSearchParams(location.search);

        const pathSegments = location.pathname.split("/");
        const vehicleType = pathSegments[2];
        setVehicleTypes(vehicleType);
    }, [location]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const fuelResponse = await fetch(
                    `https://orca-app-iyk4e.ondigitalocean.app/api/v1/fuel-type-list`
                );
                const cabTypeResponse = await fetch(
                    `https://orca-app-iyk4e.ondigitalocean.app/api/v1/all-vehicles-type-list`
                );
                const cabModelResponse = await fetch(
                    `https://orca-app-iyk4e.ondigitalocean.app/api/v1/vehicle-model-list`
                );

                const [fuelData, cabTypeData, cabModelData] = await Promise.all([
                    fuelResponse.json(),
                    cabTypeResponse.json(),
                    cabModelResponse.json(),
                ]);

                setFuelTypes(fuelData.data || []);
                setCabTypes(cabTypeData.data || []);
                setCabModels(cabModelData || []);
            } catch (error) {
                console.error("Error fetching filter data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const fetchFilteredData = async (
        selectedCabTypes,
        selectedFuelTypes,
        selectedCabModels,
        updatedRateType = rateType // Use current rateType if not provided
    ) => {
        try {
            const currentParams = new URLSearchParams(location.search);
            currentParams.set("pickupLocation", currentParams.get("pickupLocation"));
            currentParams.set("dropLocation", currentParams.get("dropLocation"));
            currentParams.set("pickupDate", selectedDate.date);
            currentParams.set("pickupTime", selectedTime.Time);
            currentParams.set("dropoffDate", dropoffDate);
            currentParams.set("dropoffTime", dropoffTime);

            // Set rateType to query parameter
            currentParams.set("rateType", updatedRateType || rateType);

            if (selectedCabTypes.length > 0) {
                currentParams.set("vehicleType", selectedCabTypes.join(","));
            } else {
                currentParams.delete("vehicleType");
            }

            if (selectedFuelTypes.length > 0) {
                currentParams.set("fuelType", selectedFuelTypes.join(","));
            } else {
                currentParams.delete("fuelType");
            }

            if (selectedCabModels.length > 0) {
                currentParams.set("vehicleModel", selectedCabModels.join(","));
            } else {
                currentParams.delete("vehicleModel");
            }

            navigate(`/Trip/${vehicleTypes}?${currentParams.toString()}`);

            const fetchUrl = `https://orca-app-iyk4e.ondigitalocean.app/api/v1/search-avaliable-rental-vehicle?${currentParams.toString()}`;

            const response = await fetch(fetchUrl);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            setFilteredResults(data || []);
            dispatch(setVehicles(data));
        } catch (error) {
            console.error("Error fetching filtered data:", error.message);
        }
    };

    const handleCabTypeChange = (e) => {
        const value = e.target.value;
        setSelectedCabTypes((prevSelected) => {
            const updatedSelection = prevSelected.includes(value)
                ? prevSelected.filter((type) => type !== value)
                : [...prevSelected, value];

            fetchFilteredData(updatedSelection, selectedFuelTypes, selectedCabModels);
            return updatedSelection;
        });
    };

    const handleFuelTypeChange = (e) => {
        const value = e.target.value;
        setSelectedFuelTypes((prevSelected) => {
            const updatedSelection = prevSelected.includes(value)
                ? prevSelected.filter((type) => type !== value)
                : [...prevSelected, value];

            fetchFilteredData(selectedCabTypes, updatedSelection, selectedCabModels);
            return updatedSelection;
        });
    };

    const handleCabModelChange = (e) => {
        const value = e.target.value;
        setSelectedCabModels((prevSelected) => {
            const updatedSelection = prevSelected.includes(value)
                ? prevSelected.filter((model) => model !== value)
                : [...prevSelected, value];

            fetchFilteredData(selectedCabTypes, selectedFuelTypes, updatedSelection);
            return updatedSelection;
        });
    };

    const handleRateTypeChange = (e) => {
        const value = e.target.value;
        setRateType(value);
        fetchFilteredData(selectedCabTypes, selectedFuelTypes, selectedCabModels, value);
    };

    return (
        <div className="p-4 bg-white text-start xsm:h-[100vh] md:h-[730px] overflow-y-scroll custom-scrollbar-hidden">
            <h3 className="text-[1.2rem] font-medium mb-3 text-start text-[#4A4A4A]">Select Filters</h3>
            <div className="mb-4">
                <h4 className="font-semibold text-[1rem] text-[#4A4A4A]">Rate Type</h4>
                {RatePerType.map((item) => (
                    <div key={item.id} className="flex items-center mt-2">
                        <input
                            type="radio" // Change to radio for single select
                            id={`rate-type-${item.id}`}
                            value={item.title}
                            checked={rateType === item.title}
                            onChange={handleRateTypeChange}
                            className="mr-2"
                        />
                        <label htmlFor={`rate-type-${item.id}`} className="text-[0.8rem]">{item.title === "ratePerHours" ? "Rate/24Hrs" : "Rate/Day"}</label>
                    </div>
                ))}
            </div>

            {/* Cab Type Filter */}
            <div className="mb-4">
                <h4 className="font-semibold text-[1rem] text-[#4A4A4A]">Cab Type</h4>
                {loading ? (
                    <p>Loading cab types...</p>
                ) : (
                    cabTypes?.map((type) => (
                        <div key={type._id} className="flex items-center mt-2">
                            <input
                                type="checkbox"
                                id={`cab-type-${type._id}`}
                                value={type.vehicleType}
                                checked={selectedCabTypes.includes(type.vehicleType)}
                                onChange={handleCabTypeChange}
                                className="mr-2"
                            />
                            <label htmlFor={`cab-type-${type._id}`} className="text-[0.8rem]">{type.vehicleType}</label>
                        </div>
                    ))
                )}
            </div>

            {/* Fuel Type Filter */}
            <div className="mb-4">
                <h4 className="font-semibold text-[1rem] text-[#4A4A4A]">Fuel Type</h4>
                {loading ? (
                    <p>Loading fuel types...</p>
                ) : (
                    fuelTypes.map((fuel) => (
                        <div key={fuel._id} className="flex items-center mt-2">
                            <input
                                type="checkbox"
                                id={`fuel-type-${fuel._id}`}
                                value={fuel.fuelType}
                                checked={selectedFuelTypes.includes(fuel.fuelType)}
                                onChange={handleFuelTypeChange}
                                className="mr-2"
                            />
                            <label htmlFor={`fuel-type-${fuel._id}`} className="text-[0.8rem]">{fuel.fuelType}</label>
                        </div>
                    ))
                )}
            </div>

            {/* Cab Model Filter */}
            <div className="mb-4">
                <h4 className="font-semibold text-[1rem] text-[#4A4A4A]">Cab Model</h4>
                {loading ? (
                    <p>Loading cab models...</p>
                ) : (
                    cabModels.map((model) => (
                        <div key={model._id} className="flex items-center mt-2">
                            <input
                                type="checkbox"
                                id={`cab-model-${model._id}`}
                                value={model.vehicleModel}
                                checked={selectedCabModels.includes(model.vehicleModel)}
                                onChange={handleCabModelChange}
                                className="mr-2"
                            />
                            <label htmlFor={`cab-model-${model._id}`} className="text-[0.8rem]">{model.vehicleModel}</label>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default CabFilter;

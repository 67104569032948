import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllOnboardTaxiVehicleList, OnboardNewTaxiVehicle, updateTaxiVehicleStatus } from '../../Services/AddVehicleDetails';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TablePagination, Paper, Typography, Button, Box, IconButton, Switch,
    responsiveFontSizes
} from '@mui/material';
import TaxiVehcileModal from '../Models/VehicleTaxiModal';
import EditIcon from '@mui/icons-material/Edit';
import EditTaxiVehicleModal from '../Models/EditTaxiModal';
import SEOHelmet from '../../Components/common/SeoHelmet';

const OnboardedTaxiVehicleList = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [vehicles, setVehicles] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedTaxi, setSelectedTaxi] = useState(null);
    const navigate = useNavigate();

    const fetchVehicles = async () => {
        try {
            const response = await getAllOnboardTaxiVehicleList();
            setVehicles(response?.data?.data);
        } catch (error) {
            console.error("Error fetching vehicle list:", error);
        }
    };
    useEffect(() => {
        fetchVehicles();
    }, []);

    const handleAddVehicle = async (vehicleData) => {
        try {
            const response = await OnboardNewTaxiVehicle(vehicleData);
            fetchVehicles();
        } catch (error) {
            console.error("Error adding vehicle:", error);
        }
    };

    const handleEditVehicle = (vehicle) => {
        setSelectedTaxi(vehicle);
        setEditModalOpen(true);
    };

    const handleToggleStatus = async (vehicle) => {
        try {
            const updatedStatus = !vehicle.available;
            const response = await updateTaxiVehicleStatus(vehicle._id, updatedStatus);
            if (response.status === 200) {
                fetchVehicles()
            }
        } catch (error) {
            console.error('Error updating vehicle status:', error);
        }
    };
    return (
        <Paper sx={{ width: '100%', maxHeight: "72vh" }}>
             <SEOHelmet title="Onboarded Taxi Vehicle | TSJ Car Rentals "/>

            <Box p={2} sx={{
                textAlign: "start",
                display: "flex",
                justifyContent: "space-between",
                color: "white",
            }}>
                <Typography variant="h5" sx={{ fontWeight: "600", color:"black",
                        float: "left" }}>All Added Taxi Vehicles</Typography>
                <Button
                    sx={{
                        backgroundColor: "primary.main",
                        color: "white",
                        "&:hover": {
                            backgroundColor: "primary.dark",
                        },
                    }}
                    onClick={() => setModalOpen(true)}
                >
                    Add New Taxi Vehicle
                </Button>
            </Box>
            <TableContainer component={Paper} sx={{
                // height: '400px',
                overflowY: 'scroll', 
            }}>
                <Table stickyHeader aria-label="vehicle table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Vehicle Type</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Model</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Fuel Type</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Transmission</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Rate/12Hrs (₹)</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Seats</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Contact</TableCell>
                            <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vehicles?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((vehicle) => (
                            <TableRow key={vehicle._id} hover>
                                <TableCell sx={{ color: "gray" }}>{vehicle.vehicleType}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{vehicle.vehicleModel}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{vehicle.fuelType}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{vehicle.transmission}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{`₹${vehicle.ratePer12Hrs}`}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{vehicle.seats}</TableCell>
                                <TableCell sx={{ color: "gray" }}>{vehicle.contactNumber}</TableCell>
                                <TableCell>
                                    <IconButton
                                        onClick={() => handleEditVehicle(vehicle)}
                                        color="primary"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <Switch
                                        checked={vehicle.available}
                                        onChange={() => handleToggleStatus(vehicle)}
                                        color="primary"
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={vehicles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => setRowsPerPage(parseInt(e.target.value, 10))}
            />
            <TaxiVehcileModal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                onSubmit={handleAddVehicle}
                refreshData={fetchVehicles}
            />
            <EditTaxiVehicleModal
                open={editModalOpen}
                onClose={() => setEditModalOpen(false)}
                selectedTaxi={selectedTaxi}
                onSubmit={handleAddVehicle}
                fetchVehicles={fetchVehicles}
            />
        </Paper>
    );
};

export default OnboardedTaxiVehicleList;

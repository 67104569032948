import React from "react";
import Navbar from "../Components/common/Navbar";
import Footer from "../Components/common/Footer";
import { CiMail } from "react-icons/ci";

import SEOHelmet from "../Components/common/SeoHelmet";



const TermConditions = () => {


    const policies = [
        {

            description:
                "The customer shall pay the car rental fare as per the applicable rates and payment schedules mentioned on the website of The Standard journey at the time of booking.The customer shall also pay parking charges, toll-charges, service tax, state tax and any other fee or levy presently payable or imposed here in after under applicable law/s for availing the car rental services"

            , subdesc: 'All the bookings which are for duration of less than 24 hours will be considered as Hourly bookings and the bookings which are for duration of 24 hours or more will be considered as Daily bookings'

            , subPolices: [
                {
                    subtitle: " For Car Rentals:",

                    points: ["If booking is cancelled within 24 hours of pickup time: 50 % of Total Booking Amount (whichever is greater) will be deducted and 3 % processing fee will be charged on rest of the refund amount."
                        , ".If booking is cancelled at least 24 hours prior to pick up time, no rental will be deducted, 50% + 3% processing fee will be charged on the refund amount."
                        , ".If booking is cancelled at or after pick up time, it’ll be treated as no-show and no refund will be provided."

                    ]
                },

                {
                    subtitle: " For Hourly Rentals:",

                    points: ["No refund will be provided in case of hourly rental booking"

                    ]
                }
                , {
                    subtitle: "The Customer agrees and acknowledges that the use of the services offered by TSJ is at the sole risk of the Customer.TSJ disclaims all representations and warranties of any kind, whether express or implied as to condition, suitability, quality, merchantability and fitness of the services offered by TSJ.The liability of TSJ is excluded to the fullest extent permitted by law.",
                    points: [".the service shall meet Customer requirements",
                        "the service shall be uninterrupted, timely, secure, or error-free."
                    ]
                }]

        },
        {
            describe: ["TSJ shall not be responsible or liable for any loss or damage, howsoever caused or suffered by the Customer arising out of the use of car rental service offered by TSJ or due to the failure of TSJ to provide services to the Customer for any reason whatsoever including but not limited to the Customer's non-compliance with the services' recorded voice instructions, malfunction, partial or total failure of any network terminal, data processing system, computer tele-transmission or telecommunications system or other circumstances whether or not beyond the control of TSJ or any person or any organization involved in the above mentioned systems.",
                "Without prejudice to the above, TSJ shall not be liable for any direct or indirect loss or damage which may be suffered by the Customer as a result of any failure on the part of TSJ to provide a taxi to the Customer within any stipulated time even if TSJ has agreed to provide the taxi, or even if the Customer has apprised TSJ of the possibility of any such loss or damage which would result if the taxi is not provided at all or is not provided within the stipulated time to the Custome",
                , "Upon the demand of TSJ, the Customer shall indemnify TSJ from / and against/ and in respect of any/ or all liabilities, losses, charges and expenses (including legal fees and costs on a full indemnity basis) claims, demands, actions and proceedings which TSJ may incur or sustain directly or indirectly from / or by any reason of/ or in relation to the use or purposed use of the Services provided by TSJ.",
                , "TSJ is hereby authorized to use the location based information provided by any of the telecommunication companies regarding the use of mobile phone by the Customer for making a taxi booking.However, the location based information will be used only to facilitate and improve the probability of locating a taxi for the Customer."
                , "TSJ shall be entitled to disclose the particulars of the Customer/s in possession of TSJ to all companies controlled by TSJ or any of its authorized chauffeurs or any government body as required by the law/ or by directive/ or request from any government body or to any third party deemed fit and proper by TSJ, in its absolute discretion."

                , "TSJ shall be entitled to terminate any booking at any time without giving any reason or prio"
                , "TSJ encourages all its customers to take full responsibility of their luggage/belongings.In case of any loss, from the taxi during the journey, TSJ shall endeavour to locate the lost luggage/belongings on a best-effort basis but TSJ shall not be held responsible for such loss or damage.",
                "If the Customer leaves any luggage/belongings/goods in the taxi or has any complaint in respect of the services or the use of the taxi, the Customer shall make a complaint in writing to TSJ within 24 hours from such usage.",
                "In case of any dispute arising between the Customer and TSJ, the dispute shall be referred to an Arbitrator, appointed by TSJ in its sole discretion.The Arbitrator so appointed by TSJ  shall act in conformity with the Arbitration and Conciliation Act, 1996. The award so passed by the Arbitrator shall be binding upon the Customer as well as TSJ.The courts of Delhi, India shall have the sole and exclusive jurisdiction in respect of any matters which may be instituted before any court of law, arising from the use of the services offered by TSJ.",
                , "TSJ shall be entitled to add, vary or amend any or all of these terms and conditions at any time at its sole discretion and the Customer shall be bound by such addition, variation or amendment incorporated/made in these terms and conditions with effect from the date set forth by TSJ.The terms and conditions shall be available on TSJ 's website namely www.thestandardjourney.com."
                , "TSJ shall be entitled to record all the calls made to the call centre/s of TSJ for quality and training purposes.",
                "Vehicles registered with TSJ may be continuously tracked by TSJ using GPS for security reasons or for reasons deemed fit and proper by TSJ.",
                "By logging on the Account Access service on TSJ's websites, the User/customer hereby authorizes TSJ and its agents to access third party sites, including that of Banks and other payment gateways, designated by them or on their behalf for retrieving requested information. While registering, the User will choose a password and the user is responsible for maintaining the confidentiality of the password and the account. The User is fully responsible for all the activities that may take place whilst using their password or account. It is the duty of the User to notify TSJ immediately in writing of any unauthorized use of their password or account or any other breach of security. TSJ will not be liable for any loss that may be incurred by the User as a result of unauthorized use of customer's password or account, either with or without his/her knowledge.The User shall not use anyone else's password at any time.",
                , "The User shall request TSJ for any refund/s against the unutilized or 'no show' cab booking for any reasons within 24 hours from the hour of departure.Any applicable refunds would accordingly be processed as per the defined policies of TSJ.No refund would be payable for any requests made after the expiry of 24 hours as mentioned above and all unclaimed amounts for such unutilized or 'no show' booking shall be deemed to have been forfeited",
                , "Insurance cover, if any provided as a part of the service/ product by TSJ shall be based on the terms and conditions of the insuring company.The User shall contact the insurance company directly for any claim/s or dispute/s which may arise out of such insurance cover.TSJ shall not provide any express or implied undertakings for acceptance of the claims by the insurance company.",
            ]
        }, {
            otherPolicies: [{
                title: "Over speeding",
                line: " Users are allowed to drive the Vehicle up to a maximum speed of 120 km/hr (74 miles/hr), beyond which the Vehicle will be considered as over speeding.A penalty of Rs.200 will be charged on the first instance, followed by a further penalty of Rs.500 and Rs.1000 for 2nd instance and 3rd instance respectively making it a total of Rs.1,700/- at third instance.After the third instance, Booking will stand cancelled and the Company shall get the right to take back the Vehicle and terminate this Agreement.W.E.F 12th May 2017: a.A penalty of Rs.750 shall be charged on the first instance.b.An additional penalty of Rs.1,000 shall be charged on the second instance of speed violation.The total penalty for 2 instances shall be Rs 1,750.c.An additional penalty of Rs.1,500 shall be charged on the third instance of speed violation.The total penalty for 3 instances shall be Rs 3,250.The fourth instance of speed violation shall be treated as violation of terms and conditions of the agreement and the rental agreement shall be treated as void.Any usage of the vehicle in such a case shall be treated as unauthorized usage and TSJ reserves the right to recover the vehicle."
            },
            {
                title: "Traffic violation",
                line: "Customers are liable to pay for any traffic violation tickets received during their journey.TSJ can charge traffic violation from the customer’s credit/debit card on actuals if the violations tickets are sent directly to the company by the traffic department.",
            }, {
                title: "Car spare part changed:",
                line: "Customers should not change or remove any car spare parts.In case of emergency, the customer should inform TSJ and act as per advice.Customers will be charged a penalty of Rs.5000 over and above the cost of spare part."
            }, {
                title: "Tyre misuse",
                line: "In case of any tyre damages resulting from driving in bad terrain and continued driving in case of tyre puncture, customers will be charged for the cost of tyre on actuals."
            }, {
                title: "Running vehicle in damaged conditions",
                line: "Customers are not advised to drive the car if it gets damaged in an accident.Customers are advised to inform TSJ immediately.In such case, customers will be charged for the cost of spare parts on actuals."
            }, {
                title: "Unauthorized activity in the car:",
                line: " Customers are not allowed to carry arms, ammunitions and banned drugs.In addition, use of car for commercial activity such as product sell and promotion, and carry goods is strictly prohibited.In such cases, customers will be charged a penalty of Rs.5000 apart from Govt actions."
            }, {
                title: "External branding: ",
                line: "Any form of external branding on the car is prohibited.Customers are not allowed to paste or paint any external brand promotion on the car.Customers will have to pay a penalty of Rs.5000."
            }, {
                title: "Tampering with devices",
                line: "Customers are not allowed to tamper with the odometer, GPS device and in-car technology devices.Customers will have to pay a penalty of Rs.1000 and over in addition to the actual cost of the device."
            }, {
                title: "Deliberately driving the car in water",
                line: "Customers will be charged for the actual cost of repair and spare parts."
            }, {
                title: "Producing fake and tampered personal documents",
                line: "Customers will be charged a penalty of Rs.1000 if found guilty."
            }, {
                title: "Diversion",
                line: " Customers are not allowed to drive the car into unauthorized or government banned areas.Customers are advised to inform TSJ in case they change the course of their trip.All TSJ cars are geo-fenced and customers have to pay a penalty Rs.10,000 if the car trespasses into banned areas, naxal hit areas, international border of republic of India and extreme end of ladakh."
            }

            ]
        }

    ];


    return (
        <div className="text-center ">
            <SEOHelmet title="Term & Conditions  | TSJ Car Rentals"/>

 
            <Navbar />
            <section className="my-10 w-[80vw] mx-auto">
                <h1 className="text-3xl font-bold mb-6 uppercase underline underline-offset-2 text-start">Rental Car Policies</h1>
                {policies.map((policy, index) => (
                    <div key={index} className="mb-6 text-start">
                        {policy.description && (
                            <p className="text-gray-700 mb-4">{policy.description}</p>
                        )}
                        {policy.subdesc && (
                            <p className="text-gray-600 mb-4">{policy.subdesc}</p>
                        )}
                        {policy.subPolices &&
                            policy.subPolices.map((subPolicy, subIndex) => (
                                <div key={subIndex} className="mb-4">
                                    <h3 className=" text-lg ">{subPolicy.subtitle}</h3>
                                    <ul
                                        style={{ listStyleType: 'space-counter' }} // Use camelCase for the property name and quotes for the value
                                        className="ml-6 pl-6"
                                    >
                                        {subPolicy.points.map((point, pointIndex) => (
                                            <li key={pointIndex} className="text-gray-700">
                                                {point}
                                            </li>
                                        ))}
                                    </ul>

                                </div>
                            ))}
                            <br/>
                          
                        {policy.describe && (
                            <ul className="list-disc ml-6">
                                {policy.describe.map((desc, descIndex) => (
                                    <li key={descIndex} className="text-gray-700 mb-2">
                                        {desc}
                                    </li>
                                ))}
                            </ul>
                        )}
                  
                        <p>Following conditions will be considered as TSJ car misuse.Customers will be fined if found involved in any of the following:</p>
                        {policies[2].otherPolicies.map((policy, index) => (
                            <p key={index} className=" my-3">
                                <span className="font-bold">{policy.title}{":"}</span> {policy.line}
                            </p>
                        ))}
                    </div>
                ))}
            </section>
            <section className="bg-blue-800 w-full h-[10rem] grid place-content-center">
                <div className="w-[35rem]">
                    <h2 className="text-white text-[2.2rem]">Contact Us</h2>
                    <p className="text-white text-[15px]">
                        We can help you provide the latest news whenever and wherever you are via email
                    </p>

                    <div className="flex mt-4 justify-between">
                        {/* Input Section */}
                        <div className="bg-white w-[77%] h-[3rem] flex items-center rounded-md overflow-hidden">
                            <div className="w-[10%] flex items-center justify-center">
                                {/* Increased the size of the email icon */}
                                <CiMail className="text-[#D9D9D9] w-8 h-8" />
                            </div>
                            <input
                                type="text"
                                placeholder="example@gmail.com"
                                className="w-[90%] h-full px-4 focus:outline-none text-gray-700"
                            />
                        </div>

                        {/* Subscribe Button */}
                        <button
                            style={{
                                boxShadow: "0px 0px 25px 0px #B40D0D1A",
                            }}
                            className=" text-white px-6 py-2 rounded-md hover:bg-red-600 transition duration-300"
                        >
                            Subscribe
                        </button>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default TermConditions
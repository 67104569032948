import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Signup from "../Assests/Auth/Signup.png";
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios'; // Import axios for API calls
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import the CSS for the phone input
import { BASE_API } from "../Services/apis";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setSignupData } from '../slices/authSlices';
import logo from "../Assests/Home/image 14.png"
import { Helmet } from 'react-helmet';
import SEOHelmet from '../Components/common/SeoHelmet';

const UserSignup = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signupData = useSelector((state) => state?.signup?.signupData);
    useEffect(() => {
    }, [signupData]); // Depend on signupData to log it whenever it changes

    const [loading, setLoading] = React.useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            mobile: '',
            dob: '',
            email: '',
            nationality: 'INDIAN', // Default nationality
            address: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            mobile: Yup.string().required('Mobile number is required'),
            dob: Yup.date().required('Date of birth is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            address: Yup.string().required('Address is required'),
        }),
        onSubmit: async (values) => {

            setLoading(true);
            dispatch(setSignupData(values));
            let deviceToken = 'someDeviceToken'
            try {
                // Send form data to the API
                localStorage.setItem("mobile", JSON.stringify(values.mobile))
                localStorage.setItem("deviceToken", JSON.stringify(deviceToken))
                const response = await axios.post(`${BASE_API}/user/signup`, {
                    ...values,
                    deviceToken: deviceToken

                });

                if (response.status === 201 || 200) {
                    const { token, data } = response.data; // Extract token and data
                    const { orderId } = data;
                    localStorage.setItem('orderId', JSON.stringify(response.data.data.orderId));
                    dispatch(setSignupData({ ...values, orderId })); // Dispatching with all values 
                    toast.success('Otp sent successful!');
                    navigate('/verify-signup-otp');
                }
            } catch (error) {
                console.error('Error during sign up:', error.response?.data || error.message);
                toast.error(error.response?.data?.message || 'Error signing up');
            } finally {
                setLoading(false);
            }
        },
    });

    return (
        <>
        <SEOHelmet title="Sign Up User | TSJ Car Rentals"/>
        
        <div className="flex justify-center items-center h-screen">
           
            <Toaster />
            <div className="bg-white shadow-lg hidden md:grid grid-cols-12 gap-4 rounded-lg p-4 w-[100vw] h-[100%] text-[0.9rem] justify-evenly">
                <div className="px-4 col-span-5 text-start h-[100%] w-[85%] mx-auto p-4 font-sf-pro-rounded flex flex-col space-y-5">
                    {/* Logo */}
                    <div className="flex justify-center mb-6">
                        <Link to="/">
                            <img src={logo} alt="Logo" className="h-[50px] w-[50px]" /> {/* Replace 'path_to_logo' with the actual logo path */}
                        </Link>
                    </div>

                    <h2 className="text-[36px] text-[#0C1421] font-bold leading-[36px] tracking-[0.01em] text-start font-sf-pro-rounded">
                        Welcome Back 👋
                    </h2>
                    <p className="text-[1rem] font-normal leading-[25px] tracking-[0.01em] text-left font-sf-pro-display text-[#313957] mb-6">
                        Today is a new day. It’s your day. You shape it. Sign in to start managing your projects.
                    </p>
                    <form className="space-y-4" onSubmit={formik.handleSubmit}>
                        <div className="grid grid-cols-2 gap-4">
                            {/* Name Field */}
                            <div>
                                <div className="flex justify-between">
                                    <label className="block text-[#0C1421] text-[16px] leading-[16px] tracking-[0.01em] font-bold">Name</label>
                                    {formik.touched.name && formik.errors.name && <p className="text-red-500 text-sm">{formik.errors.name}</p>}
                                </div>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Name of the person"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={`w-full px-4 py-2 border border-[#D4D7E3] bg-[#F7FBFF] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-[#88978D] ${formik.touched.name && formik.errors.name ? 'border-red-500' : ''}`}
                                />
                            </div>

                            {/* Mobile Field */}
                            <div>
                                <div className="flex justify-between">
                                    <label className="block text-[#0C1421] text-[16px] leading-[16px] tracking-[0.01em] font-bold">Mobile</label>
                                    {formik.touched.mobile && formik.errors.mobile && <p className="text-red-500 text-sm">{formik.errors.mobile}</p>}
                                </div>
                                <PhoneInput
                                    country={'in'}
                                    value={formik.values.mobile}
                                    onChange={(phone) => formik.setFieldValue('mobile', phone)}
                                    inputStyle={{
                                        width: '100%',
                                        border: '1px solid #D4D7E3',
                                        backgroundColor: '#F7FBFF',
                                        borderRadius: '0.375rem',
                                        padding: '0.5rem 1rem',
                                        fontSize: '0.9rem'
                                    }}
                                    buttonStyle={{
                                        border: '1px solid #D4D7E3',
                                        backgroundColor: '#F7FBFF',
                                    }}
                                    dropdownStyle={{
                                        backgroundColor: '#F7FBFF',
                                    }}
                                    containerClass="rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    inputClass="placeholder-[#88978D] placeholder:text-[0.8rem] placeholder:font-roboto placeholder:font-normal placeholder:leading-[16px] placeholder:tracking-[0.01em] placeholder:text-left"
                                />
                            </div>

                            {/* Date of Birth Field */}
                            <div>
                                <div className="flex justify-between">
                                    <label className="block text-[#0C1421] text-[16px] leading-[16px] tracking-[0.01em] font-bold">Date of birth</label>
                                    {formik.touched.dob && formik.errors.dob && <p className="text-red-500 text-sm">{formik.errors.dob}</p>}
                                </div>
                                <input
                                    type="date"
                                    name="dob"
                                    value={formik.values.dob}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={`w-full px-4 py-2 border border-[#D4D7E3] bg-[#F7FBFF] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-[#88978D] ${formik.touched.dob && formik.errors.dob ? 'border-red-500' : ''}`}
                                />
                            </div>

                            {/* Nationality Field */}
                            <div>
                                <div className="flex justify-between">
                                    <label className="block text-[#0C1421] text-[16px] font-bold">Nationality</label>
                                    {formik.touched.nationality && formik.errors.nationality && <p className="text-red-500 text-sm">{formik.errors.nationality}</p>}
                                </div>
                                <select
                                    name="nationality"
                                    value={formik.values.nationality}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={`w-full px-4 py-2 border bg-[#F7FBFF] rounded-md focus:outline-none ${formik.touched.nationality && formik.errors.nationality ? 'border-red-500' : 'border-[#D4D7E3]'}`}
                                >
                                    <option value="INDIAN">INDIAN</option>
                                    <option value="FOREIGNER">FOREIGNER</option>
                                </select>
                            </div>

                            {/* Email Field */}
                            <div className="col-span-2">
                                <div className="flex justify-between">
                                    <label className="block text-[#0C1421] text-[16px] leading-[16px] tracking-[0.01em] font-bold">Email</label>
                                    {formik.touched.email && formik.errors.email && <p className="text-red-500 text-sm">{formik.errors.email}</p>}
                                </div>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Example@email.com"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={`w-full px-4 py-2 border border-[#D4D7E3] bg-[#F7FBFF] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-[#88978D] ${formik.touched.email && formik.errors.email ? 'border-red-500' : ''}`}
                                />
                            </div>

                            {/* Address Field */}
                            <div className="col-span-2">
                                <div className="flex justify-between">
                                    <label className="block text-[#0C1421] text-[16px] leading-[16px] tracking-[0.01em] font-bold">Address</label>
                                    {formik.touched.address && formik.errors.address && <p className="text-red-500 text-sm">{formik.errors.address}</p>}
                                </div>
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="123 Main St, City, Country"
                                    value={formik.values.address}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    className={`w-full px-4 py-2 border border-[#D4D7E3] bg-[#F7FBFF] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-[#88978D] ${formik.touched.address && formik.errors.address ? 'border-red-500' : ''}`}
                                />
                            </div>
                        </div>

                        {/* Sign Up Button */}
                        <button
                            type="submit"
                            className={`w-full h-12 bg-[#162D3A] text-white rounded-md hover:bg-gray-800 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading}
                        >
                            {loading ? 'Otp sending...' : 'Next'}
                        </button>
                    </form>


                    <p className="text-center mt-4 text-[#313957] text-[16px] leading-[16px] tracking-[0.01em]">
                        Already have an account? <a href="/login" className="text-blue-600">Login</a>
                    </p>

                    <p className="text-[#959CB6] text-center my-20 text-[0.8rem]">© 2023 ALL RIGHTS RESERVED BY TSJ</p>
                </div>

                <div className="col-span-7 h-full">
                    <img
                        src={Signup}
                        alt="Signup Illustration"
                        className="w-full h-full object-cover rounded-lg max-h-[97vh]"
                    />
                </div>
            </div>
            <section
                style={{
                    backgroundImage: `url(${Signup})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
                className=" md:hidden  relative flex-col w-full h-full flex items-center justify-center"
            >

                <div className="absolute top-8 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <Link to="/">
                        <img src={logo} alt="Logo" className="w-[50px]" />
                    </Link>
                </div>

                <div
                    className="px-4 col-span-5 text-start w-[90vw] rounded-md p md:w-[60%] mx-auto p-4  flex flex-col space-y-5"
                    style={{ backdropFilter: "blur(10px)" }}
                >
                    <h2 className="text-[28px] text-[#fff] font-bold leading-[36px] tracking-[0.01em] text-start font-sf-pro-rounded">
                        Welcome Back 👋
                    </h2>
                    <p className="text-[1rem] font-normal leading-[25px] tracking-[0.01em] text-left font-sf-pro-display text-[#fff] mb-6">
                        Today is a new day. It’s your day. You shape it. Sign in to start managing your projects.
                    </p>
                    <form className="space-y-4" onSubmit={formik.handleSubmit}>
                        {/* Name Field */}
                        <div>
                            <div className='flex justify-between'>
                                <label className="block text-[#fff] text-[16px] leading-[16px] tracking-[0.01em] font-bold">Name</label>
                                {formik.touched.name && formik.errors.name && <p className="text-red-500 text-sm">{formik.errors.name}</p>}
                            </div>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name of the person"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={`w-full px-4 py-2 border border-[#D4D7E3] bg-[#F7FBFF] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-[#88978D] ${formik.touched.name && formik.errors.name ? 'border-red-500' : ''}`}
                            />
                        </div>

                        {/* Mobile Field */}
                        <div>
                            <div className='flex justify-between'>
                                <label className="block text-[#fff] text-[16px] leading-[16px] tracking-[0.01em] font-bold">Mobile</label>
                                {formik.touched.mobile && formik.errors.mobile && <p className="text-red-500 text-sm">{formik.errors.mobile}</p>}
                            </div>
                            <PhoneInput
                                country={'in'}
                                value={formik.values.mobile}
                                onChange={(phone) => formik.setFieldValue('mobile', phone)}
                                inputStyle={{
                                    width: '100%',
                                    border: '1px solid #D4D7E3',
                                    backgroundColor: '#F7FBFF',
                                    borderRadius: '0.375rem',
                                    padding: '0.5rem 1rem',
                                    fontSize: '0.9rem'
                                }}
                                buttonStyle={{
                                    border: '1px solid #D4D7E3',
                                    backgroundColor: '#F7FBFF',
                                }}
                                dropdownStyle={{
                                    backgroundColor: '#F7FBFF',
                                }}
                                containerClass="rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                inputClass="placeholder-[#88978D] placeholder:text-[0.8rem] placeholder:font-roboto placeholder:font-normal placeholder:leading-[16px] placeholder:tracking-[0.01em] placeholder:text-left"
                            />
                        </div>

                        {/* Date of Birth Field */}
                        <div>
                            <div className='flex justify-between'>
                                <label className="block text-[#fff] text-[16px] leading-[16px] tracking-[0.01em] font-bold">Date of birth</label>
                                {formik.touched.dob && formik.errors.dob && <p className="text-red-500 text-sm">{formik.errors.dob}</p>}
                            </div>
                            <input
                                type="date"
                                name="dob"
                                value={formik.values.dob}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={`w-full px-4 py-2 border border-[#D4D7E3] bg-[#F7FBFF] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-[#88978D] ${formik.touched.dob && formik.errors.dob ? 'border-red-500' : ''}`}
                            />
                        </div>

                        {/* Nationality Field */}
                        <div>
                            <div className='flex'>
                                <label className="block text-[#fff] text-[16px] font-bold">Nationality</label>
                                {formik.touched.nationality && formik.errors.nationality && <p className="text-red-500 text-sm">{formik.errors.nationality}</p>}
                            </div>
                            <select
                                name="nationality"
                                value={formik.values.nationality}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={`w-full px-4 py-2 border bg-[#F7FBFF] rounded-md focus:outline-none ${formik.touched.nationality && formik.errors.nationality ? 'border-red-500' : 'border-[#D4D7E3]'}`}
                            >
                                <option value="INDIAN">INDIAN</option>
                                <option value="FOREIGNER">FOREIGNER</option>
                            </select>
                        </div>

                        {/* Email Field */}
                        <div>
                            <div className='flex justify-between'>
                                <label className="block text-[#fff] text-[16px] leading-[16px] tracking-[0.01em] font-bold">Email</label>
                                {formik.touched.email && formik.errors.email && <p className="text-red-500 text-sm">{formik.errors.email}</p>}
                            </div>
                            <input
                                type="email"
                                name="email"
                                placeholder="Example@email.com"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={`w-full px-4 py-2 border border-[#D4D7E3] bg-[#F7FBFF] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-[#88978D] ${formik.touched.email && formik.errors.email ? 'border-red-500' : ''}`}
                            />
                        </div>

                        {/* Address Field */}
                        <div>
                            <div className='flex justify-between'>
                                <label className="block text-[#fff] text-[16px] leading-[16px] tracking-[0.01em] font-bold">Address</label>
                                {formik.touched.address && formik.errors.address && <p className="text-red-500 text-sm">{formik.errors.address}</p>}
                            </div>
                            <input
                                type="text"
                                name="address"
                                placeholder="123 Main St, City, Country"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className={`w-full px-4 py-2 border border-[#D4D7E3] bg-[#F7FBFF] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder-[#88978D] ${formik.touched.address && formik.errors.address ? 'border-red-500' : ''}`}
                            />
                        </div>

                        {/* Sign Up Button */}
                        <button
                            type="submit"
                            className={`w-full h-12 bg-[#162D3A] text-white rounded-md hover:bg-gray-800 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            disabled={loading}
                        >
                            {loading ? 'Otp sending...' : 'Next'}
                        </button>
                    </form>

                    <p className="text-center mt-4 text-[#fff] text-[16px] leading-[16px] tracking-[0.01em]">
                        Already have an account? <a href="/login" className="text-blue-600 font-bold italic">Login</a>
                    </p>
                </div>

                <p className="absolute bottom-5 left-10 text-white text-center  text-[0.8rem]">© 2023 ALL RIGHTS RESERVED BY TSJ</p>
            </section>
        </div>
        </>
    );
};

export default UserSignup;

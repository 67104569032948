import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import CabList from "../Components/Core/Trip/CabList";
import MainSection from "../Components/Core/Trip/MainSection";
import { useLocation } from "react-router-dom";
import Banner from "../Components/common/banner";
import Navbar from "../Components/common/Navbar";
import TourandTravel from "./TourAndTravelPage";
import TaxiPackagesPages from "./TaxiPackagesPage";

import SEOHelmet from "../Components/common/SeoHelmet";
// import { setVehicles, setLoading, setError } from "../slices/VehiclesSlice";
// import { useDispatch } from "react-redux";

const TripPage = () => {
    const location = useLocation();

    const [vehicleTypes, setVehicleTypes] = useState();


    useEffect(() => {
        const pathSegments = location.pathname.split("/");
        setVehicleTypes(pathSegments[2] || "default");
        console.log("Current vehicle type:", pathSegments[2]);

    }, [location]);

    const renderContent = () => {
        switch (vehicleTypes) {
            case "TourandTravel":
                return <TourandTravel />;
            case "TaxiPackages":
                return <TaxiPackagesPages />;
            default:
                return (
                    <motion.div
                        className="w-[99vw]"
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 20 }}
                        transition={{ duration: 0.5 }}
                    >
                        <SEOHelmet title="Rental Packages | TSJ Car Rentals"/>
                   
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            <Navbar />
                        </motion.div>
                        <Banner />
                        <CabList />
                        <MainSection />
                    </motion.div>
                );
        }
    };

    return <>{renderContent()}</>;
};

export default TripPage;

import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardMedia, Typography, Grid, Divider, Chip, Button } from '@mui/material';
import { AccessTime, LocationOn, Person, LocalOffer } from '@mui/icons-material';
import { useParams } from "react-router-dom";
import { taxiBookingDetails, updateStatusTaxiBooking } from '../../../Services/adminAPI'
import toast from 'react-hot-toast';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Helmet } from 'react-helmet';
import SEOHelmet from '../../../Components/common/SeoHelmet';


const TaxiBookingView = () => {
    const { bookingId } = useParams();
    const [bookingDetails, setBookingDetails] = useState(null);
    const [error, setError] = useState(null);

    const fetchBookingDetails = async () => {
        try {
            const response = await taxiBookingDetails(bookingId);
            if (response.status === 200) {
                setBookingDetails(response.data.bookings);
            } else {
                setError("Failed to fetch booking details.");
            }
        } catch (err) {
            setError("An error occurred while fetching booking details.");
        }
    };

    useEffect(() => {
        if (bookingId) {
            fetchBookingDetails();
        }
    }, [bookingId]);

    const handleApproveReject = async (bookingId, status) => {
        try {
            const response = await updateStatusTaxiBooking(bookingId, status);
            if (response.status === 200) {
                toast.success(response.data.message || "Approved sucessfully");
                fetchBookingDetails();
            }
        } catch (error) {
            toast.error(error.response.data.message || "An error occurred. Please try again.");

        }
    };


    if (error) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                }}
            >
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    if (!bookingDetails) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                }}
            >
                <Typography variant="h6">Loading booking details...</Typography>
            </Box>
        );
    }

    const {
        userId,
        vehicle,
        pickupAddress,
        dropAddress,
        pickupDate,
        pickupTime,
        dropDate,
        dropTime,
        inBetweenCities,
        fare,
        status,
        paymentStatus,
    } = bookingDetails;

    return (
        <Box sx={{ p: 2 }}>
            <SEOHelmet title="Taxi vehicle booked Details | TSJ Car Rentals"/>
            <Card sx={{ maxWidth: 800, margin: 'auto', boxShadow: 3 }}>
                <CardMedia
                    component="img"
                    height="200"
                    image={vehicle.img}
                    alt={vehicle.vehicleModel}
                />
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        {vehicle.vehicleModel} ({vehicle.vehicleType})
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {vehicle.description}
                    </Typography>
                    <Divider sx={{ my: 2 }} />

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <Person fontSize="small" sx={{ mr: 1 }} />
                                {userId.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {userId.email}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {userId.mobile}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <AccessTime fontSize="small" sx={{ mr: 1 }} />
                                Booking Details
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Pickup: {pickupAddress} ({pickupDate} at {pickupTime})
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Drop: {dropAddress} ({dropDate} at {dropTime})
                            </Typography>
                            {inBetweenCities.length > 0 && (
                                <Typography variant="body2" color="text.secondary">
                                    Stops: {inBetweenCities.map((city) => city.address).join(', ')}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>

                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <LocalOffer fontSize="small" sx={{ mr: 1 }} />
                                Fare
                            </Typography>
                            <Typography variant="h6" color="primary">
                                ₹{fare}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                Status
                            </Typography>
                            <Chip
                                label={status.toUpperCase()}
                                color={status === 'pending' ? 'warning' : 'success'}
                                sx={{ fontWeight: 'bold' }}
                            />
                            <Typography variant="body2" color="text.secondary">
                                Payment: {paymentStatus}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: 2,
                            mt: 3,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<CheckCircleIcon />}
                            onClick={() => handleApproveReject(bookingId, "accepted")}
                        >
                            Accept Booking
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default TaxiBookingView;

import React from "react";
import { CssBaseline } from '@mui/material';
import Sidebar from '../Components/Core/Dashboard/Sidebar/Sidebar';
import { menuItems } from '../../../client/src/data/AdminDashnoard';

import Header from "../Components/Core/Dashboard/Headers/Headers";
import Dashboard from "../Components/Core/Dashboard/DashbaordLayout/Dashbaord";
import SEOHelmet from "../Components/common/SeoHelmet";

const AdminDashboardPage = () => {
    return (
        <>
            <SEOHelmet title="Admin Profile | TSJ Car Rentals" />
            <div style={{ display: 'flex', maxWidth: "100vw" }} className="font-lato">
                <CssBaseline />
                <Sidebar menuItems={menuItems} position="relative" sx={{ maxWidth: "10vw" }} />
                <div style={{ flexGrow: 1, maxWidth: "90vw" }}>
                    <Header />
                    <Dashboard />
                </div>
            </div>
        </>
    )
}
export default AdminDashboardPage;
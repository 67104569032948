import React from "react";
import { Card, Grid, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination } from "@mui/material";
import {EmojiEvents, Paid, Public, ShoppingCart } from "@mui/icons-material";

const Dashboard = ({ data }) => {
  return (
    <Box p={3}>
      <Grid container spacing={2}>
        {data.stats.map((stat, index) => (
          <Grid item xs={12} sm={6} md={2.4} key={index}>
            <Card sx={{ p: 2, textAlign: 'center', backgroundColor: '#f3f4f6' }}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="p" sx={{ text: "16px" }}>{stat.title}</Typography>
              </Box>
              <Typography variant="h4">{stat.count}</Typography>
              <Typography variant="subtitle2" color={stat.changeColor}>{stat.percentage}</Typography>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Bookings Table */}
      <Box mt={3}>
        <Card>
          <Box p={2} sx={{ text: "start" }}>
            <Typography variant="h5" sx={{ fontWeight: "600", text: "start", float: "left", weight: "fit" }}>Bookings</Typography>
          </Box>
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: '#f3f4f6' }}>
                <TableRow>
                  <TableCell>User ID</TableCell>
                  <TableCell>Vehicle Type</TableCell>
                  <TableCell>Goods Type</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.bookings.map((booking, index) => (
                  <TableRow key={index}>
                    <TableCell>{booking.userId}</TableCell>
                    <TableCell>{booking.vehicleType}</TableCell>
                    <TableCell>{booking.goodsType}</TableCell>
                    <TableCell>{booking.date}</TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          color: "white",
                          backgroundColor: booking.status === "PENDING" ? "orange" : "green",
                          borderRadius: "5px",
                          padding: "5px 10px",
                          textAlign: "center",
                          width: "fit-content",
                        }}
                      >
                        {booking.status}
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display="flex" justifyContent="center" p={2}>
            <Pagination count={data.totalPages} page={data.page} onChange={data.handleChangePage} />
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

// Example Data Usage
const data = {
  stats: [
    { title: 'Total Bookings', count: 414, percentage: '+55%', changeColor: 'green', icon: <Paid />, iconBgColor: 'blue' },
    { title: "Today's Bookings", count: 4, percentage: '+3%', changeColor: 'green', icon: <Public />, iconBgColor: 'blue' },
    { title: 'Total Users', count: 26, percentage: '-2%', changeColor: 'red', icon: <EmojiEvents />, iconBgColor: 'blue' },
    { title: 'Total Rental', count: 6, percentage: '+5%', changeColor: 'green', icon: <ShoppingCart />, iconBgColor: 'blue' },
    { title: 'Total Vechile', count: 15, percentage: '+5%', changeColor: 'green', icon: <ShoppingCart />, iconBgColor: 'blue' },
  ],
  bookings: [
    { userId: '66fe01cfa8e...', vehicleType: 'Sedan', goodsType: 'None', date: 'October 10, 2024', status: 'PENDING' },
    { userId: '66e44b97415c...', vehicleType: 'SUV', goodsType: 'Furniture', date: 'October 10, 2024', status: 'PENDING' },
  ],
  page: 1,
  totalPages: 5,
  handleChangePage: () => { },
};

export default function AdminDashboardData() {
  return <Dashboard data={data} />;
}



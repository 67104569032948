import React, { useState } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import { Button, Container, Link } from '@mui/material';
import InvoiceForm from './InvoiceFormData';
import {
    Box,
    Grid2,
    Typography, Table, TableBody, TableCell, TableRow
} from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { formatDate } from '../../utils/FormateValue';
import { BASE_API } from '../../Services/apis';
import { Helmet } from 'react-helmet';
import SEOHelmet from './SeoHelmet';

const theme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '5px',
                },
            },
        },
    },
});

const InvoiceGenerator = () => {
    const [isInvoiceCreated, setIsInvoiceCreated] = useState(true)
    const [savedInvoiceData, setSavedInvoiceData] = useState()
    const [invoiceData, setInvoiceData] = useState({
        invoiceNo: '',
        date: '',
        customerId: '',
        terms: 'All terms & conditions',
        billTo: '',
        shipTo: '',
        items: [{ description: '', amount: 0 }],
        amount: '',
        remarks: '',
        discountAmount: null,
        discountPercent: null,
        taxRate: null,
        toll: null,
        otherCharges: null,
        fromDate: '',
        toDate: '',
    });

    const pdfExportComponent = React.useRef(null);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const numericFields = ['amount', 'discountAmount', 'discountPercent', 'taxRate', 'toll', 'otherCharges'];
        setInvoiceData({
            ...invoiceData,
            [name]: numericFields.includes(name) ? parseFloat(value) || null : value,
        });
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = [...invoiceData.items];
        updatedItems[index][field] = field === 'amount' ? parseFloat(value) || null : value;
        setInvoiceData({ ...invoiceData, items: updatedItems });
    };


    const handleDownload = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };

    const addNewItem = () => {
        setInvoiceData({ ...invoiceData, items: [...invoiceData.items, { description: '', amount: 0 }] });
    };

    const saveInvoice = async () => {
        try {
            setIsInvoiceCreated(false)
            const response = await fetch(`${BASE_API}/invoice/generate-invoice`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(invoiceData),
            });

            if (response.ok) {
                const result = await response.json();
                setSavedInvoiceData(result?.invoice)
                setInvoiceData(result?.invoice)
                alert('Invoice saved successfully!');
                setIsInvoiceCreated(false)


            } else {
                const error = await response.json();
                alert(`Failed to save invoice: ${error.message}`);
                setIsInvoiceCreated(true)

            }
        } catch (err) {
            setIsInvoiceCreated(true)
            console.error('Error saving invoice:', err);
            alert('An error occurred while saving the invoice.');
        }
    };
    const refreshPage = () => {
        setIsInvoiceCreated(true)
        setSavedInvoiceData()
        setInvoiceData(
            {
                invoiceNo: '',
                date: '',
                customerId: '',
                terms: 'All terms & conditions',
                billTo: '',
                shipTo: '',
                items: [{ description: '', amount: 0 }],
                amount: '',
                remarks: '',
                discountAmount: null,
                discountPercent: null,
                taxRate: null,
                toll: null,
                otherCharges: null,
                fromDate: '',
                toDate: '',
            }
        )
    }
    return (
        <ThemeProvider theme={theme}>
            <SEOHelmet title="Generate Invoice | TSJ Car Rentals"/>
             
            <Container>
                <InvoiceForm invoiceData={invoiceData} handleInputChange={handleInputChange} addNewItem={addNewItem} handleItemChange={handleItemChange} />
                <Box sx={{ margin: "10px 0px", display: "flex", gap: "20px" }}>
                    <Button color="primary" onClick={handleDownload} variant="contained" disabled={isInvoiceCreated} >Download Invoice</Button>
                    <Button color="secondary" variant="contained" onClick={saveInvoice} disabled={!isInvoiceCreated}>
                        Create Invoice
                    </Button>
                    {!isInvoiceCreated &&
                        <Button color="success" variant="contained" onClick={refreshPage}>
                            Create New Invoice
                        </Button>
                    }

                </Box>

                {savedInvoiceData &&
                    <PDFExport ref={pdfExportComponent} paperSize="A4">
                        <div style={{ padding: '20px', fontFamily: 'Arial', fontSize: '14px', color: '#000', border: "1px solid", }}>
                            <Grid2 container>
                                <Grid2 item size={{ xs: 6 }}>
                                    <img src={"/assets/company_logo.jpeg"} alt="Logo" style={{ height: '50px' }} />

                                    <div style={{ marginTop: '20px', textAlign: "left" }}>
                                        <strong>TSJ Cars Rental Services PVT LTD</strong>
                                        <p>16 G Regency Arched Navratan Bag</p>
                                        <p>Beside AICTSL Indore MP (452001)</p>
                                        <p>Email: thestandardjourney@gmail.com</p>
                                        <p>Website: tsj</p>
                                    </div>
                                </Grid2>
                                <Grid2 item size={{ xs: 6 }} align="right">
                                    <div>
                                        <Typography variant="h5" fontWeight="bold" textAlign="center">
                                            INVOICE
                                        </Typography>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell sx={{ color: "#216ba5", fontWeight: "800" }}>INVOICE NO.</TableCell>
                                                    <TableCell sx={{ color: "#216ba5", fontWeight: "800" }}>DATE</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>{savedInvoiceData?.invoiceNo}</TableCell>
                                                    <TableCell>{formatDate(savedInvoiceData?.date)}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell sx={{ color: "#216ba5", fontWeight: "800" }}>CUSTOMER ID</TableCell>
                                                    <TableCell sx={{ color: "#216ba5", fontWeight: "800" }}>Terms</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>{savedInvoiceData?.customerId}</TableCell>
                                                    <TableCell>All Terms & Conditions</TableCell>
                                                </TableRow>
                                                <TableRow sx={{ merginTop: "10px" }}>
                                                    <TableCell collapse={2}>Can check our website:-<Link to="https://www.thestandardjourney.com">www.thestandardjourney.com </Link></TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </Grid2>
                            </Grid2>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px', border: "1px solid", padding: "10px" }}>
                                <div>
                                    <strong>BILL TO</strong>
                                    <p>{savedInvoiceData?.billTo}</p>
                                </div>
                                <div style={{ borderLeft: "1px solid" }}>
                                    <strong>SHIP TO</strong>
                                    <p>{savedInvoiceData?.shipTo}</p>
                                </div>
                            </div>

                            <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
                                <thead>
                                    <tr>
                                        <th style={{ border: '1px solid #000', padding: '5px' }}>DESCRIPTION</th>
                                        <th style={{ border: '1px solid #000', padding: '5px' }}></th>
                                        <th style={{ border: '1px solid #000', padding: '5px' }}>AMOUNT</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {savedInvoiceData?.items.map((item, index) => (
                                        <tr key={index}>
                                            <td style={{ border: '1px solid #000', padding: '5px' }}>{item.description}</td>
                                            <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'right' }}>{item.amount}</td>
                                            <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'right' }}>{item.amount}</td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td style={{ border: '1px solid #000', padding: '5px' }}>FROM {formatDate(savedInvoiceData?.fromDate)} - {formatDate(savedInvoiceData?.toDate)}</td>
                                        <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'right' }}></td>
                                        <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'right' }}>0</td>
                                    </tr>
                                    <tr>
                                        <td style={{ border: '1px solid #000', padding: '5px' }}>TAX AND TOLL</td>
                                        <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'right' }}></td>
                                        <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'right' }}>{savedInvoiceData?.toll}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <Grid2 container sx={{ marginTop: "5px" }}>
                                <Grid2 item size={{ xs: 6 }} sx={{ border: "1px solid" }}>
                                    <div style={{ marginTop: '20px' }}>
                                        <p>TSJ Cars Rental Service PVt LTD</p>
                                        <p>Thank You</p>
                                    </div>
                                </Grid2>

                                <Grid2 item size={{ xs: 6 }} sx={{ border: "1px solid" }}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ color: "#000", fontWeight: "600" }}>SUBTOTAL</TableCell>
                                                <TableCell>{savedInvoiceData?.items.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0)}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ color: "#000", fontWeight: "600" }} > DISCOUNT AMOUNT</TableCell>
                                                <TableCell>{savedInvoiceData?.discountAmount}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ color: "#000", fontWeight: "600" }}>DISCOUNT %</TableCell>
                                                <TableCell>{savedInvoiceData?.discountPercent}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ color: "#000", fontWeight: "600" }}>TAX RATE %</TableCell>
                                                <TableCell>{savedInvoiceData?.taxRate}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ color: "#000", fontWeight: "600" }}>TOLL TAX</TableCell>
                                                <TableCell>{savedInvoiceData?.toll}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ color: "#000", fontWeight: "600" }}>OTHER</TableCell>
                                                <TableCell >{savedInvoiceData?.otherCharges}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ color: "#000", fontWeight: "600" }}>Total</TableCell>
                                                <TableCell >  {(() => {
                                                    const subtotal = savedInvoiceData?.items.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);
                                                    const discount = savedInvoiceData?.discountAmount ? parseFloat(savedInvoiceData?.discountAmount || 0) : (subtotal * parseFloat(savedInvoiceData?.discountPercent || 0)) / 100;
                                                    const tax = ((subtotal - discount) * parseFloat(savedInvoiceData?.taxRate || 0)) / 100;
                                                    return (subtotal - discount + tax + parseFloat(savedInvoiceData?.toll || 0) + parseFloat(savedInvoiceData?.otherCharges || 0)).toFixed(2);
                                                })()}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell sx={{ color: "#000", fontWeight: "600" }}>Remarks/ Instruction</TableCell>
                                                <TableCell >{savedInvoiceData?.remarks}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid2>
                            </Grid2>
                            <Grid2 container>
                                <Grid2 item size={8}>
                                    <footer style={{ marginTop: '20px', textAlign: 'center' }}>
                                        <p>For questions concerning this invoice, please contact:</p>
                                        <p>The Standard Journey, (07311) 4035356, standardjourney@gmail.com</p>
                                        <p>www.thestandardjourney.com</p>
                                    </footer>
                                </Grid2>
                                <Grid2 item size={4}>
                                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                        <img src={"/assets/sign.png"} alt="Signature" style={{ height: '100px', width: "100px" }} />
                                    </Box>
                                </Grid2>
                            </Grid2>
                        </div>
                    </PDFExport>
                }
            </Container>
        </ThemeProvider>
    );
};

export default InvoiceGenerator;

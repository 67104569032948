import axios from "axios";
import { logout } from "../slices/UserSlices";
import { BASE_API } from "./apis";
import store from "../slices/Store"


const api = axios.create({
    baseURL: BASE_API,
});

// Add a request interceptor to include the token in the headers

api.interceptors.request.use(
    (config) => {
        const { token } = store.getState().auth;
        console.log(token);

        if (token) {
            config.headers.Authorization = token;
        }

        return config;
    },
    (error) => {
        throw error;
    }
);

// Add a response interceptor to handle unauthorized requests
api.interceptors.response.use(
    (response) => {
        console.log("request success", response)
        return response;
    },
    (error) => {
        console.log("request error", error)
        if (error.response && error.response.status === 401) {
            // Unauthorized: clear the token and log the user out
            console.log("error")
            store.dispatch(logout());
        }
        throw error;
    }
);

export default api;

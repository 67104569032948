import React, { useState } from 'react';
import { TextField, Button, Grid, Typography, CircularProgress } from '@mui/material';
import { uploadGalleryImages } from '../../../Services/adminAPI'
import toast from 'react-hot-toast';

const GalleryImageUpload = () => {
  const [title, setTitle] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!title || !image) {
      setError('Please provide both title and image.');
      return;
    }

    const formData = new FormData();
    formData.append('title', title);
    formData.append('image', image);

    setLoading(true);
    setError(null);

    try {
      const response = await uploadGalleryImages(formData);
      if (response.status === 201) {
        console.log('Image uploaded successfully:', response.data);
        setTitle('');
        setImage(null);
        toast.success("Image uploaded successfully.");
      }
    } catch (error) {
      setError('Error uploading image, please try again later.');
      toast.error(error?.response?.data?.message || "An error occurred. Please try again.");
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <Typography variant="h5" gutterBottom>
        Upload Gallery Image
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Title"
              variant="outlined"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <input
              type="file"
              onChange={handleImageChange}
              accept="image/*"
              required
            />
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <CircularProgress />
            ) : (
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Upload Image
              </Button>
            )}
          </Grid>
        </Grid>
      </form>

      {error && (
        <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default GalleryImageUpload;

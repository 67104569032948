import React from 'react';
import {
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Grid,
    Chip,
    Divider
  } from "@mui/material";

const PackageViewModal = ({selectedPackage,open,onClose}) => {
    return (
        <div>
            {selectedPackage && (
                <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
                    <DialogTitle sx={{display:"flex",justifyContent:"space-between"}}>{selectedPackage.packageName} <button onClick={onClose}>X</button></DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <img
                                    src={selectedPackage.titleImg}
                                    alt={selectedPackage.packageName}
                                    style={{ width: "100%", borderRadius: 8 }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body1" gutterBottom>
                                    {selectedPackage.description}
                                </Typography>
                            </Grid>

                            <Divider style={{ width: "100%", margin: "16px 0" }} />

                            <Grid item xs={6}>
                                <Typography variant="subtitle2">
                                    Destination: {selectedPackage.destination}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2">
                                    Duration: {selectedPackage.durationInDays} Days / {selectedPackage.durationInNight} Nights
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="subtitle2">
                                    Price: ₹{selectedPackage.packagePrice}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2">
                                    Accommodation: {selectedPackage.accommodationType}
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="subtitle2">
                                    Transportation: {selectedPackage.transportation}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="subtitle2">
                                    Group Size: {selectedPackage.groupSize}
                                </Typography>
                            </Grid>

                            <Divider style={{ width: "100%", margin: "16px 0" }} />

                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Meals Included:</Typography>
                                {selectedPackage.mealsIncluded.map((meal, index) => (
                                    <Chip
                                        key={index}
                                        label={meal}
                                        size="small"
                                        style={{ margin: "2px" }}
                                    />
                                ))}
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="subtitle1">Activities:</Typography>
                                {selectedPackage.activities.map((activity, index) => (
                                    <Chip
                                        key={index}
                                        label={activity}
                                        size="small"
                                        style={{ margin: "2px" }}
                                    />
                                ))}
                            </Grid>

                            <Divider style={{ width: "100%", margin: "16px 0" }} />

                            <Grid item xs={12}>
                                <Typography variant="subtitle2">
                                    Cancellation Policy: {selectedPackage.cancellationPolicy}
                                </Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            )}
        </div>
    )
}

export default PackageViewModal

import React, { useState } from "react";
import { AppBar, Toolbar, IconButton, Drawer, List, ListItem, ListItemText, Typography, Box, Menu, MenuItem, Divider } from "@mui/material";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import { Link, useNavigate} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../slices/UserSlices";
import image from "../../Assests/Home/image 14.png";
import toast from "react-hot-toast";
import girl from "../../Assests/myChoose/girl.png";


const UserProfile = ({ handleProfileClick }) => {
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem('user')) : null
    return (
        <div className="xsm:w-[280px] md:w-[150px] flex h-full items-center" onClick={handleProfileClick}>
            <img src={girl} className="w-[30px] h-[30px]" alt="User" />
            <div className="max-w-[100px] text-start">
                {user && (
                    <p className="w-[110px] text-[13px] font-lato font-semibold">
                        {user.name || 'User Name'} <br />
                        {/* {user.email || 'Email'} */}
                    </p>
                )}
            </div>
        </div>
    )
};

const Navbar = () => {
    const [showServices, setShowServices] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const NavLink = [
        { id: 1, title: "Home", path: "/" },
        {
            id: 2,
            title: "Services",
            path: "/services",
            sublink: [
                { subtitle: "Car Rental", subPath: "/car_rental" },
                { subtitle: "Tour packages for domestic & international", subPath: "/tour_packages" },
                { subtitle: "Taxi Services", subPath: "/taxi_services" }
            ]
        },
        { id: 3, title: "Gallery", path: "/gallery" },
        { id: 5, title: "Contact Us", path: "/contactUs" },
        { id: 6, title: "Become Host", path: "/become-host" },
        {
            id:7,title:"Blogs",path:"/blogs"
        }

    ];

    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        toast.success("Logged Out");
        navigate("/");
    };

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleServicesToggle = () => {
        setShowServices(!showServices);
    };

    const handleProfileClick = () => {
        navigate("/user/profile");
    }
    return (
        <AppBar position="static" color="default" sx={{ fontFamily: "Poppins" }} elevation={1}>
            <Toolbar
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingX: {
                        lg: "10rem",
                        md: "5rem",
                        sm: "4rem",
                        xs: "2rem",
                    },
                }}
            >
                <Link to="/">
                    <img src={image} alt="Logo" style={{ height: 50, marginRight: 10 }} />
                </Link>
                <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex", width: "50%", justifyContent: "end" } }}>
                    {NavLink.map((link) => (
                        <Box key={link.id} sx={{ position: "relative", mr: 2 }}>
                            <Typography
                                component={Link}
                                to={link.path}
                                onClick={link.id === 2 ? handleServicesToggle : undefined}
                                sx={{ color: "text.primary", textDecoration: "none", mr: 1 }}
                            >
                                {link.title}
                            </Typography>
                            {/* Dropdown for sublinks */}
                            {link.sublink && showServices && link.id === 2 && (
                                <Menu
                                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                                    open={showServices}
                                    onClose={() => setShowServices(false)}
                                    sx={{ mt: 1 }}
                                >
                                    {link.sublink.map((sublink) => (
                                        <MenuItem
                                            key={sublink.subtitle}
                                            component={Link}
                                            to={sublink.subPath}
                                            onClick={() => setShowServices(false)}
                                        >
                                            {sublink.subtitle}
                                        </MenuItem>
                                    ))}

                                </Menu>
                            )}
                        </Box>
                    ))}
                   
                    {user ? (
                        < div className="flex gap-2" >
                            <UserProfile handleProfileClick={handleProfileClick} />
                            <Typography onClick={handleLogout} sx={{ cursor: "pointer", color: "text.primary" }}>Logout</Typography>
                        </div>
                    ) : (
                        <Box sx={{ display: "flex", gap: 2 }}>
                            <Link to="/login" style={{ textDecoration: "none" }}><Typography>Login</Typography></Link>
                            {/* <Link to="/signup" style={{ textDecoration: "none" }}><Typography>Signup</Typography></Link> */}
                        </Box>
                    )}
                </Box>

                {/* Mobile Menu Icon */}
                <IconButton
                    color="inherit"
                    edge="end"
                    onClick={handleDrawerToggle}
                    sx={{ display: { xs: "flex", md: "none" } }}
                >
                    <MenuIcon />
                </IconButton>

                {/* Sidebar Drawer */}
                <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                    <Box sx={{ width: 250, p: 2 }}>
                        <IconButton onClick={handleDrawerToggle}>
                            <CloseIcon />
                        </IconButton>
                        <Divider />
                        <List>
                            {NavLink.map((link) => (
                                <Box key={link.id}>
                                    <ListItem button component={Link} to={link.path} onClick={link.sublink ? handleServicesToggle : handleDrawerToggle}>
                                        <ListItemText primary={link.title} />
                                    </ListItem>
                                    {/* Dropdown for sublinks in Drawer */}
                                    {link.sublink && showServices && link.id === 2 && (
                                        <Box sx={{ pl: 4 }}>
                                            {link.sublink.map((sublink) => (
                                                <ListItem
                                                    key={sublink.subtitle}
                                                    button
                                                    component={Link}
                                                    to={sublink.subPath}
                                                    onClick={handleDrawerToggle}
                                                >
                                                    <ListItemText primary={sublink.subtitle} />
                                                </ListItem>
                                            ))}
                                        </Box>
                                    )}
                                </Box>
                            ))}
                         
                            <Divider />
                            {user ? (
                                <>
                                    <ListItem button onClick={() => { handleLogout(); handleDrawerToggle(); }}>
                                        <ListItemText primary="Logout" />
                                    </ListItem>
                                    <ListItem button onClick={() => { handleLogout(); handleDrawerToggle(); }}>
                                        <UserProfile handleProfileClick={handleProfileClick} />
                                    </ListItem>
                                </>
                            ) : (
                                <>
                                    <ListItem button component={Link} to="/login" onClick={handleDrawerToggle}>
                                        <ListItemText primary="Login" />
                                    </ListItem>
                                    {/* <ListItem button component={Link} to="/signup" onClick={handleDrawerToggle}>
                                        <ListItemText primary="Signup" />
                                    </ListItem> */}
                                </>
                            )}
                        </List>
                    </Box>
                </Drawer>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;

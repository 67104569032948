import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_API } from '../../../Services/apis';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import SEOHelmet from '../../../Components/common/SeoHelmet';

const TaxiBookingTable = () => {
    const [bookings, setBookings] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const token = JSON.parse(localStorage.getItem('token'));
                const response = await axios.get(`${BASE_API}/user/my-taxi-bookings`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setBookings(response?.data?.bookings || []);
            } catch (error) {
                console.error("Error fetching bookings:", error);
            }
        };

        fetchBookings();
    }, []);

    const handleDetailsClick = (bookingId) => {
        localStorage.setItem("vehicleDetail", "TaxiVehicle")
        navigate(`/user/booking-details/taxi/${bookingId}`);
    };

    return (
        <div className="">
            <SEOHelmet title="User Taxi Booking List | TSJ Car Rentals" />

            <div className="overflow-x-auto">
                <table className="w-full border-collapse bg-white shadow-lg">
                    <thead>
                        <tr className="bg-gradient-to-r rounded-t-lg from-blue-600 to-blue-500 text-white">
                            <th className="px-4 py-2 font-medium text-sm uppercase tracking-wider whitespace-nowrap">Vehicle Type</th>
                            <th className="px-4 py-2 font-medium text-sm uppercase tracking-wider whitespace-nowrap">Vehicle Model</th>
                            <th className="px-4 py-2 font-medium text-sm uppercase tracking-wider whitespace-nowrap">Pickup Date</th>
                            <th className="px-4 py-2 font-medium text-sm uppercase tracking-wider whitespace-nowrap">Drop Date</th>
                            <th className="px-4 py-2 font-medium text-sm uppercase tracking-wider whitespace-nowrap">Pickup Location</th>
                            <th className="px-4 py-2 font-medium text-sm uppercase tracking-wider whitespace-nowrap">Drop Location</th>
                            <th className="px-4 py-2 font-medium text-sm uppercase tracking-wider whitespace-nowrap">Total Amount</th>
                            <th className="px-4 py-2 font-medium text-sm uppercase tracking-wider whitespace-nowrap">Booking Status</th>
                            <th className="px-4 py-2 font-medium text-sm uppercase tracking-wider whitespace-nowrap">Actions</th>

                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map((booking) => (
                            <tr
                                key={booking._id}
                                className="odd:bg-gray-100 even:bg-gray-50 hover:bg-blue-50 transition duration-200"
                            >
                                <td className="border px-4 py-2 text-gray-800">{booking.vehicle.vehicleType}</td>
                                <td className="border px-4 py-2 text-gray-800">{booking.vehicle.vehicleModel}</td>
                                <td className="border px-4 py-2 text-gray-800">
                                    {new Date(booking.pickupDate).toLocaleDateString()}
                                </td>
                                <td className="border px-4 py-2 text-gray-800">
                                    {new Date(booking.dropDate).toLocaleDateString()}
                                </td>
                                <td className="border px-4 py-2 text-gray-800">{booking.pickupAddress}</td>
                                <td className="border px-4 py-2 text-gray-800">{booking.dropAddress}</td>
                                <td className="border px-4 py-2 text-gray-800 font-semibold">
                                    ₹{booking.fare}
                                </td>
                                <td className="border px-4 py-2 text-gray-800">
                                    <span
                                        className={`px-2 py-1 rounded text-sm 
            ${booking.status === 'pending' ? 'bg-yellow-100 text-yellow-700' : ''}
            ${booking.status === 'accepted' ? 'bg-blue-100 text-blue-700' : ''}
            ${booking.status === 'on_trip' ? 'bg-indigo-100 text-indigo-700' : ''}
            ${booking.status === 'completed' ? 'bg-green-100 text-green-700' : ''}
            ${booking.status === 'cancelled' ? 'bg-red-100 text-red-700' : ''}
        `}
                                    >
                                        {booking.status}
                                    </span>
                                </td>
                                <td className="border px-4 py-2">
                                    <button
                                        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 shadow-md whitespace-nowrap"
                                        onClick={() => handleDetailsClick(booking._id)}
                                    >
                                        View Details
                                    </button>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default TaxiBookingTable;

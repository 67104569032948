import React from 'react';
import { motion } from 'framer-motion';
import { useLocation, useNavigate } from 'react-router-dom';
import placeHolderImg from '../../../Assests/newHomeImgs/bhopal.jpg'
const TravelsCard = ({ data }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const vehicleType = location.pathname.split("/")[2];
    const currentParams = new URLSearchParams(location.search);

    const handleNavigateDetail = () => {
        const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem('user')) : null;
        const token = localStorage.getItem("token") ? JSON.parse(localStorage.getItem('token')) : null;
        if (!token && !user) {
            return navigate("/login")
        }
        localStorage.setItem("SelectedTourPackage", JSON.stringify(data));
        const queryParams = currentParams.toString();
        navigate(`/book-details/${vehicleType}/${data?._id}?${queryParams}`);
    };

    return (
        <motion.div
            className="w-full mb-2 md:max-w-sm rounded overflow-hidden"
            style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            transition={{ duration: 0.3 }}
        >
            <img
                className="w-[410px] h-[200px] object-cover rounded-t-md"
                src={data?.titleImg}
                alt="travel destination"
            />
            <div className="shadow bg-[#ffffff] border  min-h-[280px] w-full flex flex-col justify-between rounded-b-md">
                <div className="px-[12px] py-[8px] h-full flex flex-col justify-between">
                    <div className="border-b-[1px] border-gray-500">
                        <div className="w-full font-bold text-[16px] flex items-center justify-between text-start leading-2 text-black truncate whitespace-nowrap overflow-hidden flex-1 font-lato">
                            <span>{data?.packageName || ' '}</span>
                            <span className="border text-[11px] px-[3px] py-[2px] rounded text-[#16477F] border-[#16477F] font-normal">
                                {data?.durationInDays}D/{data?.durationInNight}N
                            </span>
                        </div>
                        <p className="font-lato text-[12px] mb-2 text-start text-[#4a4a4a]">
                            {data?.durationInNight ? `${data?.durationInNight}N ${data?.destination}` : ' '}
                        </p>
                    </div>

                    <div className="border-b-[1px] border-gray-500">
                        <div className="w-full font-bold text-[16px] flex items-center justify-between text-start leading-2 text-black truncate whitespace-nowrap overflow-hidden flex-1 font-lato">
                            <span> Group Size: {data?.groupSize || ' '}</span>
                            <span className="border text-[11px] px-[3px] py-[2px] rounded text-[#16477F] border-[#16477F] font-normal">
                                {data?.maxAdults} Max Adults/{data?.maxChildren} Max Children
                            </span>
                        </div>
                        <p className="font-lato text-[12px] mb-2 text-start text-[#4a4a4a]">
                            Source Location:- {data?.source}
                        </p>
                    </div>
                    <ul className="mb-4 grid grid-cols-2 font-lato text-[12px] text-start">
                        {data?.transportation && (
                            <li className="flex items-center">
                                <span className="inline-block w-1.5 h-1.5 mr-2 text-[#4a4a4a] bg-[#4a4a4a] rounded-full"></span>
                                {data?.transportation}
                            </li>
                        )}
                        {data?.accommodationType && (
                            <li className="flex items-center">
                                <span className="inline-block w-1.5 h-1.5 mr-2 text-[#4a4a4a] bg-[#4a4a4a] rounded-full"></span>
                                {data?.accommodationType}
                            </li>
                        )}
                        {data?.mealsIncluded?.map((meal, index) => (
                            <li key={index} className="flex items-center">
                                <span className="inline-block w-1.5 h-1.5 mr-2 text-[#4a4a4a] bg-[#4a4a4a] rounded-full"></span>
                                {meal}
                            </li>
                        ))}
                    </ul>

                    <ul className="font-lato w-full text-[12px] text-start">
                        {data?.activities?.map((activity, index) => (
                            <li key={index} className="flex items-center w-full text-[#007E7D]">
                                <span className="inline-block w-1.5 h-1.5 mr-2 bg-blue-400 rounded-full"></span>
                                <p>{activity}</p>
                            </li>
                        ))}
                    </ul>

                    <div className="bg-[#f9f9f9] cursor-pointer rounded-md px-[12px] py-[10px] border flex justify-between h-[60px]"
                    >
                        <div className="text-[14px] font-lato text-[#4a4a4a] text-start">
                            <p>Booking this Now by Paying only</p>
                            <p>Total Trip Amount </p>
                        </div>
                        <div className="flex flex-col">
                            {data.packagePrice && <span className="text-sm font-semibold text-blue-500"> ₹{data.packagePrice / 10}</span>}
                            <p className="text-sm font-semibold text-gray-900">₹{data.packagePrice || 'N/A'}</p>
                        </div>
                    </div>
                    <div className='flex justify-stretch w-full md:justify-end py-[10px]'>
                        <button
                            onClick={handleNavigateDetail}
                            className='bg-[#1976d2] text-white cursor-pointer rounded-md   px-[12px] py-[10px] w-full border'>Book Now</button>
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

const TravelCardList = ({ packages }) => {
    return (
        <motion.div
            className="w-[100%] float-right h-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="xsm:w-[100%] sm:w-[97%] md:w-[97%] float-right grid xsm:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-5 place-content-end">
                {packages && packages.length > 0 ? (
                    packages.map((data) => (
                        <TravelsCard key={data._id} data={data} />
                    ))
                ) : (
                    <motion.div
                        className="flex flex-col items-center justify-center h-full w-full text-center bg-gray-100 rounded-lg sm:p-4 md:p-6"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <img
                            src={placeHolderImg}
                            alt="No results"
                            className="w-64 h-64 object-cover mb-4"
                        />
                        <h2 className="text-lg font-bold text-gray-700 mb-2">
                            No results found
                        </h2>
                        <p className="text-gray-500 mb-4">
                            Try changing your filter criteria to explore more options.
                        </p>
                    </motion.div>
                )}
            </div>
        </motion.div>
    );
};

export default TravelCardList;

import React from 'react';
import { FaFacebookF, FaInstagram, FaPinterestP, FaLinkedinIn, FaYoutube, FaTwitter, FaPhoneAlt } from 'react-icons/fa';
import foo from "../../Assests/newHomeImgs/Footerimg.jpeg";
import imgLogo from "../../Assests/Home/image 14.png"

const Footer = () => {
  const officeDetails = [
    {
      location: 'Indore Office',
      address: 'Regency Arcade, 16 G, beside Chartered Bus, Indore, Madhya Pradesh 452001',
      phone: '+91 9109123404'
    },
    {
      location: 'Bhopal Office',
      address: 'Plot No 195, MP Nagar Zone 2, Sargam Bus Stop, near Veer Street, Bhopal, Madhya Pradesh 462001',
      phone: '+91 9109123406'
    },
    {
      location: 'Udaipur Office',
      address: 'E 55 Subcity Center, Near Income Tax Office, Udaipur, Rajasthan 313001',
      phone: '+91 9109123405'
    }
  ];

  ;

  return (
    <footer
      className="bg-cover bg-center text-white py-8"
      style={{ backgroundImage: `url(${foo})` }}
    >
      <section className='grid grid-cols-1 md:grid-cols-4 w-[90%] mx-auto gap-8 text-start'>
        {/* Office Addresses with Map Function */}
        {officeDetails.map((office, index) => (
          <div key={index}>
            <p className="font-bold mb-2">{office.location}:</p>
            <p>{office.address}</p>
            <p className="mt-2 flex items-center">
              <FaPhoneAlt className="mr-2" />
              <a href={`tel:${office.phone}`} className="hover:underline">{office.phone}</a>
            </p>
          </div>
        ))}

        {/* Contact Numbers and Social Icons */}
        <div className="text-center">
          <div className="flex flex-col items-center">
            <img src={imgLogo} className='w-[50px]' />
          </div>

          {/* Social Icons */}
          <div className="flex justify-center space-x-4 my-4 text-2xl">
            <FaFacebookF />
            <FaInstagram />

            <FaLinkedinIn />
            <FaTwitter />
          </div>

          {/* Footer Links */}
          <div className="flex justify-center space-x-4 text-sm mt-4">

            <a href="/Term-Conditions" className="hover:underline">Terms & Conditions</a>
            <a href="/privacy" className="hover:underline">Privacy Policy</a>
          </div>
        </div>
      </section>

      {/* Copyright Section */}
      <div className="text-center mt-8">
        <p>© {new Date().getFullYear()} Developed by OTUSONE LLP. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

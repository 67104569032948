import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../Components/common/Navbar'
import LatestBlog from '../Components/Core/Home/Blog'
import Footer from '../Components/common/Footer'
import SEOHelmet from '../Components/common/SeoHelmet'
const LatestBlogPage = () => {
    return (
        <div>
            <SEOHelmet title="Latest Blog | TSJ Car Rentals" />

            <Navbar />

            <LatestBlog />
            <Footer />

        </div>
    )
}

export default LatestBlogPage

import React, { useEffect, useState } from "react";
import axios from "axios";
import Navbar from "../../common/Navbar";
import Banner from "../../common/banner";
import IndianForm from "../../common/IndianForm";
import ForeignForm from "../../common/ForigenForm"
import { useSelector } from "react-redux";

import SEOHelmet from "../../common/SeoHelmet";
const BookingTripDetails = () => {
    const [selected, setSelected] = useState('Indian');
    const [userData, setUserData] = useState('');


    const { user, token } = useSelector((state) => state.auth)
    useEffect(() => {
        setUserData(user)
    }, [])


    return (<div>
            <SEOHelmet title="User Personal Detail form | TSJ Car Rentals"/>

       
        <Navbar />
        <Banner />



        <div class="w-[65vw] mx-auto flex flex-col my-10  gap-4  items-center justify-center">

            <div className="flex mb-8 w-48 gap-5 justify-center items-center ">
                {/* Indian Button */}
                {user?.nationality === "INDIAN" ? (
                    <button
                        onClick={() => setSelected('Indian')}
                        className={`font-semibold py-2 w-[100px] rounded-lg bg-blue-500 text-white ${selected === 'Indian'
                            ? 'bg-blue-500 text-white'
                            : 'border border-blue-500 text-blue-500'
                            }`}
                    >
                        Indian
                    </button>
                ) : (<button
                    onClick={() => setSelected('Foreigner')}
                    className={`font-semibold py-2 w-[100px] bg-blue-500 text-white  rounded-lg ${selected === 'Foreigner'
                        ? 'bg-blue-500 text-white'
                        : 'border border-blue-500 text-blue-500'
                        }`}
                >
                    Foreigner
                </button>)

                }

                {/* Foreigner Button */}

            </div>
            {
                user?.nationality === "INDIAN" && <IndianForm user={userData} token={token} />
            }
            {user?.nationality === "FOREIGNER" && <ForeignForm user={userData} token={token} />
            }
        </div>

    </div>)
}

export default BookingTripDetails
import React from "react";
import { motion } from "framer-motion"; // Import motion
import sedan from "../../../Assests/Cars/sedan.png";
import BookingDetails from "./BookingDetails";
import TripDetailsForm from "./TripDetailsForm";
import PaymentPage from "./PaymentPage";
import { useSearchParams, useParams } from "react-router-dom";
const MainSection = ({ selectedVehicle }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { vehicleType } = useParams();
    const rateType = searchParams.get("rateType") || "";

    return (
        <div className="w-full  xsm:mt-3 md:-mt-3">
            <section className="xsm:w-[95vw] sm:w-[90vw] md:w-[65vw] mx-auto grid grid-cols-12 gap-4">
                <motion.div
                    className=" xsm:col-span-12 md:col-span-8"
                    initial={{ opacity: 0, y: 20 }} // Initial state
                    animate={{ opacity: 1, y: 0 }} // Animate to visible and original position
                    transition={{ duration: 0.5 }} // Transition duration
                >
                    <motion.section
                        style={{ boxShadow: "0px 4px 8px 0px #8A6B7614" }}
                        className="grid grid-cols-11  gap-6 w-full font-lato bg-white border-[#BABABA] h-auto border-[1px] rounded-md  p-4"
                        initial={{ opacity: 0 }} 
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        <div className="col-span-11 md:col-span-2">
                            <div className="grid place-content-center rounded">
                                <img src={selectedVehicle?.img || sedan} className=" w-full h-full md:w-full md:h-[60%] rounded" />
                            </div>
                        </div>
                        <div className="col-span-9 flex flex-col justify-between">
                            <div className="flex gap-1 items-center">
                                <p className="font-bold text-[1rem]">{selectedVehicle?.vehicleModel}</p>
                                <span className="text-[#9B9B9B] text-[0.6rem]">or Similar</span>
                                <span
                                    style={{ background: 'linear-gradient(74.29deg, #26BD99 0%, #219393 100%)' }}
                                    className="w-[3rem] h-[1rem] rounded text-[0.8rem]"
                                >
                                    {selectedVehicle?.rating || "4.7"}/5
                                </span>
                            </div>

                            <div className="flex  items-center text-[0.8rem] gap-2">
                                <span className="font-lato  text-left">{selectedVehicle?.vehicleType}</span>
                                <ol className="flex font-lato   text-left gap-2">
                                    <li>AC</li>
                                    <li>{selectedVehicle?.seats}</li>
                                    <li>{selectedVehicle?.kmIncluded}</li>
                                </ol>
                            </div>
                            <ol className="text-[0.8rem] font-lato  flex  gap-2  ">
                                {selectedVehicle?.amenities && selectedVehicle?.amenities.length > 0 ? (
                                    selectedVehicle?.amenities.map((amenity, index) => (
                                        <li key={index}>{amenity}</li>
                                    ))
                                ) : (
                                    <li>No amenities available.</li>
                                )}
                            </ol>
                            <div className="flex flex-col space-y-1 text-start">
                                <p className="font-bold text-[0.9rem]">Spacious Car</p>
                                {
                                    vehicleType === "TaxiPackages" &&
                                    <>
                                        <div className="flex">
                                            <span className="w-[8.2rem] text-[0.8rem] font-bold">Extra Km fare</span>
                                            <p className="text-[0.7rem]"><span>{selectedVehicle?.extraKmRate}/Km</span> after <span>{selectedVehicle?.limitPer24Hrs || selectedVehicle?.limitPer12Hrs} kms</span></p>
                                        </div>
                                        {selectedVehicle?.extraHoursRate &&
                                            <div className="flex">
                                                <span className="w-[8.2rem] text-[0.8rem] font-bold">Extra Hours fare</span>
                                                <p className="text-[0.7rem]"><span>{selectedVehicle?.extraHoursRate}/Hrs</span></p>
                                            </div>
                                        }
                                    </>
                                }

                                {
                                    vehicleType === "Rental" &&
                                    <>
                                        {

                                            rateType === "ratePerDay" ?
                                                <div className="flex">
                                                    <span className="w-[8.2rem] text-[0.8rem] font-bold">Extra Hours fare</span>
                                                    <p className="text-[0.7rem]"><span>{selectedVehicle?.extraHoursRate}/Hrs</span></p>
                                                </div>
                                                :

                                                <div className="flex">
                                                    <span className="w-[8.2rem] text-[0.8rem] font-bold">Extra Km fare</span>
                                                    <p className="text-[0.7rem]"><span>{selectedVehicle?.extraKmRate}/Km</span> after <span>{selectedVehicle?.limitPer24Hrs || selectedVehicle?.limitPer12Hrs} kms</span></p>
                                                </div>
                                        }


                                    </>
                                }


                                <div className="flex">
                                    <span className="w-[8.2rem] text-[0.8rem] font-bold">Fuel Type</span>
                                    <p className="text-[0.7rem]"><span>{selectedVehicle?.fuelType}</span> </p>
                                </div>
                                {selectedVehicle?.driverCharge &&
                                    <div className="flex">
                                        <span className="w-[8.2rem] text-[0.8rem] font-bold">Driver charge</span>
                                        <p className="text-[0.7rem]"><span>₹{selectedVehicle?.driverCharge}/12Hrs</span> </p>
                                    </div>
                                }

                                <div className="flex">
                                    <span className="w-[8.2rem] text-[0.8rem] font-bold">Cancellation</span>
                                    <p className="text-[0.7rem]"><span className="text-green-500 font-semibold">Free</span> till 1 hour of departure</p>
                                </div>
                            </div>
                        </div>
                    </motion.section>

                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                    >
                        <BookingDetails selectedVehicle={selectedVehicle} />
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.6 }}
                    >
                        <TripDetailsForm />
                    </motion.div>
                    <motion.div
                        style={{ boxShadow: "0px 4px 8px 0px #8A6B7614" }}
                        className="max-w-4xl mx-auto p-6 text-[0.8rem] rounded-md shadow-md mt-5 border"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5, delay: 0.8 }}
                    >
                        <h2 className="text-xl font-semibold mb-4 text-start">Read before you book!</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-start">
                            {/* Left column */}
                            <div>
                                <h3 className="font-bold">Cab Category</h3>
                                <p className="text-gray-600">
                                    The booking will be for cab type HATCHBACK and we do not commit on
                                    providing the preferred cab model (Indica, Swift or similar).
                                </p>

                                <h3 className="font-bold mt-4">Hilly Regions</h3>
                                <p className="text-gray-600">AC will be switched off in hilly areas.</p>

                                <h3 className="font-bold mt-4">Luggage Policy</h3>
                                <p className="text-gray-600">
                                    HATCHBACK has space for 1 Luggage Bag. However, depending on the
                                    number of passengers, luggage can be adjusted in the seating area
                                    with driver consent.
                                </p>

                                <h3 className="font-bold mt-4">Driver Details</h3>
                                <p className="text-gray-600">
                                    Driver details will be shared up to 30 mins prior to departure. In case
                                    the driver/cab that reaches you for pickup is different from what we
                                    have communicated, please don’t board the cab and call us for assistance.
                                </p>
                            </div>

                            {/* Right column */}
                            <div>
                                <h3 className="font-bold">Stops</h3>
                                <p className="text-gray-600">
                                    This is a point-to-point booking and only one stop for meals is
                                    included.
                                </p>

                                <h3 className="font-bold mt-4">Delays</h3>
                                <p className="text-gray-600">
                                    Due to traffic or any other unavoidable reason, pickup may be delayed
                                    by 30 mins.
                                </p>

                                <h3 className="font-bold mt-4">Receipts</h3>
                                <p className="text-gray-600">
                                    You need to collect receipts from the driver for any extra charges
                                    paid directly to the driver during the trip. MMT is not liable to
                                    provide invoices for such amounts.
                                </p>

                                <h3 className="font-bold mt-4">Refueling Policy</h3>
                                <p className="text-gray-600">
                                    In case of a CNG refill stop, the average turnaround time will be 30+
                                    mins due to fewer stations and long queues.
                                </p>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
                <motion.div
                    className="col-span-4"
                    initial={{ opacity: 0, y: 20 }} // Initial state for PaymentPage
                    animate={{ opacity: 1, y: 0 }} // Animate to visible
                    transition={{ duration: 0.5, delay: 1 }} // Transition duration for PaymentPage
                >
                    <PaymentPage selectedVehicle={selectedVehicle} />
                </motion.div>
            </section>
        </div>
    );
};

export default MainSection;

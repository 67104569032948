import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [pathname]);

  return (
    <motion.div
      key={pathname}  // Animate based on route changes
      initial={{ opacity: 0 }}  // Start with opacity 0
      animate={{ opacity: 1 }}  // Animate to full opacity
      exit={{ opacity: 0 }}  // Fade out on route exit
      transition={{ duration: 0.5 }}  // Adjust the timing
    >
      {children}
    </motion.div>
  );
};

export default ScrollToTop;

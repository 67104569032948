import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const SearchBar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [fromLocation, setFromLocation] = useState('');
    const [toLocation, setToLocation] = useState('');
    const [selectedDate, setSelectedDate] = useState({
        date: '',
        month: '',
        year: '',
        day: '',
    });
    const [selectedTime, setSelectedTime] = useState({ Time: '' });
    const [adultCount, setAdultCount] = useState(1);
    const [childCount, setChildCount] = useState(0);

    const updateURL = (key, value) => {
        const params = new URLSearchParams(location.search);
        console.log("key", value)
        if (key === "pickupDate") {
            params.set(
                'pickupDate',
                `${value.year}-${value.month}-${value.date}`
            );
        } else if (key === "pickupTime") {
            params.set('pickupTime', value);

        } else {
            params.set(key, value);
        }

        navigate({ search: params.toString() });
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        setFromLocation(params.get('pickupLocation') || '');
        setToLocation(params.get('dropLocation') || '');

        const initialPickupDate = params.get('pickupDate');
        if (initialPickupDate) {
            const dateObj = new Date(initialPickupDate);
            setSelectedDate({
                date: dateObj.getDate(),
                month: dateObj.toLocaleString('default', { month: 'short' }),
                year: dateObj.getFullYear(),
                day: dateObj.toLocaleString('default', { weekday: 'long' }),
            });
        }

        setSelectedTime({ Time: params.get('pickupTime') || '' });
        setAdultCount(Number(params.get('adults')) || 1);
        setChildCount(Number(params.get('children')) || 0);
    }, [location.search]);

    return (
        <div className="bg-[#0A2E45] py-4 flex gap-28 justify-center items-center">
            <div className="grid grid-cols-1 xsm:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 px-5">
                {/* Starting From */}
                <div className="flex flex-col items-start bg-[#24465c] px-2 py-1 rounded h-[2.8rem] w-full">
                    <label className="font-lato text-[10px] font-semibold leading-[12px] text-blue-400 uppercase text-left">
                        Starting From
                    </label>
                    <input
                        type="text"
                        className="bg-transparent border-none outline-none font-lato text-[13px] font-normal leading-[25.2px] text-left text-white"
                        value={fromLocation}
                        onChange={(e) => {
                            setFromLocation(e.target.value);
                            updateURL('pickupLocation', e.target.value);
                        }}
                    />
                </div>

                {/* Going To */}
                <div className="flex flex-col items-start bg-[#24465c] px-2 py-1 rounded h-[2.8rem] w-full">
                    <label className="font-lato text-[10px] font-semibold leading-[12px] text-blue-400 uppercase text-left">
                        Going To
                    </label>
                    <input
                        type="text"
                        className="bg-transparent border-none outline-none font-lato text-[13px] font-normal leading-[25.2px] text-left text-white"
                        value={toLocation}
                        onChange={(e) => {
                            setToLocation(e.target.value);
                            updateURL('dropLocation', e.target.value);
                        }}
                    />
                </div>

                {/* Starting Date */}
                <div className="flex flex-col items-start bg-[#24465c] px-2 py-1 rounded h-[2.8rem] w-full">
                    <label className="font-lato text-[10px] font-semibold leading-[12px] text-blue-400 uppercase text-left">
                        Starting Date
                    </label>
                    <input
                        type="date"
                        className="bg-transparent border-none outline-none font-lato text-[13px] font-normal leading-[25.2px] text-left text-white"
                        onChange={(e) => {
                            const dateObj = new Date(e.target.value);
                            const updatedDate = {
                                date: dateObj.getDate(),
                                month: dateObj.getMonth() + 1,
                                year: dateObj.getFullYear(),
                                day: dateObj.toLocaleString('default', { weekday: 'long' }),
                            };
                            setSelectedDate(updatedDate);
                            updateURL('pickupDate', updatedDate);
                        }}
                    />
                </div>

                {/* Pickup Time */}
                <div className="flex flex-col items-start bg-[#24465c] px-2 py-1 rounded h-[2.8rem] w-full">
                    <label className="font-lato text-[10px] font-semibold leading-[12px] text-blue-400 uppercase text-left">
                        Pickup Time
                    </label>
                    <input
                        type="time"
                        className="bg-transparent border-none outline-none font-lato text-[13px] font-normal leading-[25.2px] text-left text-white"
                        value={selectedTime.Time}
                        onChange={(e) => {
                            setSelectedTime({ Time: e.target.value });
                            updateURL('pickupTime', e.target.value);
                        }}
                    />
                </div>

                {/* Adults */}
                <div className="flex flex-col items-start bg-[#24465c] px-2 py-1 rounded h-[2.8rem] w-full">
                    <label className="font-lato text-[10px] font-semibold leading-[12px] text-blue-400 uppercase text-left">
                        Adults
                    </label>
                    <input
                        type="number"
                        className="bg-transparent border-none outline-none font-lato text-[13px] font-normal leading-[25.2px] text-left text-white"
                        value={adultCount}
                        onChange={(e) => {
                            setAdultCount(Number(e.target.value));
                            updateURL('adults', e.target.value);
                        }}
                    />
                </div>

                
                <div className="flex flex-col items-start bg-[#24465c] px-2 py-1 rounded h-[2.8rem] w-full">
                    <label className="font-lato text-[10px] font-semibold leading-[12px] text-blue-400 uppercase text-left">
                        Children
                    </label>
                    <input
                        type="number"
                        className="bg-transparent border-none outline-none font-lato text-[13px] font-normal leading-[25.2px] text-left text-white"
                        value={childCount}
                        onChange={(e) => {
                            setChildCount(Number(e.target.value));
                            updateURL('children', e.target.value);
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default SearchBar;

import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import Signup from '../Assests/Auth/Signup.png';
import Logo from "../Assests/Home/image 14.png";
import { BASE_API } from '../Services/apis';
import { setSignupData, setToken, setUser } from '../slices/UserSlices';
import { Helmet } from 'react-helmet';
import SEOHelmet from '../Components/common/SeoHelmet';

const LoginOtpForm = ({ loginType, loginData, orderId }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const OTP_LENGTH = 6;
    const inputRefs = useRef([]);
    const [otp, setOtp] = useState(new Array(OTP_LENGTH).fill(''));
    const [error, setError] = useState('');

    const handleResendOtp = async () => {
        try {
            const response = await axios.post(`${BASE_API}/user/resend-otp`, { orderId });
            if (response.status === 200) {
                toast.success('New OTP sent');
            }
        } catch (error) {
            toast.error('Error resending OTP');
        }
    };

    const handleVerifyOtp = async () => {
        const otpValue = otp.join('');
        if (otpValue.length < OTP_LENGTH || !/^\d+$/.test(otpValue)) {
            setError('Please enter a valid OTP');
            return;
        }
        setError('');

        try {
            const response = await axios.post(
                `${BASE_API}${loginType === "mobile" ? '/user/verify-login-otp' : '/user/verify-email-login-otp'}`,
                {
                    [loginType === "mobile" ? "mobile" : "email"]: loginData,
                    orderId,
                    otp: otpValue,
                }
            );

            if (response.status === 201 || response.status === 200) {
                dispatch(setToken(response.data.token));
                dispatch(setUser(response.data.data));
                localStorage.setItem('token', JSON.stringify(response.data.token));
                localStorage.setItem('user', JSON.stringify(response.data.data));
                toast.success('Successfully signed up');
                navigate('/');
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Verification failed');
        }
    };

    const handleInputChange = (e, index) => {
        const { value } = e.target;
        if (/^\d$/.test(value) || value === '') {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (value && index < OTP_LENGTH - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleBackspace = (e, index) => {
        if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    const handlePaste = (e) => {
        const pastedData = e.clipboardData.getData('text').slice(0, OTP_LENGTH).split('');
        if (pastedData.every((char) => /^\d$/.test(char))) {
            setOtp(pastedData);
            inputRefs.current[pastedData.length - 1]?.focus();
        }
    };

    return (
        <>
            <SEOHelmet title="Login Verfiy Otp | TSJ Car Rentals" />

            <div className="flex justify-center items-center h-screen">
                <Helmet>
                    <title>Login Verify Otp  | TSJ Car Rentals</title>

                </Helmet>

                <div className="bg-white shadow-lg grid grid-cols-1 md:grid-cols-12 rounded-lg p-4 w-full text-[0.9rem]">
                    <div className="px-4 md:col-span-6 text-start w-full mx-auto p-6 font-sf-pro-rounded flex flex-col space-y-5 place-content-center">
                        <div className="flex justify-center mb-6">
                            <Link to="/">
                                <img src={Logo} alt="Logo" className="w-[50px] h-[50px]" />
                            </Link>
                        </div>
                        <h2 className="text-xl font-bold mb-6 text-center">OTP Validation</h2>
                        <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
                            <div className="flex w-full justify-between mb-4 space-x-2 md:space-x-0">
                                {otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handleInputChange(e, index)}
                                        onKeyDown={(e) => handleBackspace(e, index)}
                                        onPaste={handlePaste}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        className="w-12 h-12 text-center text-xl border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                ))}
                            </div>
                            {error && <p className="text-red-500 text-sm mb-4">{error}</p>}
                            <button
                                type="button"
                                onClick={handleVerifyOtp}
                                className="w-full h-12 cursor-pointer bg-[#162D3A] text-white rounded-md hover:bg-gray-800"
                            >
                                Verify OTP
                            </button>
                            <div className="flex flex-col md:flex-row justify-between items-start space-y-2 md:space-y-0">
                                <button
                                    type="button"
                                    onClick={handleResendOtp}
                                    className="text-blue-600 cursor-pointer text-[0.8rem]"
                                >
                                    Resend OTP
                                </button>
                                <p className="text-[#000] text-[16px]">
                                    Go to <Link to="/login" className="text-blue-600 font-bold italic">Login</Link>
                                </p>
                            </div>
                        </form>
                    </div>
                    <div className="hidden md:block md:col-span-6 h-[95vh]">
                        <img src={Signup} alt="Signup Illustration" className="w-full h-full object-cover rounded-lg" />
                    </div>
                </div>

            </div>
        </>
    );
};

export default LoginOtpForm;

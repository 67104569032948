import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addNewRatePerKm, getRatePerKmList, deleteRatePerKm } from '../../Services/AddVehicleDetails';
import { Button, Box, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import SEOHelmet from '../../Components/common/SeoHelmet';

const AddNewRatePerKm = () => {
    const [rateperkm, setRateperKm] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch existing rate per km data on mount
    useEffect(() => {
        const fetchRatePerKmList = async () => {
            try {
                const response = await getRatePerKmList();
                setRateperKm(response?.data || []);
            } catch (error) {
                console.error('Error fetching rate per km:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchRatePerKmList();
    }, []);

    // Formik setup
    const formik = useFormik({
        initialValues: {
            ratePerKm: ''
        },
        validationSchema: Yup.object({
            ratePerKm: Yup.number()
                .required('Rate per km is required')
                .min(1, 'Rate must be at least 1')
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                await addNewRatePerKm(values);
                setRateperKm([...rateperkm, values]); // Update list after adding
                resetForm(); // Clear form
            } catch (error) {
                console.error('Error adding rate per km:', error);
            }
        }
    });

    // Handle delete operation
    const handleDelete = async (id) => {
        try {
            await deleteRatePerKm(id); // Call delete API
            setRateperKm(rateperkm.filter(rate => rate._id !== id)); // Remove deleted item from list
        } catch (error) {
            console.error('Error deleting rate per km:', error);
        }
    };

    return (
        <div style={{ maxHeight: "72vh" }}>
             <SEOHelmet title="Create New Rate per km | TSJ Car Rentals "/>

            <Typography variant="h5" gutterBottom>
                Add New Rate Per Km
            </Typography>

            {/* Form to Add New Rate */}
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '3fr 1fr', // 75% for input, 25% for button
                    alignItems: 'center',
                    gap: 2,
                    marginTop: 2,
                }}
            >
                <TextField
                    id="ratePerKm"
                    name="ratePerKm"
                    label="Rate Per Km"
                    type="number"
                    value={formik.values.ratePerKm}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.ratePerKm && Boolean(formik.errors.ratePerKm)}
                    helperText={formik.touched.ratePerKm && formik.errors.ratePerKm}
                    margin="normal"
                />
                <Button color="primary" variant="contained" type="submit">
                    Add Rate
                </Button>
            </Box>

            {/* Table to display Rate per km list */}
            <TableContainer
                component={Paper}
                sx={{
                    // height: '400px',
                    overflowY: 'scroll',
                }}
            >
                <Table stickyHeader aria-label="vehicle table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1976D4' }}>
                            <TableCell sx={{ color: 'white' }}>Rate Per Km</TableCell>
                            <TableCell sx={{ color: 'white' }}>ID</TableCell>
                            <TableCell sx={{ color: 'white' }}>Version</TableCell>
                            <TableCell sx={{ color: 'white' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rateperkm.map((rate) => (
                            <TableRow key={rate._id}>
                                <TableCell>{rate.ratePerKm}</TableCell>
                                <TableCell>{rate._id}</TableCell>
                                <TableCell>{rate.__v}</TableCell>
                                <TableCell>
                                    <Button 
                                        color="secondary"
                                        variant="outlined"
                                        onClick={() => handleDelete(rate._id)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default AddNewRatePerKm;



import Taxi from "../Assests/Cars/Taxi.png"
import TaxiW from "../Assests/Cars/Taxiw.png"
import CarRental from "../Assests/Cars/CarRental.png"
import CarRentalW from "../Assests/Cars/Carw.png"
import TT from "../Assests/Cars/TT.png"
import TTW from "../Assests/Cars/TTW.png"

import serImg1 from "../Assests/newHomeImgs/bhopal.jpg";
import serImg2 from "../Assests/newHomeImgs/indore.webp";
import serImg3 from "../Assests/newHomeImgs/udaipur.jpeg";
import serImg4 from "../Assests/newHomeImgs/noida-to-agra.png";

export const CabServicesData = [
    {
        id: 1,
        img: serImg1,
        title: "Bhopal",
        // texiscount: "100+",
    },
    {
        id: 2,
        img: serImg2,
        title: "Indore",
        // texiscount: "100+",
    },
    {
        id: 3,
        img: serImg3,
        title: "Udaipur",
        // texiscount: "100+",
    },
    {
        id: 4,
        img: serImg4,
        title: "More...",
        // texiscount: "100+",
    },
];


export const vehicleTypes = [
    { id: 1, name: "Rental car", img: CarRental, Wimg: CarRentalW },
    { id: 2, name: "Taxi", img: Taxi, Wimg: TaxiW },
    { id: 3, name: "Tour&Travel", img: TT, Wimg: TTW }
];


export const options = [
    { id: 'one-way', label: 'Outstation One-Way' },
    { id: 'round-trip', label: 'Outstation Round-Trip' },
    { id: 'airport', label: 'Airport Transfers' },
    { id: 'hourly', label: 'Hourly Rentals' }
];

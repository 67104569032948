import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BASE_API } from '../Services/apis';
import axios from 'axios';
import { Typography, Box, CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SEOHelmet from '../Components/common/SeoHelmet';

const BookingDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const token = JSON.parse(localStorage.getItem("token"));
    const [bookingDetails, setBookingDetails] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const fetchBookingDetails = async () => {
            try {
                const vehicleType = localStorage.getItem("vehicleDetail");
                let response;

                if (vehicleType === "RentalVehicle") {
                    response = await axios.get(`${BASE_API}/get-booking-details/${id}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    if (response && response.status === 200) {
                        setBookingDetails(response?.data?.data || {});
                    } else {
                        throw new Error("Failed to fetch booking details");
                    }

                } else if (vehicleType === "TourTravelVehicle") {
                    response = await axios.get(`${BASE_API}/tour-package-booking-details/${id}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    if (response && response.status === 200) {
                        setBookingDetails(response?.data?.data || {});
                    } else {
                        throw new Error("Failed to fetch booking details");
                    }
                } else if (vehicleType === "TaxiVehicle") {
                    response = await axios.get(`${BASE_API}/get-taxi-booking-details/${id}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    if (response && response.status === 200) {
                        setBookingDetails(response?.data?.bookings || {});
                    } else {
                        throw new Error("Failed to fetch booking details");
                    }
                }


            } catch (error) {
                setError("Error fetching booking details");
                console.error("Error:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchBookingDetails();
    }, [id, token]);

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">{error}</Typography>;

    const {
        _id,
        pickupAddress,
        dropAddress,
        pickupDate,
        pickupTime,
        dropDate,
        dropTime,
        vehicle = {},
        status,
        fare,
        isRoundTrip,
        inBetweenCities = [],
    } = bookingDetails;

    return (<>

        <SEOHelmet title="User Booking Details | TSJ Car Rentals" />
        <div className="bg-gray-50 px-4 md:px-8 py-6 lg:py-12">
            <div className="flex justify-start my-4">
                <ArrowBackIcon onClick={() => navigate(-1)} />
            </div>
            <div className="max-w-6xl mx-auto">
                <div className="bg-gray-400 h-[120px] md:h-[150px] rounded-md mb-6 p-4">
                    <h1 className="text-black text-xl md:text-2xl font-bold">Booking Details</h1>
                    <div className="flex flex-wrap justify-between text-sm text-gray-600 mt-2">
                        <p>Booking ID: <span className="text-black font-semibold">{_id}</span></p>
                        <p>Status: <span className="text-black font-semibold">{status}</span></p>
                        <p>Fare: <span className="text-black font-semibold">₹{fare}</span></p>
                    </div>
                </div>

                <div className="bg-white shadow-md rounded-lg p-4 mb-4">
                    <h2 className="text-lg font-bold mb-4">Trip Details</h2>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                        <div>
                            <p className="text-left font-semibold">Pickup Address:</p>
                            <p>{pickupAddress} ({pickupTime}, {new Date(pickupDate).toDateString()})</p>
                        </div>
                        <div>
                            <p className="text-left font-semibold">Drop Address:</p>
                            <p>{dropAddress} ({dropTime}, {new Date(dropDate).toDateString()})</p>
                        </div>
                        {isRoundTrip && (
                            <div>
                                <p className="text-left font-semibold">Round Trip:</p>
                                <p>Yes</p>
                            </div>
                        )}
                    </div>

                    {inBetweenCities.length > 0 && (
                        <div className="mt-4">
                            <p className="text-left font-semibold mb-2">In-Between Cities:</p>
                            <ul className="list-disc pl-5">
                                {inBetweenCities.map((city, index) => (
                                    <li key={city._id || index}>{city.address}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                <div className="bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-lg font-bold mb-4">Vehicle Details</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                            <p className="text-left font-semibold">Type:</p>
                            <p>{vehicle.vehicleType}</p>
                        </div>
                        <div>
                            <p className="text-left font-semibold">Model:</p>
                            <p>{vehicle.vehicleModel}</p>
                        </div>
                        <div>
                            <p className="text-left font-semibold">Number:</p>
                            <p>{vehicle.vehicleNumber}</p>
                        </div>
                        <div>
                            <p className="text-left font-semibold">Fuel Type:</p>
                            <p>{vehicle.fuelType}</p>
                        </div>
                        <div>
                            <p className="text-left font-semibold">Transmission:</p>
                            <p>{vehicle.transmission}</p>
                        </div>
                        <div>
                            <p className="text-left font-semibold">Seats:</p>
                            <p>{vehicle.seats}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    );
};

export default BookingDetail;
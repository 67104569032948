import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Paper,
    Container,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import InvocePDF from "./InvocePDF";
import { BASE_API } from "../../Services/apis";

import SEOHelmet from "./SeoHelmet";

const GeneratedInvoice = () => {
    const pdfExportComponent = React.useRef(null);
    const [invoices, setInvoices] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const handleOpenModal = (data) => {
        setSelectedInvoice(data);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleDownload = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };

    const fetchInvoiceData = async () => {
        try {
            const response = await fetch(`${BASE_API}/invoice/invoice-list`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                const result = await response.json();
                setInvoices(result?.invoices || []);
            } else {
                console.error("Error fetching invoices:", response.statusText);
            }
        } catch (err) {
            console.error("Error fetching invoices:", err);
        }
    };

    useEffect(() => {
        fetchInvoiceData();
    }, []);

    return (
        <Container sx={{ margin: "50px auto" }}>
             <SEOHelmet title="Invoice | TSJ Car Rentals "/>

            <Typography variant="h4" sx={{ margin: "50px 0" }}>
                Invoices
            </Typography>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Invoice No</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Customer ID</TableCell>
                            <TableCell>Bill To</TableCell>
                            <TableCell>Ship To</TableCell>
                            <TableCell>Total Amount</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((invoice) => (
                            <TableRow key={invoice._id}>
                                <TableCell>{invoice.invoiceNo}</TableCell>
                                <TableCell>{new Date(invoice.date).toLocaleDateString()}</TableCell>
                                <TableCell>{invoice.customerId}</TableCell>
                                <TableCell>{invoice.billTo}</TableCell>
                                <TableCell>{invoice.shipTo}</TableCell>
                                <TableCell>{invoice.totalAmount.toLocaleString()}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleOpenModal(invoice)}
                                    >
                                        View & Download
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal for Invoice */}
            <Dialog open={openModal} onClose={handleCloseModal} maxWidth="md" fullWidth>
                <DialogTitle>Invoice Preview</DialogTitle>
                <DialogContent>
                    {selectedInvoice && (
                        <InvocePDF
                            pdfExportComponent={pdfExportComponent}
                            savedInvoiceData={selectedInvoice}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal} color="secondary">
                        Close
                    </Button>
                    <Button onClick={handleDownload} variant="contained" color="primary">
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default GeneratedInvoice;

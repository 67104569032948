import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    vehicles: [],  // Store array of vehicles
    loading: false,
    error: null
};

const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        setVehicles: (state, action) => {
            console.log(action.payload);
            state.vehicles = action.payload; // Set the vehicle data array
        },
        addVehicle: (state, action) => {
            state.vehicles.push(action.payload); // Add a new vehicle to the array
        },
        setLoading: (state, action) => {
            state.loading = action.payload; // Set loading state
        },
        setError: (state, action) => {
            state.error = action.payload; // Set error state
        }
    }
});

export const { setVehicles, addVehicle, setLoading, setError } = vehicleSlice.actions;

export default vehicleSlice.reducer;

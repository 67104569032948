// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import authReducer from './UserSlices'
import vehicleReducer from './VehiclesSlice';
import signupReducer from "./authSlices"
const store = configureStore({
    reducer: {
        auth: authReducer,
        vehicle: vehicleReducer,
        signup:signupReducer
    },
});

export default store;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addNewFuelType, getFuelList, deleteFuelType } from '../../Services/AddVehicleDetails';
import { Button, Box, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import SEOHelmet from '../../Components/common/SeoHelmet';

const AddNewFuel = () => {
    const [fuelTypes, setFuelTypes] = useState([]);

    // Fetch existing fuel types on mount
    useEffect(() => {
        const fetchFuelTypes = async () => {
            try {
                const response = await getFuelList();
                setFuelTypes(response?.data?.data || []); // Update state with fetched fuel types
            } catch (error) {
                console.error('Error fetching fuel types:', error);
            }
        };
        fetchFuelTypes();
    }, []);

    // Formik setup
    const formik = useFormik({
        initialValues: {
            fuelType: ''
        },
        validationSchema: Yup.object({
            fuelType: Yup.string()
                .required('Fuel type is required')
                .min(2, 'Fuel type must be at least 2 characters'),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                await addNewFuelType({ fuelType: values.fuelType }); // Send fuelType to the API
                setFuelTypes([...fuelTypes, { fuelType: values.fuelType, _id: Date.now() }]); // Update list after adding
                resetForm(); // Clear form
            } catch (error) {
                console.error('Error adding fuel type:', error);
            }
        }
    });

    // Handle Fuel Type Deletion
    const handleDelete = async (id) => {
        try {
            await deleteFuelType(id); // Call API to delete fuel type
            setFuelTypes(fuelTypes.filter(fuel => fuel._id !== id)); // Update state after deletion
        } catch (error) {
            console.error('Error deleting fuel type:', error);
        }
    };

    return (
        <div style={{ maxHeight: "72vh" }}>
            <SEOHelmet title="Add New Vehicle Fuel | TSJ Car Rentals " />
            <Typography variant="h5" gutterBottom>
                Add New Fuel Type
            </Typography>

            {/* Form to Add New Fuel Type */}
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '3fr 1fr', // 75% for input, 25% for button
                    alignItems: 'center',
                    gap: 2,
                    marginTop: 2,
                }}
            >
                <TextField
                    id="fuelType"
                    name="fuelType"
                    label="Fuel Type"
                    value={formik.values.fuelType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fuelType && Boolean(formik.errors.fuelType)}
                    helperText={formik.touched.fuelType && formik.errors.fuelType}
                    margin="normal"
                />
                <Button color="primary" variant="contained" type="submit">
                    Add Fuel Type
                </Button>
            </Box>

            <TableContainer
                component={Paper}
                sx={{
                    // height: '400px',
                    overflowY: 'scroll', 
                }}
            >
                <Table stickyHeader aria-label="vehicle table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1976D4' }}>
                            <TableCell sx={{ color: 'white' }}>Fuel Type</TableCell>
                            <TableCell sx={{ color: 'white' }}>ID</TableCell>
                            <TableCell sx={{ color: 'white' }}>Version</TableCell>
                            <TableCell sx={{ color: 'white' }}>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fuelTypes.map((fuel) => (
                            <TableRow key={fuel._id}>
                                <TableCell>{fuel.fuelType}</TableCell>
                                <TableCell>{fuel._id}</TableCell>
                                <TableCell>{fuel.__v}</TableCell>
                                <TableCell>
                                    {/* Delete Button */}
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        onClick={() => handleDelete(fuel._id)}
                                    >
                                        Delete
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default AddNewFuel;

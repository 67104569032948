import React from 'react'
import VendorRequestList from '../Layout/tables/Vendor/VendorRequestList'
import SEOHelmet from '../Components/common/SeoHelmet'

const AdminVendorRequestList = () => {
  return (
    <div>
            <SEOHelmet title="Vendor Request List | TSJ Car Rentals"/>

        <VendorRequestList/>
    </div>
  )
}

export default AdminVendorRequestList
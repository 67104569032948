// components/MapUI.js
import React, { useState } from "react";
import SearchLocationInput from "./GooglePlacesApi"; // Adjust the path if necessary

function MapUI() {
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 28.7041, // Default location (New Delhi)
    lng: 77.1025,
  });

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <SearchLocationInput setSelectedLocation={setSelectedLocation} />
    </div>
  );
}

export default MapUI;

import React, { useState } from "react";
import {
    Box, Typography, TextField, IconButton, Grid2, Select, MenuItem, InputLabel, FormControl,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


const FillBookingDetail = ({ bookingDetails, setBookingDetails }) => {
    // const [code,setCode]=useState("91")
    const handleIncrement = (key) => {
        setBookingDetails((prev) => ({ ...prev, [key]: prev[key] + 1 }));
    };

    const handleDecrement = (key) => {
        setBookingDetails((prev) => ({ ...prev, [key]: Math.max(0, prev[key] - 1) }));
    };

    return (
        <Box p={2}>
            <Box mb={3}>
                <Grid2 container spacing={2}>
                    <Grid2 item size={6}>
                        <div className="flex flex-col items-start border  px-2 py-1 rounded">
                            <label className="font-lato text-[10px] font-semibold leading-[12px] text-blue-400 uppercase text-left">
                                Pickup Date
                            </label>

                            <input
                                type="date"
                                className="bg-transparent border-none outline-none font-lato text-[13px] font-normal leading-[25.2px] text-left"
                                onChange={(e) => {
                                    const dateObj = new Date(e.target.value);
                                    const updatedDate = {
                                        date: dateObj.getDate(),
                                        month: dateObj.getMonth() + 1,
                                        year: dateObj.getFullYear(),
                                        day: dateObj.toLocaleString('default', { weekday: 'long' }),
                                    };
                                    setBookingDetails({ ...bookingDetails, startDate: `${updatedDate.year}-${updatedDate.month}-${updatedDate.date}` })
                                }}
                            />
                        </div>
                    </Grid2>
                    <Grid2 item size={6}>
                        <div className="flex flex-col items-start border px-2 py-1 rounded">
                            <label className="font-lato text-[10px] font-semibold leading-[12px] text-blue-400 uppercase text-left">
                                Pickup Time
                            </label>
                            <input
                                type="time"
                                className="bg-transparent border-none outline-none font-lato text-[13px] font-normal leading-[25.2px] text-left "
                                value={bookingDetails.pickupTime}
                                onChange={(e) => {
                                    setBookingDetails({ ...bookingDetails, pickupTime: e.target.value })
                                }}
                            />
                        </div>
                    </Grid2>
                    <Grid2 item size={6}>
                        <div className="flex flex-col items-start border  px-2 py-1 rounded">
                            <label className="font-lato text-[10px] font-semibold leading-[12px] text-blue-400 uppercase text-left">
                                Drop Date
                            </label>

                            <input
                                type="date"
                                className="bg-transparent border-none outline-none font-lato text-[13px] font-normal leading-[25.2px] text-left"
                                onChange={(e) => {
                                    const dateObj = new Date(e.target.value);
                                    const updatedDate = {
                                        date: dateObj.getDate(),
                                        month: dateObj.getMonth() + 1,
                                        year: dateObj.getFullYear(),
                                        day: dateObj.toLocaleString('default', { weekday: 'long' }),
                                    };
                                    setBookingDetails({ ...bookingDetails, endDate: `${updatedDate.year}-${updatedDate.month}-${updatedDate.date}` })
                                }}
                            />
                        </div>
                    </Grid2>
                    <Grid2 item size={6}>

                        <div className="flex flex-col border items-start px-2 py-1 rounded">
                            <label className="font-lato text-[10px] font-semibold leading-[12px] text-blue-400 uppercase text-left">
                                Drop Time
                            </label>
                            <input
                                type="time"
                                className="bg-transparent border-none outline-none font-lato text-[13px] font-normal leading-[25.2px] text-left"
                                value={bookingDetails.dropTime}
                                onChange={(e) => {
                                    setBookingDetails({ ...bookingDetails, dropTime: e.target.value })
                                }}
                            />
                        </div>
                    </Grid2>
                </Grid2>
            </Box>

            {/* Adults and Children */}
            <Box mb={3}>
                <Grid2 container spacing={2} alignItems="center">
                    <Grid2 item size={6}>
                        <div className="flex items-center">
                            <Typography>Adults</Typography>
                            <IconButton onClick={() => handleDecrement("adults")}
                                color="error"
                            >
                                <RemoveIcon />
                            </IconButton>
                            <span>{bookingDetails.adults}</span>
                            <IconButton
                                onClick={() => handleIncrement("adults")}
                                color="primary"
                            >

                                <AddIcon />
                            </IconButton>
                        </div>
                    </Grid2>
                    <Grid2 item size={6}>
                        <Typography>Children</Typography>
                        <Box display="flex" alignItems="center">
                            <IconButton
                                onClick={() => handleDecrement("children")}
                                color="error"
                            >
                                <RemoveIcon />
                            </IconButton>
                            <Typography>{bookingDetails.children}</Typography>
                            <IconButton
                                onClick={() => handleIncrement("children")}
                                color="primary"
                            >
                                <AddIcon />
                            </IconButton>
                        </Box>
                    </Grid2>
                </Grid2>
            </Box>

            <Box mb={3}>
                <TextField
                    label="Special Requests"
                    value={bookingDetails.specialRequests}
                    onChange={(e) => setBookingDetails({ ...bookingDetails, specialRequests: e.target.value })}
                    fullWidth
                />
            </Box>

            <Box mb={3}>
                <Grid2 container spacing={2}>
                    <Grid2 item size={6}>
                        <PhoneInput
                            country={"in"}
                            value={bookingDetails.alternateMobile}
                            onChange={(value) =>
                                setBookingDetails({ ...bookingDetails, alternateMobile: value })
                            }
                            inputStyle={{
                                width: "100%",
                                padding: "10px",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                            }}
                        />
                    </Grid2>
                </Grid2>
            </Box>
        </Box>
    );
};

export default FillBookingDetail;

import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { FiUpload } from 'react-icons/fi';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BASE_API } from '../../Services/apis';
import { setUser, setToken } from '../../slices/UserSlices';

const IndianForm = ({ user, token }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState(null);
  const vehicleTypes = location.pathname.split('/')[2];

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    setQueryParams(queryParams.toString());
  }, [location.search]);

  const formik = useFormik({
    initialValues: {
      name: user?.name || '',
      address: user?.address || '',
      nationality: user?.nationality || '',
      aadharCardNumber: '',
      dlNumber: '',
      dlExpiry: '',
      dlType: '',
      dlCode: '',
      socialMedia: '',
      familyContact: '',
      alternateContact: '',
      emergencyContact: '',
      refrenceName: '',
      refrenceContact: '',
      panNumber: '',
      aadharCardFrontImg: null,
      aadharCardBackImg: null,
      dlImg: null,
    },
    enableReinitialize: true, // Add this line to enable reinitialization
    validationSchema: Yup.object({
      nationality: Yup.string().required('Nationality is required'),
      address: Yup.string().required('Address is required'),
      aadharCardNumber: Yup.string()
        .matches(/^\d+$/, 'Aadhar Card Number must be a number')
        .required('Aadhar Card Number is required'),
      dlNumber: Yup.string().required('DL Number is required'),
      dlExpiry: Yup.date().required('DL Expiry is required'),
      dlType: Yup.string().required('DL Type is required'),
      dlCode: Yup.string().required('DL Code is required'),
      familyContact: Yup.string().matches(/^\d+$/, 'Family Contact must be a number'),
      alternateContact: Yup.string().matches(/^\d+$/, 'Alternate Contact must be a number'),
      emergencyContact: Yup.string().matches(/^\d+$/, 'Emergency Contact must be a number'),
      refrenceContact: Yup.string().matches(/^\d+$/, 'Reference Contact must be a number'),
    }),
    onSubmit: async (values) => {
      try {
        const response = await axios.post(`${BASE_API}/user/fill-user-details`, values, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.status === 200 || response.statusText === "OK") {
          dispatch(setToken(response.data?.data?.token));
          dispatch(setUser(response.data?.data));
          localStorage.setItem('token', JSON.stringify(response?.data?.data?.token));
          localStorage.setItem('user', JSON.stringify(response.data?.data));
          navigate(`/review-booking/${vehicleTypes}?${queryParams}`);
        } else {
          toast.error("An error occurred. Please try again.");
        }
      } catch (error) {
        toast.error(`Error: ${error.response?.data?.message || error.message}`);
        console.error("Error submitting form:", error.message);
      }
    },
  });

  return (
    <div className="max-w-[1350px] mx-auto mt-10 p-1">
      <h2 className="text-2xl font-bold text-start mb-6">Enter the Details</h2>
      <form onSubmit={formik.handleSubmit} className="grid grid-cols-4 gap-2 p-5 border-dotted border-2 border-blue-600 rounded-md">

        {/* DL Type Field as Select */}
        <div className="col-span-1 text-start h-[85px]">
          <label className="block text-[12px] text-[#666666] font-medium">DL Type</label>
          <select
            name="dlType"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dlType}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="" label="Select DL Type" />
            <option value="NT" label="NT" />
            <option value="TR" label="TR" />
            <option value="Internation driving permit" label="International" />
          </select>
          {formik.touched.dlType && formik.errors.dlType && (
            <span className="text-red-500 text-[10px]">{formik.errors.dlType}</span>
          )}
        </div>

        {/* DL Code Field as Select */}
        <div className="col-span-1 text-start h-[85px]">
          <label className="block text-[12px] text-[#666666] font-medium">DL Code</label>
          <select
            name="dlCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.dlCode}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="" label="Select DL Code" />
            <option value="LMV" label="LMV" />
            <option value="HGV" label="HGV" />
            <option value="TRANS" label="TRANS" />
          </select>
          {formik.touched.dlCode && formik.errors.dlCode && (
            <span className="text-red-500 text-[10px]">{formik.errors.dlCode}</span>
          )}
        </div>

        {/* Remaining Fields */}
        {Object.entries(formik.initialValues).map(([field, _]) => (
          field !== 'dlType' && field !== 'dlCode' && (
            <div key={field} className="col-span-1 text-start h-[85px]">
              {['aadharCardFrontImg', 'aadharCardBackImg', 'dlImg'].includes(field) ? (
                <div>
                  <label className="block text-[12px] text-[#666666] font-medium">{field}</label>
                  <div className="relative border border-gray-300 rounded-md shadow-sm w-full">
                    <input
                      type="file"
                      name={field}
                      className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                      onChange={(e) => formik.setFieldValue(field, e.target.files[0])}
                    />
                    <div className="flex items-center justify-between px-3 py-2">
                      <span className="text-gray-500">{formik.values[field]?.name || `Upload img`}</span>
                      <FiUpload className="text-gray-400" size={24} />
                    </div>
                  </div>
                  {formik.touched[field] && formik.errors[field] && (
                    <span className="text-red-500 text-[10px]">{formik.errors[field]}</span>
                  )}
                </div>
              ) : (
                <div>
                  <label className="block text-[12px] text-[#666666] font-medium">
                    {field.charAt(0).toUpperCase() + field.slice(1)}
                  </label>
                  <input
                    type={field === 'dlExpiry' ? 'date' : 'text'}
                    name={field}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values[field]}
                    placeholder={`Enter ${field}`}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                  />
                  {formik.touched[field] && formik.errors[field] && (
                    <span className="text-red-500 text-[10px]">{formik.errors[field]}</span>
                  )}
                </div>
              )}
            </div>
          )
        ))}

        <div className="col-span-4 flex justify-center items-center mt-6">
          <button
            type="submit"
            className="bg-blue-600 w-fit text-white px-6 py-2 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default IndianForm;
import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Avatar,
  Modal,
  IconButton,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import CloseIcon from "@mui/icons-material/Close";
import {
  rentalBookingDetails,
  // adminApproveRejectRental,
} from "../../../Services/adminAPI";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../utils/FormateValue";
import toast from 'react-hot-toast';

import SEOHelmet from "../../../Components/common/SeoHelmet";

const RentalBookingView = ({approveRejectRental}) => {
  const { bookingId } = useParams();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [error, setError] = useState(null);
  const [zoomImg, setZoomImg] = useState(null);

  const fetchBookingDetails = async () => {
    try {
      const response = await rentalBookingDetails(bookingId);
      if (response.status === 200) {
        setBookingDetails(response.data.data);
      } else {
        setError("Failed to fetch booking details.");
      }
    } catch (err) {
      setError("An error occurred while fetching booking details.");
    }
  };

  useEffect(() => {
    if (bookingId) {
      fetchBookingDetails();
    }
  }, [bookingId]);

  const handleZoomIn = (img) => {
    setZoomImg(img);
  };

  const handleZoomOut = () => {
    setZoomImg(null);
  };

  const handleApproveReject = async (bookingId, status, rejectionReason) => {
    try {
      const response = await approveRejectRental(
        bookingId,
        status,
        rejectionReason
      );
      if (response.status === 200) {
        toast.success(response.data.message || "Approved sucessfully");
        fetchBookingDetails();
      }
    } catch (error) {
      toast.error(error.response.data.message || "An error occurred. Please try again.");

    }
  };

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!bookingDetails) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Typography variant="h6">Loading booking details...</Typography>
      </Box>
    );
  }

  const {
    userId = {},
    vehicleId = {},
    pickupDate,
    dropDate,
    pickupTime,
    dropTime,
    pickupLocation,
    dropLocation,
    totalAmount,
    paymentStatus,
    bookingStatus,
    adminApprovalStatus,
    managerApprovalStatus
  } = bookingDetails;

  const isForeignNational = userId.nationality === "foreign";

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
        padding: 2,
      }}
    >
      <SEOHelmet title="Rental vehicle booked Details | TSJ Car Rentals"/>
      
      <Card sx={{ maxWidth: 800, width: "100%" }}>
        <CardContent>
          <Typography
            variant="h5"
            sx={{ textAlign: "center", fontWeight: "bold", mb: 2 }}
          >
            Booking Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                User Details
              </Typography>
              <Typography align="left">Name: {userId.name || "N/A"}</Typography>
              <Typography align="left">
                Email: {userId.email || "N/A"}
              </Typography>
              <Typography align="left">
                Mobile: {userId.mobile || "N/A"}
              </Typography>
              <Typography align="left">
                AlternateContact: {userId.alternateContact || "N/A"}
              </Typography>
              <Typography align="left">
                Address: {userId.address || "N/A"}
              </Typography>
              <Typography align="left">
                Nationality: {userId.nationality || "N/A"}
              </Typography>

              {isForeignNational ? (
                <>
                  <Typography align="left">
                    Passport Number: {userId.passportNumber || "N/A"}
                  </Typography>
                  <Typography align="left">
                    Foreign DL Number: {userId.foreignDLNumber || "N/A"}
                  </Typography>
                  <Typography align="left">
                    Foreign DL Type: {userId.foreigndlType || "N/A"}
                  </Typography>
                  <Typography align="left">
                    DL Expiry: {userId.foreignDLExpiry || "N/A"}
                  </Typography>
                  <Typography align="left">
                    DL Code: {userId.foreigndlCode || "N/A"}
                  </Typography>
                  <Box>
                    <Button
                      onClick={() => handleZoomIn(userId.foreignDLImg)}
                      startIcon={<ZoomInIcon />}
                    >
                      View Foreign DL
                    </Button>

                    <Button
                      onClick={() => handleZoomIn(userId.passportImg)}
                      startIcon={<ZoomInIcon />}
                    >
                      View Passport
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Typography align="left">
                    Aadhar Number: {userId.aadharCardNumber || "N/A"}
                  </Typography>
                  <Box>
                    <Button
                      onClick={() => handleZoomIn(userId.aadharCardFrontImg)}
                      startIcon={<ZoomInIcon />}
                    >
                      View Aadhar Front
                    </Button>
                    <Button
                      onClick={() => handleZoomIn(userId.aadharCardBackImg)}
                      startIcon={<ZoomInIcon />}
                    >
                      View Aadhar Back
                    </Button>
                  </Box>
                  <Typography align="left">
                    PAN Number: {userId.panNumber || "N/A"}
                  </Typography>
                  <Typography align="left">
                    DL Number: {userId.dlNumber || "N/A"}
                  </Typography>
                  <Typography align="left">
                    DL Code: {userId.dlCode || "N/A"}
                  </Typography>

                  <Typography align="left">
                    DL type: {userId.dlType || "N/A"}
                  </Typography>
                  <Typography align="left">
                    DL Expiry: {formatDate(userId.dlExpiry) || "N/A"}
                  </Typography>
                  <Box>
                    <Button
                      onClick={() => handleZoomIn(userId.dlImg)}
                      startIcon={<ZoomInIcon />}
                    >
                      View DL
                    </Button>
                  </Box>
                </>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight="bold">
                Vehicle Details
              </Typography>
              <Box>
                <img src={vehicleId.img} alt={vehicleId.vehicleModel} />
              </Box>
              <Typography align="left">
                Type: {vehicleId.vehicleType || "N/A"}
              </Typography>
              <Typography align="left">
                Model: {vehicleId.vehicleModel || "N/A"}
              </Typography>
              <Typography align="left">
                Number: {vehicleId.vehicleNumber || "N/A"}
              </Typography>

              <Typography align="left" >
                Admin Approval Status: <span style={{ color: adminApprovalStatus === "Approved" ? "green" : adminApprovalStatus === "Rejected" ? "red" : "" }}>{adminApprovalStatus || "N/A"} </span> {adminApprovalStatus === "Approved" && <CheckCircleIcon color="success" />} {adminApprovalStatus === "Rejected" && <CancelIcon color="error" />}
              </Typography>

              <Typography align="left">
                Manager Approval Status: <span style={{ color: managerApprovalStatus === "Approved" ? "green" : managerApprovalStatus === "Rejected" ? "red" : "" }}>{managerApprovalStatus || "N/A"} </span> {managerApprovalStatus === "Approved" && <CheckCircleIcon color="success" />} {managerApprovalStatus === "Rejected" && <CancelIcon color="error" />}
              </Typography>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              mt: 3,
            }}
          >
            <Button
              variant="contained"
              color="success"
              startIcon={<CheckCircleIcon />}
              onClick={() => handleApproveReject(bookingId, "Approved")}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              color="error"
              startIcon={<CancelIcon />}
              onClick={() =>
                handleApproveReject(bookingId, "Rejected", "Doc incomplete")
              }
            >
              Reject
            </Button>
          </Box>
        </CardContent>
      </Card>

      <Modal open={!!zoomImg} onClose={handleZoomOut}>
        <Box
          sx={{
            position: "absolute",
            maxWidth: "800px",
            maxHeight: "80vh",
            height: "100%",
            width: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <IconButton
            onClick={handleZoomOut}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          {zoomImg && (
            <img
              src={zoomImg}
              alt="Document"
              style={{ width: "100%", height: "100%" }}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default RentalBookingView;

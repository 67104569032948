import api from "./apiUtility";

export const addNewVehicleTypes = async (data) => {
    try {
        const response = await api.post(`/admin/add-new-vehicle-type`, data);
        return response;
    } catch (error) {
        throw error;
    }
};
export const updateRentalVehicleStatus = async (vehicleId,status) => {
    try {
        const response = await api.patch(`/admin/disable-enable-rental-vehicle/${vehicleId}`, {available:status});
        return response;
    } catch (error) {
        throw error;
    }
};

export const editRentalVehicle = async (data) => {
    const {vehicleId}=data
    try {
        const response = await api.patch(`/admin/edit-rental-vehicle/${vehicleId}`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const updateTaxiVehicleStatus = async (vehicleId,status) => {
    try {
        const response = await api.patch(`/admin/disable-enable-taxi-vehicle/${vehicleId}`, {available:status});
        return response;
    } catch (error) {
        throw error;
    }
};

export const editTaxiVehicle = async (data) => {
    const {vehicleId}=data
    console.log("vehicleId",vehicleId)
    try {
        const response = await api.patch(`/admin/edit-taxi-vehicle/${vehicleId}`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteVehicleTypes = async (vehicleTypeId) => {
    try {
        const response = await api.delete(`/admin/delete-particular-vehicle-type/${vehicleTypeId}`);
        return response;
    } catch (error) {
        throw error;
    }
};

export const addNewRatePerKm = async (payload) => {
    try {
        const response = await api.post("/admin/add-new-rate-per-km", payload);
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteRatePerKm = async (vehicleTypeId) => {
    try {
        const response = await api.delete(`/admin/delete-particular-rate-per-km/${vehicleTypeId}`);
        return response;
    } catch (error) {
        throw error;
    }
};


export const addNewFuelType = async (payload) => {
    try {
        const response = await api.post("/admin/add-new-fuel-type", payload);
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteFuelType = async (fuelTypeId) => {
    try {
        const response = await api.delete(`/admin/delete-particular-fuel-type/${fuelTypeId}`);
        return response;
    } catch (error) {
        throw error;
    }
};


export const addNewService = async (payload) => {
    try {
        const response = await api.post("/admin/add-new-service-type", payload);
        return response;
    } catch (error) {
        throw error;
    }
};

export const deleteService = async (serviceTypeId) => {
    try {
        const response = await api.delete(`/admin/delete-particular-service/${serviceTypeId}`);
        return response;
    } catch (error) {
        throw error;
    }
};


export const addNewSeating = async (payload) => {
    try {
        const response = await api.post("/admin/add-new-seating-type", payload);
        return response;
    } catch (error) {
        throw error;
    }
};

export const addNewVehicleModel = async (payload) => {
    try {
        const response = await api.post("/admin/add-new-vehicle-modal", payload);
        return response;
    } catch (error) {
        throw error;
    }
};


export const RejectAndApproveRentalBooking = async (bookingId, payload) => {
    try {
        const response = await api.patch(`/admin/approve-reject/rental-booking/${bookingId}`, payload)
        return response;
    } catch (error) {
        throw error
    }
}


export const getVehicleTypesList = async () => {
    try {
        const response = await api.get("/all-vehicles-type-list");
        return response;
    } catch (error) {
        throw error
    }
}


export const getRatePerKmList = async () => {
    try {
        const response = await api.get("/all-rate-per-km-list");
        return response;
    } catch (error) {
        throw error
    }
}


export const getServicesList = async () => {
    try {
        const response = await api.get("/all-service-type-list");
        return response;
    } catch (error) {
        throw error
    }
}


export const getVehicleModelList = async () => {
    try {
        const response = await api.get("/vehicle-model-list");
        return response;
    } catch (error) {
        throw error
    }
}


export const getFuelList = async () => {
    try {
        const response = await api.get("/fuel-type-list");
        return response;
    } catch (error) {
        throw error
    }
}

export const getAllRentalBookings = async () => {
    try {
        const response = await api.get("/get-all-rental-bookings");
        return response;
    } catch (error) {
        throw error
    }
}

export const getAllOnboardVehicleList = async () => {
    try {
        const response = await api.get("/admin/all-onboard-rental-vehicle");
        return response;
    } catch (error) {
        throw error
    }
}


export const OnboardNewRentalVehicle = async (payload) => {
    try {
        const response = await api.post("/admin/onboard-new-rental-vehicle", payload);
        return response;
    } catch (error) {
        throw error
    }
}



export const getAllOnboardTaxiVehicleList = async () => {
    try {
        const response = await api.get("/admin/all-onboard-taxi");
        return response;
    } catch (error) {
        throw error
    }
}

export const OnboardNewTaxiVehicle = async (payload) => {
    try {
        const response = await api.post("/admin/onboard-new-taxi", payload);
        return response;
    } catch (error) {
        throw error
    }
}


export const getAllOnboardTTVehicleList = async () => {
    try {
        const response = await api.get("/admin/all-onboard-rental-vehicle");
        return response;
    } catch (error) {
        throw error
    }
}

export const OnboardNewTTVehicle = async (payload) => {
    try {
        const response = await api.post("/admin/create-new-tour-package", payload);
        return response;
    } catch (error) {
        throw error
    }

}
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { addNewVehicleModel, getVehicleModelList } from '../../Services/AddVehicleDetails';
import { Button, Box, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import SEOHelmet from '../../Components/common/SeoHelmet';
const AddNewVehcileModel = () => {
    const [vehicleModel, setVehicleModel] = useState([]);
    
    // Fetch existing vehicle Models on mount
    useEffect(() => {
        const fetchVehiclemodel = async () => {
            try {
                const response = await getVehicleModelList();
                setVehicleModel(response?.data || []);
            } catch (error) {
                console.error('Error fetching vehicle Models:', error);
            }
        };
        fetchVehiclemodel();
    }, []);

    // Formik setup
    const formik = useFormik({
        initialValues: {
            vehicleModel: ''
        },
        validationSchema: Yup.object({
            vehicleModel: Yup.string()
                .required('Vehicle Model is required')
                .min(2, 'Vehicle Model must be at least 2 characters'),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                await addNewVehicleModel(values);
                setVehicleModel([...vehicleModel, { vehicleModel: values.vehicleModel, _id: Date.now() }]); // Update list after adding
                resetForm(); // Clear form
            } catch (error) {
                console.error('Error adding vehicle Model:', error);
            }
        }
    });

    return (
        <div style={{ maxHeight: "72vh" }}>
             <SEOHelmet title="Create Vehicle Modal "/>

            <Typography variant="h5" gutterBottom>
                Add New Vehicle Modal
            </Typography>

            {/* Form to Add New Vehicle Model */}
            <Box
                component="form"
                onSubmit={formik.handleSubmit}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '3fr 1fr', // 75% for input, 25% for button
                    alignItems: 'center',
                    gap: 2,
                    marginTop: 2,
                }}
            >
                <TextField
                    id="vehicleModel"
                    name="vehicleModel"
                    label="Vehicle Model"
                    value={formik.values.vehicleModel}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.vehicleModel && Boolean(formik.errors.vehicleModel)}
                    helperText={formik.touched.vehicleModel && formik.errors.vehicleModel}
                    margin="normal"
                />
                <Button color="primary" variant="contained" type="submit">
                    Add Vehicle Model
                </Button>
            </Box>

            {/* Table to display Vehicle Models */}
            <TableContainer
                component={Paper}
                sx={{
                    height: '400px', // Adjust this value to the desired height
                    overflowY: 'scroll', // Enables vertical scrolling
                }}
            >
                <Table aria-label="vehicle Models table">
                    <TableHead>
                        <TableRow sx={{ backgroundColor: '#1976D4' }}>
                            <TableCell sx={{ color: 'white' }}>Vehicle Model</TableCell>
                            <TableCell sx={{ color: 'white' }}>ID</TableCell>
                            <TableCell sx={{ color: 'white' }}>Version</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vehicleModel.map((Model) => (
                            <TableRow key={Model._id}>
                                <TableCell>{Model.vehicleModel}</TableCell>
                                <TableCell>{Model._id}</TableCell>
                                <TableCell>{Model.__v}</TableCell>


                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div >
    );
};

export default AddNewVehcileModel

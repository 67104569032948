import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardMedia, Typography, Grid, Divider, Chip, Button } from '@mui/material';
import { AccessTime, Person, LocationOn, Group, LocalOffer } from '@mui/icons-material';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { tourPackageBookingDetails, updateStatusTourPacakgeBooking } from '../../../Services/adminAPI'
import toast from 'react-hot-toast';
import { useParams } from "react-router-dom";
import { Helmet } from 'react-helmet';
import SEOHelmet from '../../../Components/common/SeoHelmet';

const TourPackageView = () => {

    const { bookingId } = useParams();
    const [bookingDetails, setBookingDetails] = useState(null);
    const [error, setError] = useState(null);

    const fetchBookingDetails = async () => {
        try {
            const response = await tourPackageBookingDetails(bookingId);
            if (response.status === 200) {
                setBookingDetails(response.data.bookings);
            } else {
                setError("Failed to fetch booking details.");
            }
        } catch (err) {
            setError("An error occurred while fetching booking details.");
        }
    };

    useEffect(() => {
        if (bookingId) {
            fetchBookingDetails();
        }
    }, [bookingId]);

    const handleApproveReject = async (bookingId, bookingStatus) => {
        try {
            const response = await updateStatusTourPacakgeBooking(bookingId, bookingStatus);
            if (response.status === 200) {
                toast.success(response.data.message || "Approved sucessfully");
                fetchBookingDetails();
            }
        } catch (error) {
            toast.error(error.response.data.message || "An error occurred. Please try again.");

        }
    };


    if (error) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                }}
            >
                <Typography variant="h6" color="error">
                    {error}
                </Typography>
            </Box>
        );
    }

    if (!bookingDetails) {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "100vh",
                }}
            >
                <Typography variant="h6">Loading booking details...</Typography>
            </Box>
        );
    }

    const {
        name,
        nationality,
        mobile,
        email,
        tourPackageId,
        selectedDates,
        adults,
        children,
        groupSize,
        packagePrice,
        totalPrice,
        paymentStatus,
        bookingStatus,
        specialRequests,
    } = bookingDetails;

    return (
        <Box sx={{ p: 2 }}>
            <SEOHelmet title="TourAndTravel Booked Detail | TSJ Car Rentals" />
            <Card sx={{ maxWidth: 800, margin: 'auto', boxShadow: 3 }}>
                <CardMedia
                    component="img"
                    height="200"
                    image={tourPackageId.titleImg}
                    alt={tourPackageId.packageName}
                />
                <CardContent>
                    <Typography variant="h5" gutterBottom>
                        {tourPackageId.packageName}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {tourPackageId.description}
                    </Typography>
                    <Divider sx={{ my: 2 }} />

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <Person fontSize="small" sx={{ mr: 1 }} />
                                {name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Nationality: {nationality}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Mobile: {mobile}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Email: {email}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <AccessTime fontSize="small" sx={{ mr: 1 }} />
                                Tour Dates
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Start: {new Date(selectedDates.startDate).toLocaleDateString()} at {selectedDates.pickupTime}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                End: {new Date(selectedDates.endDate).toLocaleDateString()} at {selectedDates.dropTime}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <Group fontSize="small" sx={{ mr: 1 }} />
                                Group Details
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Adults: {adults}, Children: {children}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Max Group Size: {groupSize}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                <LocalOffer fontSize="small" sx={{ mr: 1 }} />
                                Pricing
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Package Price: ₹{packagePrice.toLocaleString()}
                            </Typography>
                            <Typography variant="h6" color="primary">
                                Total: ₹{totalPrice.toLocaleString()}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Divider sx={{ my: 2 }} />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                Special Requests
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {specialRequests || 'None'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="subtitle1" gutterBottom>
                                Booking Status
                            </Typography>
                            <Chip
                                label={bookingStatus.toUpperCase()}
                                color={bookingStatus === 'Pending' ? 'warning' : 'success'}
                                sx={{ fontWeight: 'bold' }}
                            />
                            <Typography variant="body2" color="text.secondary">
                                Payment: {paymentStatus}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: 2,
                            mt: 3,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="success"
                            startIcon={<CheckCircleIcon />}
                            disabled={bookingStatus === "Confirmed"}
                            onClick={() => handleApproveReject(bookingId, "Confirmed")}
                        >
                            Confirm Booking
                        </Button>

                        <Button
                            variant="contained"
                            color="error"
                            startIcon={<CheckCircleIcon />}
                            disabled={bookingStatus === "Cancelled"}
                            onClick={() => handleApproveReject(bookingId, "Cancelled")}
                        >
                            Cancel Booking
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default TourPackageView;

import React from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Grid, Button, Divider, Paper, Container } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Helmet } from 'react-helmet';
import SEOHelmet from '../../common/SeoHelmet';

const TourPackageBookingSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const bookingData = location.state?.booking;

    const handleContinue = () => {
        navigate("/user/profile")
    }

    if (!bookingData) {
        return (
            <Container maxWidth="sm" sx={{ py: 4 }}>
                <Paper elevation={3} sx={{ p: 4, textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                        No booking data available. Please try again.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/")}
                        sx={{ mt: 2 }}
                    >
                        Go Back
                    </Button>
                </Paper>
            </Container>
        );
    }

    const {
        name,
        nationality,
        mobile,
        alternateMobile,
        email,
        packageName,
        destination,
        durationInDays,
        durationInNight,
        selectedDates: { startDate, pickupTime, endDate, dropTime },
        transportation,
        activities,
        groupSize,
        adults,
        children,
        titleImg,
        packagePrice,
        totalPrice,
        paymentStatus,
        bookingDate,
        bookingStatus,
    } = bookingData;

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <SEOHelmet title="TourAndTravel confirmation Booking | TSJ Car Rentals"/>
           <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
            <Box sx={{ padding: 3 }}>
                <Box display="flex" alignItems="center" mb={2}>
                    <CheckCircleIcon color="success" sx={{ fontSize: 40, mr: 2 }} />
                    <Typography variant="h5" fontWeight="bold">
                        Booking successful!
                    </Typography>
                </Box>
                <Card sx={{ display: 'flex', marginBottom: 3 }}>
                    <CardMedia
                        component="img"
                        sx={{ width: 300 }}
                        image={titleImg}
                        alt={packageName}
                    />
                    <CardContent>
                        <Typography variant="h5" align='left' gutterBottom>
                            {packageName}
                        </Typography>
                        <Typography variant="body1" align='left' gutterBottom>
                            Destination: {destination}
                        </Typography>
                        <Typography variant="body1" align='left' gutterBottom>
                            Duration: {durationInDays} Days / {durationInNight} Nights
                        </Typography>
                        <Typography variant="body1" align='left' gutterBottom >
                            Transportation: {transportation}
                        </Typography>
                        <Typography variant="body1" align='left' gutterBottom>
                            Activities: {activities.join(', ')}
                        </Typography>
                        <Typography variant="body1" align='left' gutterBottom>
                            Group Size: {groupSize} (Adults: {adults}, Children: {children})
                        </Typography>
                    </CardContent>
                </Card> 

                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" align='left'>Traveler Information</Typography>
                        <Divider sx={{ marginBottom: 1 }} />
                        <Typography align='left'>Name: {name}</Typography>
                        <Typography align='left'>Nationality: {nationality}</Typography>
                        <Typography align='left'>Mobile: {mobile}</Typography>
                        <Typography align='left'>Alternate Mobile: {alternateMobile}</Typography>
                        <Typography align='left'>Email: {email}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" align='left'>Booking Details</Typography>
                        <Divider sx={{ marginBottom: 1 }} />
                        <Typography align='left'>Start Date: {new Date(startDate).toLocaleDateString()}</Typography>
                        <Typography align='left'>Pickup Time: {pickupTime}</Typography>
                        <Typography align='left'>End Date: {new Date(endDate).toLocaleDateString()}</Typography>
                        <Typography align='left'>Drop Time: {dropTime}</Typography>
                        <Typography align='left'>Booking Date: {new Date(bookingDate).toLocaleDateString()}</Typography>
                        <Typography align='left'>Payment Status: {paymentStatus}</Typography>
                        <Typography align='left'>Booking Status: {bookingStatus}</Typography>
                        <Typography align='left'>Package Price: &#8377;{packagePrice}</Typography>
                        <Typography align='left'>Total Price: &#8377;{totalPrice}</Typography>
                    </Grid>
                </Grid>

                <Box sx={{ marginTop: 3 }}>
                    {/* <Button variant="contained" color="primary" sx={{ marginRight: 2 }}>
                        View Itinerary
                    </Button> */}
                    <Button variant="outlined" color="secondary" onClick={handleContinue}>
                        Continue
                    </Button>
                </Box>
            </Box>
            </Paper>
        </Container>
    );
};

export default TourPackageBookingSuccess;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "../Components/common/Navbar";
import FindTripForm from "../Components/Core/TaxiPackages/FindTripFrom";
import { motion } from "framer-motion";
import { BASE_API } from "../Services/apis";
import { useSearchParams } from "react-router-dom";
import TaxiCarDetails from "../Components/Core/TaxiPackages/TaxiCarDetailCard";

import SEOHelmet from "../Components/common/SeoHelmet";

const TaxiPackagesPages = () => {
    const [alerModel, setAlertModel] = useState(null);
    const [vehicleData, setVehicleData] = useState([]);
    const [isFormField, setIsFormField] = useState(false);
    const [queryParameter, setQueryParameter] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false);

    const onClose = () => {
        setAlertModel(null);

    }

    const handlefillModel = (data) => {
        setAlertModel(data)


    }

    const query = (data) => {
        console.log('plese show data', data)
        setQueryParameter({
            pickUpLocation: data.pickUpLocation,
            dropAddress: data.dropAddress,
            pickupDate: data.pickUpDate,
            pickupTime: data.pickUpTime,
            dropoffDate: data.dropDate,
            dropoffTime: data.dropTime,
            // fuelType: data.fuelType,
            // alternatMobil: data.alternateMobile,
            inBetweenCities: JSON.stringify(data.inBetweenCities),
        });

    }

    const fetchVehicleData = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${BASE_API}/search-avaliable-taxi?${new URLSearchParams(queryParameter).toString()}`);
            if (!response.ok) throw new Error('Failed to fetch vehicle data');
            const data = await response.json();
            console.log("data for taxi page", data);
            setVehicleData(data?.filteredVehicles || []);
        } catch (error) {
            console.error('Error fetching vehicle data:', error);
           handlefillModel(error.response?.data?.message)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchVehicleData();
    }, [queryParameter]);


    return (
        <section>
            <SEOHelmet title="Taxi Packages | TSJ Car Rentals" />

            <Navbar />
            <section className="w-full md:px-[28px] py-[32px] grid grid-cols-12 md:h-[89vh]">
                <div className=" xsm:col-span-12 sm:col-span-4 md:col-span-3 p-5 rounded-md">
                    <FindTripForm
                        fetchVehicleData={fetchVehicleData}
                        setAlertModel={setAlertModel}
                        alerModel={alerModel}
                        query={query}
                        isFormField={isFormField}
                        onClose={onClose}
                        handlefillModel={handlefillModel}

                    />
                </div>
                <div className="xsm:col-span-12 sm:col-span-8 md:col-span-8 p-4 h-[85vh] overflow-y-scroll "

                    style={{
                        scrollbarWidth: "none",
                        msOverflowStyle: "none"
                    }}>
                    <div className="flex flex-col gap-3  mt-1 float-start w-full lg:w-[60vw] mx-auto">
                        {loading ? (
                            <p>Loading vehicles...</p>
                        ) : (
                            vehicleData.length > 0 ? (
                                vehicleData?.map((vehicle, index) => (
                                    <motion.div
                                        key={index}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        exit={{ opacity: 0, y: 20 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <TaxiCarDetails vehicle={vehicle} />
                                    </motion.div>
                                ))
                            ) : (
                                <div className="w-full bg-gray-800 text-white p-8 rounded-md shadow-md mt-10 text-center" style={{ backgroundImage: 'url(https://example.com/your-image.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                                    <h2 className="text-2xl font-bold mb-4">Sorry, no vehicles available for your search</h2>
                                    <p className="text-lg mb-4">Please adjust your search criteria and try again.</p>
                                    <Link to="/" className="text-blue-500 hover:text-blue-700 underline">Back to Homepage</Link>
                                </div>)
                        )}
                    </div>
                </div>
            </section>
            {
                alerModel !== null && (
                    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white rounded-lg shadow-lg p-6 w-full xsm:w-[90vw] md:max-w-md text-center">
                            <h2 className=" text-[14px] md:text-xl font-bold text-gray-800 mb-4">Alert</h2>
                            <p className="text-gray-600 text-[11px] md:text-[14px] mb-6">{alerModel}</p>
                            <button
                                className="bg-blue-500 text-[11px] md:text-[14px] hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
                                onClick={onClose}
                            >
                                OK
                            </button>
                        </div>
                    </div>
                )
            }
        </section>
    );
};

export default TaxiPackagesPages;

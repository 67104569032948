import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import CabFilter from "./CabFilter";
import { Button, Drawer } from "@mui/material";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import CarDetails from "./CarDetails";
import { useSelector } from "react-redux";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { setVehicles, setLoading, setError } from "../../../slices/VehiclesSlice";
import { FaFilter } from "react-icons/fa6";

const MainSection = () => {
    const dispatch = useDispatch();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { vehicles, loading, error } = useSelector((state) => state.vehicle);

    const toggleDrawer = (open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setIsDrawerOpen(open);
    };



    useEffect(() => {

        const fetchVehicles = async () => {
            try {
                // Extract query parameters from the current URL
                const queryParams = new URLSearchParams(window.location.search);
                const pickupLocation = queryParams.get("pickupLocation");
                const dropLocation = queryParams.get("dropLocation");
                const pickupDate = queryParams.get("pickupDate");
                const pickupTime = queryParams.get("pickupTime");
                const dropoffDate = queryParams.get("dropoffDate");
                const dropoffTime = queryParams.get("dropoffTime");

                // Construct the API URL with the extracted query parameters
                const apiUrl = `https://orca-app-iyk4e.ondigitalocean.app/api/v1/search-avaliable-rental-vehicle?pickupLocation=${pickupLocation}&dropLocation=${dropLocation}&pickupDate=${pickupDate}&pickupTime=${pickupTime}&dropoffDate=${dropoffDate}&dropoffTime=${dropoffTime}`;

                const response = await fetch(apiUrl); // Fetch data from API
                if (!response.ok) {
                    throw new Error("Failed to fetch vehicles");
                }
                const data = await response.json();
                dispatch(setVehicles(data)); // Dispatch the data to Redux
            } catch (error) {
                console.error("Error fetching vehicle data:", error);
            }
        };
        fetchVehicles();
    }, []);

    return (
        <div className="w-full mb-10 h-auto">
            <div className="xsm:h-[3rem] xsm:mt-[15vh] xs:mt-[16vh] w-[90vw] mx-auto md:hidden lg:hidden xsm:flex xs:flex sm:flex items-center px-4 justify-between rounded-md">
                <Button className="lg:hidden md:hidden block font-semibold text-black">
                    Filter
                </Button>
                <FaFilter className="text-blue-600" onClick={toggleDrawer(true)} />
            </div>

            <motion.section
                className="lg:w-[75vw] md:w-[90vw] sm:w-[90vw] xsm:w-[90vw] xs:w-[90vw] mx-auto grid lg:grid-cols-9 md:grid-cols-9 gap-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
            >
                {/* Sidebar (Hidden on small screens) */}
                <div className="md:col-span-2 lg:col-span-2 hidden sm:hidden xsm:hidden xs:hidden lg:flex md:flex">
                    <CabFilter />
                </div>

                {/* Drawer component for the sidebar on smaller screens */}
                <Drawer sx={{ width: "" }} anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                    <div role="presentation" onKeyDown={toggleDrawer(false)}>
                        <CabFilter />
                    </div>
                </Drawer>


                {/* Main Content Area */}
                <div className="md:col-span-7 lg:col-span-7 sm:col-span-7 xsm:col-span-6 xs:col-span-7 mt-1 md:h-[93vh] xsm:h-auto overflow-y-scroll xsm:mx-auto"
                    style={{
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                    }}
                >


                    {/* Vehicle List */}
                    <div className="flex flex-col gap-3 mt-3">
                        {loading ? (
                            <div>Loading vehicles...</div>
                        ) : error ? (
                            <div>Error loading vehicles: {error}</div>
                        ) : vehicles?.filteredVehicles?.length > 0 ? (
                            vehicles.filteredVehicles.map((vehicle, index) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    exit={{ opacity: 0, y: 20 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <CarDetails vehicle={vehicle} />
                                </motion.div>
                            ))
                        ) : (
                            <div>No vehicles available.</div>
                        )}
                    </div>
                </div>
            </motion.section>
        </div>
    );
};

export default MainSection;

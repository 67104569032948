import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_API } from '../../../Services/apis';
import axios from 'axios';
import { Button } from '@mui/material';
import { getAllRentalBookings } from '../../../Services/AddVehicleDetails';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Typography,
    Box
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SEOHelmet from '../../../Components/common/SeoHelmet';

const AllRentalBookingList = () => {
    const location = useLocation();
    const loginType = location.pathname.split("/")[1]
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [bookings, setBookings] = useState([]);
    const [bookingDetails, setBookingDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const fetchBookings = async () => {
            try {

                const response = await getAllRentalBookings()
                setBookings(response?.data?.data);
            } catch (error) {
                console.error("Error fetching bookings:", error);
            }
        };

        fetchBookings();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleRowClick = (bookingId) => {
        navigate(`/${loginType}/manage/Booking/rental-booking-detail/${bookingId}`);
    };

    return (
        <>
            <SEOHelmet title="All Rental Bookings | TSJ Car Rentals" />
            <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: 4, maxHeight: "72vh", overflowY: "scroll" }}>
                <Box p={2} sx={{ textAlign: "start" }}>
                    <Typography variant="h5" sx={{ fontWeight: "600", float: "left" }}>All Rental Bookings</Typography>
                </Box>
                <TableContainer component={Paper}
                    sx={{
                        // height: '400px',
                        overflowY: 'scroll',
                    }}>
                    <Table stickyHeader aria-label="vehicle table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>User Name</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Vehicle Type</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Vehicle Model</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Pickup Date</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Drop Date</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Pickup Location</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Drop Location</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Total Amount</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Booking Status</TableCell>
                                <TableCell sx={{ backgroundColor: "primary.main", color: "white" }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {bookings?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((booking) => (
                                <TableRow
                                    hover
                                    key={booking._id}
                                    onClick={() => handleRowClick(booking._id)}
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell sx={{ color: "gray" }}>{booking.userId?.name}</TableCell>
                                    <TableCell sx={{ color: "gray" }}>{booking.vehicleId?.vehicleType}</TableCell>
                                    <TableCell sx={{ color: "gray" }}>{booking.vehicleId?.vehicleModel}</TableCell>
                                    <TableCell sx={{ color: "gray" }}>{new Date(booking.pickupDate).toLocaleDateString()}</TableCell>
                                    <TableCell sx={{ color: "gray" }}>{new Date(booking.dropDate).toLocaleDateString()}</TableCell>
                                    <TableCell sx={{ color: "gray" }}>{booking.pickupLocation}</TableCell>
                                    <TableCell sx={{ color: "gray" }}>{booking.dropLocation}</TableCell>
                                    <TableCell sx={{ color: "gray" }}>{`₹${booking.totalAmount}`}</TableCell>
                                    <TableCell sx={{ color: "gray" }}>{booking.bookingStatus}</TableCell>
                                    <TableCell sx={{ color: "gray" }}>view</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>


                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={bookings?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
};

export default AllRentalBookingList; 

import React ,{useState,useEffect} from 'react';
import { Box, Typography, Card, CardContent, CardMedia, Grid2, Button, Divider, Paper, Container, Dialog, DialogTitle, DialogContent, DialogActions,IconButton  } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { formatDateString } from '../../../utils/FormateValue'
import { Helmet } from 'react-helmet';
import SEOHelmet from '../../common/SeoHelmet';
import CloseIcon from "@mui/icons-material/Close";

const TaxiPackageBookingSuccess = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const bookingData = location.state?.booking;
    const [openPopup, setOpenPopup] = useState(false);
    const [contactDetails, setContactDetails] = useState("");

    const handleContinue = () => {
        navigate("/user/profile")
    }
    useEffect(() => {
        const pickupLocation = bookingData?.pickupAddress?.toLowerCase();
        let details = "Contact details not available";
        if (pickupLocation === "bhopal") details = "Contact: +91 9109123408 (Bhopal)";
        else if (pickupLocation === "indore") details = "Contact: +91 91 9109123403 (Indore)";
        else if (pickupLocation === "udaipur") details = "Contact: +91 9109123403 (Udaipur)";
        setContactDetails(details);
        const timer = setTimeout(() => {
            setOpenPopup(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, [bookingData]);

    if (!bookingData) {
        return (
            <Container maxWidth="sm" sx={{ py: 4 }}>
                <Paper elevation={3} sx={{ p: 4, textAlign: "center" }}>
                    <Typography variant="h6" color="error">
                        No booking data available. Please try again.
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => navigate("/")}
                        sx={{ mt: 2 }}
                    >
                        Go Back
                    </Button>
                </Paper>
            </Container>
        );
    }

    const {
        name,
        nationality,
        mobile,
        alternateMobile,
        email,
        status,
        pickupAddress,
        dropAddress,
        pickupDate,
        pickupTime,
        dropDate,
        dropTime,
        ratePer12Hrs,
        limitPer12Hrs,
        extraHoursRate,
        extraKmRate,
        driverCharge,
        fare,
        surgeMultiplier,
        paymentStatus,
    } = bookingData;

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <SEOHelmet title="Taxi confirmation Booking | TSJ Car Rentals" />
            <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
                <Box display="flex" alignItems="center" mb={2}>
                    <CheckCircleIcon color="success" sx={{ fontSize: 40, mr: 2 }} />
                    <Typography variant="h5" fontWeight="bold">
                        Taxi booked successfully!
                    </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Grid2 container spacing={2}>
                    <Grid2 item xs={12} sm={6}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Name:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>{name}</Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Nationality:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>{nationality}</Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Mobile:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>{mobile}</Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Alternate Mobile:
                        </Typography>
                        <Typography variant="body2">{alternateMobile}</Typography>
                    </Grid2>
                    <Grid2 item xs={12} sm={6}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Email:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>{email}</Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Booking Status:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>{status}</Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1" fontWeight="bold">
                            Payment Status:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>{paymentStatus}</Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Pickup Address:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>{pickupAddress}</Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }} >
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"} align="left">
                            Drop Address:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>{dropAddress}</Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Pickup Date & Time:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>
                            {formatDateString(pickupDate)} at {pickupTime}
                        </Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Drop Date & Time:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>
                            {formatDateString(dropDate)} at {dropTime}
                        </Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Fare:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>&#8377;{fare}</Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Rate per 12 Hrs:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>&#8377;{ratePer12Hrs}</Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Limit per 12 Hrs:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>{limitPer12Hrs} km</Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Extra Hours Rate:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>&#8377;{extraHoursRate} per hour</Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Extra Km Rate:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>&#8377;{extraKmRate} per km</Typography>
                    </Grid2>
                    <Grid2 item size={{ xs: 12, sm: 6 }}>
                        <Typography variant="body1" fontWeight="bold" textAlign={"left"}>
                            Driver Charge:
                        </Typography>
                        <Typography variant="body2" textAlign={"left"}>&#8377;{driverCharge}</Typography>
                    </Grid2>
                </Grid2>

                <Divider sx={{ my: 2 }} />

                <Box display="flex" justifyContent="center">
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => navigate("/user/profile")}
                    >
                        Go to Dashboard
                    </Button>
                </Box>
            </Paper>
            <Dialog open={openPopup} onClose={handleClosePopup}>
                    <DialogTitle>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Booking Confirmed</Typography>
                            <IconButton onClick={handleClosePopup}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="body1">
                            Your booking was successful! Our team will contact you within 2-3 hours.
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            {contactDetails}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePopup} color="primary" autoFocus>
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
        </Container>
    );
};

export default TaxiPackageBookingSuccess;

import api from "./apiUtility";

export const getAllGalleryImages= async () => {
  try {
    const response = await api.get(`/gallery/retrive-gallery`);
    return response;
  } catch (error) {
    throw error;
  }
};

  export const vendorRequest = async (payload) => {
    try {
        console.log("payload",payload)
      const response = await api.post(`/vendor/vendor-request`,payload,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  export const allBlogList= async () => {
    try {
      const response = await api.get(`/blog/get-all-blogs`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  export const allFeaturedVehicleList= async () => {
    try {
      const response = await api.get(`/all-featured-veehicle-inventory`);
      return response;
    } catch (error) {
      throw error;
    }
  };


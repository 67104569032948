import React from "react";
import { FaCar, FaCogs, FaUsers, FaMapMarkerAlt } from "react-icons/fa";
import { MdLocalGasStation } from "react-icons/md";
import { BsSpeedometer2 } from "react-icons/bs";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { Link } from "react-router-dom";

const CarCardComp = ({
    vehicleType,
    vehicleModel,
    fuelType,
    transmission,
    amenities,
    img,
    availableLocation,
    ratePer24Hrs,
    limitPer24Hrs,
    extraHoursRate,
    extraKmRate,
    ratePerDay,
    available,

}) => {

    console.log("vehicleModel",vehicleModel)

    return (
        <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg shadow-md">
            {/* Image Section */}
            <Link to={`/Trip/Rental`} >

                <div className="relative p-4">
                    <img
                        src={img}
                        alt={`${vehicleType} ${vehicleModel}`}
                        className="w-full  object-contain rounded-md"
                    />
                    <div className="absolute top-2 left-2 bg-gray-800 text-white text-xs px-2 py-1 rounded">
                        {vehicleType}
                    </div>
                </div>
            </Link>

            {/* Car Information */}
            <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800">
                    {vehicleModel}
                </h3>
                <ul className="text-[0.8rem] text-gray-600 mt-4 grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-2">
                    <li className="flex items-center">
                        <FaCogs className="mr-2 text-gray-500" />
                        <span className="font-medium"></span> {transmission}
                    </li>
                    <li className="flex items-center">
                        <BsSpeedometer2 className="mr-2 text-gray-500" />
                        <span className="font-medium">Limit:</span> {limitPer24Hrs} km
                    </li>
                    <li className="flex items-center">
                        <MdLocalGasStation className="mr-2 text-gray-500" />
                        <span className="font-medium ">Fuel Type:</span> {fuelType}
                    </li>
                    {/* <li className="flex items-center">
                        <FaUsers className="mr-2 text-gray-500" />
                        <span className="font-medium">Passengers:</span> {persons} Persons
                    </li> */}
                    <li className="flex items-center">
                        {amenities && amenities.length>0? (
                            <AiOutlineCheckCircle className="mr-2 text-green-500" />
                        ) : (
                            <AiOutlineCloseCircle className="mr-2 text-red-500" />
                        )}
                        <span className="font-medium">AC:</span> {amenities.includes("ac") ? "Available" : "Not Available"}
                    </li>
                </ul>

            </div>

            {/* Pricing Section */}
            <div className="p-4 border-t">
                <div className="flex items-center justify-between text-gray-800">
                    <span className="flex items-center">
                        <FaMapMarkerAlt className="mr-2 text-gray-500" />
                        {availableLocation && availableLocation[0]?.toUpperCase() || "N/A"}
                    </span>
                    <span className=" text-[0.9rem] md:text-lg font-bold">₹{ratePer24Hrs}/24Hrs</span>
                </div>
                <p className="text-[1rem] md:text-sm text-gray-500 mt-1">
                    Extra km fare - ₹{extraKmRate}/km
                </p>
            </div>

            {/* Availability Section */}
            <div className="p-4 border-t">
                {available ? (
                    <Link to='/Trip/Rental' >


                        <button className="w-full py-2 bg-green-500 text-white rounded-md hover:bg-green-600">
                            Book Now
                        </button>
                    </Link>
                ) : (
                    <button
                        className="w-full py-2 bg-gray-300 text-gray-600 rounded-md cursor-not-allowed"
                        disabled
                    >
                        Not Available
                    </button>
                )}
            </div>
        </div>
    );
};

export default CarCardComp;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_API } from '../../../Services/apis';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import SEOHelmet from '../../../Components/common/SeoHelmet';

const RentalBookingTable = () => {
    const [bookings, setBookings] = useState([]);
    const token = JSON.parse(localStorage.getItem("token"));
    const navigate = useNavigate();

    useEffect(() => {
        const fetchBookings = async () => {
            try {
                const response = await axios.get(`${BASE_API}/user/my-rental-bookings`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setBookings(response.data?.data);
            } catch (error) {
                console.error("Error fetching bookings:", error);
            }
        };

        fetchBookings();
    }, []);

    const handleDetailsClick = (bookingId) => {
        localStorage.setItem("vehicleDetail", "RentalVehicle")
        navigate(`/user/booking-details/rental/${bookingId}`);
    };

    return (
        <div className="overflow-x-auto">
             <SEOHelmet title="User Rental Booking Lists | TSJ Car Rentals"/>
            <table className="w-full border-collapse bg-white shadow-lg">
                <thead>
                    <tr className="bg-gradient-to-r from-blue-600 to-blue-400 text-white">
                        <th className="px-4 py-2 whitespace-nowrap">Vehicle Type</th>
                        <th className="px-4 py-2 whitespace-nowrap">Vehicle Model</th>
                        <th className="px-4 py-2 whitespace-nowrap">Pickup Date</th>
                        <th className="px-4 py-2 whitespace-nowrap">Drop Date</th>
                        <th className="px-4 py-2 whitespace-nowrap">Pickup Location</th>
                        <th className="px-4 py-2 whitespace-nowrap">Drop Location</th>
                        <th className="px-4 py-2 whitespace-nowrap">Total Amount</th>
                        <th className="px-4 py-2 whitespace-nowrap">Booking Status</th>
                        <th className="px-4 py-2 whitespace-nowrap">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {bookings.map((booking) => (
                        <tr
                            key={booking._id}
                            className="odd:bg-gray-100 even:bg-gray-50 hover:bg-gray-200"
                        >
                            <td className="border px-4 py-2 text-gray-700">{booking.vehicleId.vehicleType}</td>
                            <td className="border px-4 py-2 text-gray-700">{booking.vehicleId.vehicleModel}</td>
                            <td className="border px-4 py-2 text-gray-700">{new Date(booking.pickupDate).toLocaleDateString()}</td>
                            <td className="border px-4 py-2 text-gray-700">{new Date(booking.dropDate).toLocaleDateString()}</td>
                            <td className="border px-4 py-2 text-gray-700">{booking.pickupLocation}</td>
                            <td className="border px-4 py-2 text-gray-700">{booking.dropLocation}</td>
                            <td className="border px-4 py-2 text-gray-700">₹{booking.totalAmount}</td>
                            <td
                                className={`px-2 py-1 rounded text-sm  
    ${booking.bookingStatus === 'Pending' ? 'bg-yellow-100 text-yellow-500' : ''}
    ${booking.bookingStatus === 'AdminApproved' ? 'bg-blue-100 text-blue-500' : ''}
    ${booking.bookingStatus === 'ManagerApproved' ? 'bg-green-100 text-green-500' : ''}
    ${booking.bookingStatus === 'Confirmed' ? 'bg-purple-100 text-purple-500' : ''}
    ${booking.bookingStatus === 'Cancelled' ? 'bg-red-100 text-red-500' : ''}
    ${booking.bookingStatus === 'Completed' ? 'bg-gray-100 text-gray-500' : ''}
    text-gray-700`}
                            >
                                {booking.bookingStatus}
                            </td>

                            <td className="border px-4 py-2">
                                <button
                                    className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-600 whitespace-nowrap"
                                    onClick={() => handleDetailsClick(booking._id)}
                                >
                                    View Details
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default RentalBookingTable;

import React from "react";
import { motion } from "framer-motion";
import { CabServicesData } from "../../../data/HomePageData";

const CabServices = () => {

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.2,
            },
        },
    };

    const cardVariants = {
        hidden: { opacity: 0, y: 50, scale: 0.9 },
        visible: { opacity: 1, y: 0, scale: 1, rotate: [0, 5, -5, 0] },
    };

    const pulseEffect = {
        animate: {
            scale: [1, 1.05, 1], 
            transition: { duration: 2, repeat: Infinity, ease: "easeInOut" },
        },
    };

    return (
        <section className="flex flex-col gap-5  lg:my-[2rem] md:my-[1rem] xsm:my-[1rem] sm:my-[1rem] font-font1">
            <div className="flex flex-col space-y-6 py-5 md:w-full xsm:w-[80vw]  mx-auto ">
                <h1  className="font-montserrat text-[1.1rem] font-semibold xsm:leading-20  md:leading-[30px]  text-black  text-center h-6">
                    Best Rental Services for you in Indore, Bhopal and Udaipur
                </h1>
                <p className="font-montserrat text-[1rem] md:text-[1rem] sm:text-[0.9rem] xsm:text-[0.8rem] text-[#484848] font-medium leading-custom24 text-center">
                    Tsj Offers Affordable Service to visit your dream
                </p>
            </div>
            <motion.div
                className="grid w-[90vw] md:w-[80vw] mx-auto grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 relative"
                variants={containerVariants}
                initial="hidden"
                animate="visible"
            >
                {CabServicesData.map((servicedata) => (
                    <motion.div
                        key={servicedata.id}
                        className="relative w-full h-48 md:h-[18.9375rem] rounded-md overflow-hidden"
                        style={{
                            backgroundImage: `url(${servicedata.img})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                        variants={cardVariants}
                        transition={{ duration: 0.5 }}
                        {...pulseEffect}
                    >
                        {/* Image Overlay */}
                        <div
                            className="absolute inset-0 rounded-md transition-opacity duration-500 ease-in-out"
                            style={{
                                backgroundImage: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
                            }}
                        ></div>

                     
                        <div className="relative z-10 p-4 flex flex-col justify-end h-full">
                            <h2 className="text-white text-lg font-montserrat text-[1rem]">
                                {servicedata.title}
                            </h2>
                        </div>
                    </motion.div>
                ))}
            </motion.div>
        </section>
    );
};

export default CabServices;


import './App.css';
import { useState } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import HomePage from './Pages/Homep';
import TripPage from './Pages/TripPage';
import BookingReview from './Pages/BookingReviewPage';
import Services from './Pages/Services';
import ContactUsPage from './Pages/ContactUspage';
import TermConditions from './Pages/TermConditions';
import ScrollToTop from './Components/common/ScrollEffect';
import AdminLoginForm from './Pages/AdminLoginPage';
import BookingTripDetails from './Components/Core/TripBook/BookingTripDetails';
import DashboardPage from './Pages/AdminDashbaordPage';
import AdminDashboardData from './Layout/Dashboard/AdminDashboardData';
import BookingTypes from './Pages/BookingTypes';
import TTBookingtable from './Layout/tables/UserBooking/TTBookingList';
import TaxiBookingtable from './Layout/tables/UserBooking/TaxiBookingList';
import RentalBookingTable from './Layout/tables/UserBooking/RentalBookingList';
import { FaWhatsapp, FaPhoneAlt } from 'react-icons/fa';
import UserSignup from './Pages/UserSignup';
import UserLogin from './Pages/UserLogin';
import UserDashboard from './Pages/UserDashboardPage';
import Profile from './Components/Core/UserDashboard/Profile';
import { Protected } from './Components/Core/Auth/ProtectedRoutes';
import MyGallery from './Pages/MyGallery';
import MapUI from './Components/common/GoogleMap/index';
import SignupOtpForm from './Pages/SignupOtpForm';
import LoginOtpForm from './Pages/LoginOtpForm';
import TTPackagePayment from './Pages/TTPackagePayment';
import BookingDetail from './Pages/BookingDetails';
import AllRentalBookingList from './Layout/tables/AdminBookings/AllRentalBookingList';
import AllTTBookingList from './Layout/tables/AdminBookings/AllTourAndTravelList';
import AllTaxiBookingList from './Layout/tables/AdminBookings/AllTaxiBookingList';
import AdminNestedNavabar from './Components/common/AdminNestedNavabar/AdminNestedNavbar';
import { AdminBookingPath, ManageVehiclePaths, OnboardVehcilePaths } from './data/AdminDashnoard';
import AddNewVehicleType from './Layout/AddVehicleDetails/AddNewVehicleType';
import AddNewRatePerKm from './Layout/AddVehicleDetails/AddNewRatePerKm';
import AddNewVehcileModel from './Layout/AddVehicleDetails/AddNewVehcileModel';
import AddNewFuel from './Layout/AddVehicleDetails/AddNewFuelType';
import AddNewService from './Layout/AddVehicleDetails/AddNewServiceType';
import OnboardedVehicleList from './Layout/OnboardVehicles/OnboardedVehicleList';
import OnboardedTaxiVehicleList from './Layout/OnboardVehicles/OnboardedTaxiVehicleList';
import OnboardedTTVehicleList from './Layout/OnboardVehicles/OnboardedTTVehicleList';
import Invoice from './Components/common/Invoice';
import GeneratedInvoice from './Components/common/GeneratedInvoice';
import BookingConfirmation from './Components/Core/TripDetails/RentalBookingConfirmation';
import TourPackageBookingSuccess from './Components/Core/TourAndTravels/Tour&TravelBookingSuccessful';
import TaxiPackageBookingSuccess from './Components/Core/TaxiPackages/TaxiBookingSuccesful';
import RentalBookingDetail from './Pages/RentalVechileDetails';
import TourBookingDetail from './Pages/TourVehicleDetails';
import RentalBookingView from './Layout/tables/AdminBookings/RentalBookingDetails';
import TaxiBookingView from './Layout/tables/AdminBookings/TaxiBookingView';
import TourPackageView from './Layout/tables/AdminBookings/Tour&Package';
import ManagerDashboardPage from './Pages/ManagerDashboardPage';
import AdminRentalBookingApprove from './Pages/AdminRentalBookingApprove';
import ManagerRentalBookingApproval from './Pages/ManagerRentalBookingApproval';
import { setUser } from './slices/UserSlices';
import UploadToGallery from './Pages/UploadToGallery';
import VendorOnboardingForm from './Pages/VendorRequest';
import AdminVendorRequestList from './Pages/AdminVendorRequestList';
import LatestBlog from './Components/Core/Home/Blog';
import LatestBlogPage from './Pages/LatestBlogPage';
import AdminBlogPost from './Pages/AdminBlogPost';
import AllBlogList from './Layout/tables/Blog/BlogList';
import FeaturedVehicles from './Pages/FeaturedVehicleInventory';
const getAuthStatus = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return user ? { isAuthenticated: true, user: user } : { isAuthenticated: false, user: null };
};

function App() {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth)
  const adminPAth = location.pathname.split("/")[1]
  const navigate = useNavigate();
  return (
    <div className="App relative w-[100%]">
      <ScrollToTop />
      <Routes>
        {/* <Route path="*" element={<Navigate to="/" />} /> */}
        <Route path='/' element={<HomePage />} />
        <Route path='/login' element={<UserLogin />} />
        <Route path='/become-host' element={<VendorOnboardingForm />} />
        <Route path="/blogs" element={<LatestBlogPage />}></Route>
        <Route path='/verify-login-otp' element={<LoginOtpForm />} />
        <Route path='/signup' element={<UserSignup />} />
        <Route path='/verify-signup-otp' element={<SignupOtpForm />} />
        <Route path="/gallery" element={<MyGallery />} />
        <Route path='/map' element={<MapUI />}></Route>
        <Route path="/user" element={<Protected><UserDashboard /></Protected>}>
          <Route path="profile" element={<Profile />} />
          <Route path="booking" element={<BookingTypes />}>
            <Route path="all-listed-vehicle-types" element={<RentalBookingTable />} />
            <Route path="all-vehicle-types" element={<TTBookingtable />} />
            <Route path="all-vehicle-modal" element={<TaxiBookingtable />} />
          </Route>
          <Route path="Password-Set" element={<p>New Password</p>} />
        </Route>
        <Route path='/user/booking-details/rental/:id' element={<RentalBookingDetail />} />
        <Route path='/user/booking-details/tt/:id' element={<TourBookingDetail />} />
        <Route path='/user/booking-details/taxi/:id' element={<BookingDetail />} />
        <Route path='/Trip/:vehicleType/' element={<TripPage />} />
        <Route path='/review-booking/:vehicleType/' element={<Protected><BookingReview /></Protected>} />
        <Route path='/rental-booking-confirmation-details' element={<Protected><BookingConfirmation /></Protected>} />
        <Route path='/book-details/:vehicleType/' element={<BookingTripDetails />} />
        <Route path='/book-details/:vehicleType/:id' element={<TTPackagePayment />} />
        <Route path='/tour-package-booking-successful' element={<Protected><TourPackageBookingSuccess /></Protected>} />
        <Route path='/taxi-package-booking-successful' element={<Protected><TaxiPackageBookingSuccess /></Protected>} />
        <Route path='/Services' element={<Services />} />
        <Route path='/contactUs' element={<ContactUsPage />} />
        <Route path='/Term-Conditions' element={<TermConditions />} />
        <Route path='/admin/login' element={<AdminLoginForm />} />
        <Route path='/manager/login' element={<AdminLoginForm />} />

        {
          user?.data?.role === "ADMIN" && (
            <Route path='/admin/manage' element={<Protected><DashboardPage /></Protected>}>
              <Route index element={<Protected><AdminDashboardData /></Protected>} />
              <Route path='Booking' element={<AdminNestedNavabar BookingPath={AdminBookingPath} />} >
                <Route path="Rental" element={<Protected><AllRentalBookingList /></Protected>} />
                <Route path="rental-booking-detail/:bookingId" element={<Protected><AdminRentalBookingApprove /></Protected>} />
                <Route path="Taxi" element={<AllTaxiBookingList />} />
                <Route path="taxi-booking-detail/:bookingId" element={<Protected><TaxiBookingView /></Protected>} />
                <Route path="TourAndTravel" element={<AllTTBookingList />} />
                <Route path="tour-package-booking-detail/:bookingId" element={<Protected><TourPackageView /></Protected>} />
              </Route>
              <Route path="Create" element={<AdminNestedNavabar BookingPath={ManageVehiclePaths} />}>
                <Route path="vehicleType" element={<Protected><AddNewVehicleType /></Protected>} />
                <Route path="rateperkm" element={<Protected><AddNewRatePerKm /></Protected>} />
                <Route path="vehiclemodel" element={<Protected><AddNewVehcileModel /></Protected>} />
                <Route path="fuel" element={<Protected><AddNewFuel /></Protected>} />
                <Route path='serviceType' element={<Protected><AddNewService /></Protected>}></Route>
              </Route>
              <Route path='onboard' element={<AdminNestedNavabar BookingPath={OnboardVehcilePaths} />} >
                <Route path="Rental" element={<Protected><OnboardedVehicleList /></Protected>} />
                <Route path="Taxi" element={<Protected><OnboardedTaxiVehicleList /></Protected>} />
                <Route path="TourAndTravel" element={<Protected><OnboardedTTVehicleList /></Protected>} />
              </Route>
              <Route path='invoice' element={<GeneratedInvoice />} />
              <Route path='created-invoice' element={<Invoice />} />
              <Route path='upload-to-gallery' element={<UploadToGallery />} />
              <Route path='retrive-galleres' element={<UploadToGallery />} />
              <Route path='vendor-request' element={<AdminVendorRequestList />} />
              <Route path='new-blog' element={<AdminBlogPost />} />
              <Route path='blog-list' element={<AllBlogList />} />
              <Route path='feature-vehicle-inventory' element={<FeaturedVehicles />} />
            </Route>
          )
        }
        {user?.data?.role === "MANAGER" && (
          <Route path='/manager/manage/' element={<Protected><ManagerDashboardPage /></Protected>}>
            <Route index element={<Protected><AllRentalBookingList /></Protected>} />
            <Route path="Booking/rental-booking-detail/:bookingId" element={<Protected><ManagerRentalBookingApproval /></Protected>} />
          </Route>
        )}
      </Routes>

      {
        adminPAth !== "admin" && (
          <div className="fixed bottom-5 z-50 xsm:right-1  md:right-5 flex flex-col space-y-2">
            {/* WhatsApp Button */}
            <a
              href="https://wa.me/919109123403"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-center xsm:w-10 aspect-square md:w-14 bg-green-500 rounded-full shadow-lg hover:shadow-xl transition-shadow duration-300"
              aria-label="Contact via WhatsApp"
            >
              <FaWhatsapp className="text-white xsm:text-xl md:text-3xl" />
            </a>
            {/* Phone Call Button */}
            <a
              href="tel:+919109123403"
              className="flex items-center justify-center xsm:w-10 aspect-square md:w-14 bg-blue-800 rounded-full shadow-lg hover:shadow-xl transition-shadow duration-300"
              aria-label="Call Us"
            >
              <FaPhoneAlt className="text-white xsm:text-xl md:text-3xl" />
            </a>
          </div>)
      }
    </div>
  );
}

export default App;

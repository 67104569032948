import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { Editor } from '@tinymce/tinymce-react';
import { BASE_API } from '../Services/apis';
import { createNewBlog } from '../Services/adminAPI';
import SEOHelmet from '../Components/common/SeoHelmet';

const AdminBlogPost = () => {
    const [content, setContent] = useState('');
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({ title: '', content: '', image: '' });
    const [loading, setLoading] = useState(false);

    const validateInputs = () => {
        let isValid = true;
        const newErrors = { title: '', content: '', image: '' };

        if (!title.trim()) {
            newErrors.title = 'Title is required.';
            isValid = false;
        }
        if (!content.trim()) {
            newErrors.content = 'Content is required.';
            isValid = false;
        }
        if (!image) {
            newErrors.image = 'Image is required.';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 500 * 1024) {
                setErrors((prev) => ({ ...prev, image: "Image size must be less than 500KB" }));
                setImage(null);
            } else {
                setImage(file);
                setErrors((prev) => ({ ...prev, image: "" }));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateInputs()) {
            return;
        }

        setLoading(true); 
        try {
            const formData = new FormData();
            formData.append('title', title);
            formData.append('content', content);
            formData.append('image', image);

            const response = await createNewBlog(formData);
            if (response.status === 201) {
                toast.success('Blog uploaded successfully');
                setTitle('');
                setContent('');
                setImage(null);
                window.location.reload();
            }
        } catch (error) {
            toast.error(`Failed to upload blog. ${error?.response?.data?.message || error.message}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="container mx-auto mt-10">
            <SEOHelmet title="New Blogs | TSJ Car Rentals"/>

         
            <div className="bg-white shadow-lg rounded-lg p-6">
                <h1 className="text-2xl font-bold mb-4">Upload New Blog</h1>
                <form onSubmit={handleSubmit}>
                    {/* Title Field */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="title">
                            Title
                        </label>
                        <input
                            type="text"
                            id="title"
                            placeholder="Enter title here"
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                                if (e.target.value.trim()) {
                                    setErrors((prev) => ({ ...prev, title: '' }));
                                }
                            }}
                        />
                        {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
                    </div>

                    {/* Content Field */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="content">
                            Content
                        </label>
                        <Editor
                            apiKey="jvppp2jccn17jj2hrohh2ik1yvo5t968hm6k3z8sil3mce8f"
                            init={{
                                height: 500, 
                                menubar: true,
                                plugins: [
                                    'advlist autolink lists link image charmap print preview anchor',
                                    'searchreplace visualblocks code fullscreen',
                                    'insertdatetime media table paste code help wordcount',
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | table',
                                images_upload_url: `${BASE_API}/blog/upload-image`,
                            }}
                            onEditorChange={(content) => {
                                setContent(content);
                                if (content.trim()) {
                                    setErrors((prev) => ({ ...prev, content: '' }));
                                }
                            }}
                        />
                        {errors.content && <p className="text-red-500 text-sm mt-1">{errors.content}</p>}
                    </div>

                    {/* Image Upload Field */}
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor="image">
                            Upload Image
                        </label>
                        <input
                            type="file"
                            id="image"
                            accept="image/*"
                            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
                            onChange={handleImageChange}
                        />
                        {errors.image ?(
                            <p style={{ textAlign: "left", color: "red" }}>
                                {errors.image}
                            </p>
                        ):
                        <p style={{ textAlign: "left" }}>Image size must be less than 500KB</p>
                        }
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        className={`w-full text-white font-semibold py-2 rounded-lg ${
                            loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'
                        }`}
                        disabled={loading}
                    >
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AdminBlogPost;

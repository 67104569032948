import React, { useState, useEffect } from 'react';
import {
    Container, Typography, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Button, IconButton, Select,
    MenuItem, Dialog, DialogActions, DialogContent, DialogTitle,
    Box,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getAllFeaturedVehicle, editFeaturedVehicle, addNewFeaturedVehicle,deleteFeaturedVehicle } from "../Services/adminAPI"
import { getAllOnboardVehicleList } from "../Services/AddVehicleDetails"
import toast from 'react-hot-toast';

const FeaturedVehicles = () => {
    const [featuredVehicles, setFeaturedVehicles] = useState([]);
    const [onboardedVehicles, setOnboardedVehicles] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedVehicleId, setSelectedVehicleId] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editingVehicleId, setEditingVehicleId] = useState('');

    const fetchFeaturedVehicles = async () => {
        try {
            const response = await getAllFeaturedVehicle();
            if (response.status === 200) {
                setFeaturedVehicles(response.data.featuredVehicles || []);
            }
        } catch (error) {
            console.error('Error fetching featured vehicles:', error);
        }
    };

    // Fetch onboarded vehicles
    const fetchOnboardedVehicles = async () => {
        try {
            const response = await getAllOnboardVehicleList();
            setOnboardedVehicles(response.data.data || []);
        } catch (error) {
            console.error('Error fetching onboarded vehicles:', error);
        }
    };

    useEffect(() => {
        fetchFeaturedVehicles();
        fetchOnboardedVehicles();
    }, []);

    // Add or Edit Featured Vehicle

    const handleSaveVehicle = async () => {
        try {
            const response = editMode ? await editFeaturedVehicle(editingVehicleId,selectedVehicleId) : await addNewFeaturedVehicle(selectedVehicleId);
            if (response.status===200 || response.status===201) {
                toast.success("Added successfully");
                fetchFeaturedVehicles();
                setOpenDialog(false);
                setSelectedVehicleId('');
                setEditMode(false);
                fetchFeaturedVehicles();

            }
        } catch (error) {
            toast.error(error?.response?.data?.message || "An error occurred. Please try again.");

            console.error('Error saving featured vehicle:', error);
        }
    };

    // Delete Featured Vehicle
    const handleDeleteVehicle = async (id) => {
        try {
            const response = await deleteFeaturedVehicle(id);
            if (response.status===200) {
                fetchFeaturedVehicles();
                toast.success(response?.data?.message ||"Deleted Successfully")
            } 
        } catch (error) {
            toast.error(error?.response?.data?.message ||"Error deleting featured vehicle")
            console.error('Error deleting featured vehicle:', error);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Manage Featured Vehicles
            </Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    setOpenDialog(true);
                    setEditMode(false);
                }}
            >
                Add Featured Vehicle
            </Button>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Vehicle Name</TableCell>
                            <TableCell>Model Type</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {featuredVehicles.map((vehicle) => (
                            <TableRow key={vehicle._id}>
                                <TableCell>{vehicle.vehicleId?.vehicleType || 'Unknown'}</TableCell>
                                <TableCell>{vehicle.vehicleId?.vehicleModel || 'Unknown'}</TableCell>
                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            setOpenDialog(true);
                                            setEditMode(true);
                                            setEditingVehicleId(vehicle._id);
                                            setSelectedVehicleId(vehicle.vehicleId?._id);
                                        }}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton
                                        color="error"
                                        onClick={() => handleDeleteVehicle(vehicle._id)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Dialog for Add/Edit */}
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>{editMode ? 'Edit Featured Vehicle' : 'Add Featured Vehicle'}</DialogTitle>
                <DialogContent sx={{ minWidth: "425px" }}>
                    <Box display="flex" flexDirection="column" gap={2}>
                        <Select
                            value={selectedVehicleId}
                            onChange={(e) => setSelectedVehicleId(e.target.value)}
                            fullWidth
                        >
                            <MenuItem value="" disabled>
                                Select a Vehicle
                            </MenuItem>
                            {onboardedVehicles.map((vehicle) => (
                                <MenuItem key={vehicle._id} value={vehicle._id}>
                                    {vehicle.vehicleType} - {vehicle.vehicleModel}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveVehicle} variant="contained" color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default FeaturedVehicles;
